import React from 'react'
import { Back } from './styles'
import { useNavigate, useLocation } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'

export default function BackButton ({ exact }) {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const currentPage = queryParams.get('page') || 1

  return (
    <Back type='secondary' onClick={() => navigate(-1)}>
      <IoIosArrowBack />
      Back
    </Back>
  )
}
