import React from 'react'
import { Result, Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { SmileOutlined } from '@ant-design/icons'

const OrderConfirmedPage = () => {
  const navigate = useNavigate()

  const continueShop = () => {
    navigate('/gifts')
  }
  return (
    <Result
      status='success'
      title='Your Order Has Been Confirmed!'
      subTitle='You will receive an email confirmation shortly.'
      icon={<SmileOutlined style={{ color: '#d29e84' }} />}
      extra={[
        <Button
          type='primary'
          onClick={continueShop}
          style={{ backgroundColor: '#d29e84' }}
        >
          Continue Shopping
        </Button>
      ]}
    />
  )
}

export default OrderConfirmedPage
