import styled from 'styled-components'

export const Container = styled.div`
  padding: 1rem 3rem;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.div`
  font-size: 16px;
`
export const Content = styled.div`
  font-size: 14px;
`
export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 5rem;
`
