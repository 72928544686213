import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap:2rem;
`
export const Divider = styled.div`
  border-top: 1px dashed lightgray;
  // border-bottom: 1px dashed lightgray;
  font-size: 20px;
  margin: 5rem 0;
`
