import React from 'react'
import './index.css'

export default function AddToCartButton ({ onClick, det }) {
  return (
    <button
      className='addToCart-btn'
      onClick={onClick}
      style={
        det && {
          color: 'white',
          border: 'none',
          backgroundColor: '#874e31',
          // width: '30rem'
        }
      }
    >
      Add To Cart
    </button>
  )
}
