import axiosInstance from "../../utils/Axios/axiosConfig";

export const fetchCategories = async () => {
  const result = await axiosInstance.get("/categories");
  return result.data;
};

export const fetchCategory = async (id) => {
  const result = await axiosInstance.get("/categories/" + id);
  return result.data;
};

export const createCategory = async (categoryData) => {
  const result = await axiosInstance.post("/categories", categoryData);
  return result.data;
};

export const updateCategory = async (categoryData) => {
  const result = await axiosInstance.put(
    `/categories/${categoryData.id}`,
    categoryData.data
  );
  return result.data;
};

export const deleteCategory = async (categoryId) => {
  const result = await axiosInstance.delete(`/categories/${categoryId}`);
  return result.data;
};
