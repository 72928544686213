import styled from "styled-components";
import { Input, Tooltip } from "antd";

import React from "react";

const { TextArea } = Input;
export const Container = styled.div`
  padding: 5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 50%;
  @media only screen and (max-width: 1440px) {
    width: 40%;
  }
  @media only screen and (max-width: 670px) {
    width: 100%;
    padding: 0 5rem;
    gap: 1rem;
    margin-bottom: 5rem;
  }
`;
export const Title = styled.div`
  font-size: 3rem;
  @media only screen and (max-width: 480px) {
    font-size: 22px;
  }
`;
export const Description = styled.p`
  font-size: 1.7rem;
  color: gray;
  text-align: justify;
  @media only screen and (max-width: 480px) {
    font-size: 14px;
  }
`;
export const SubTitle = styled.div`
  font-size: 2.5rem;
  @media only screen and (max-width: 480px) {
    font-size: 16px;
  }
`;
export const ColorsContainer = styled.div`
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // display: grid;
  // grid-template-columns: repeat(4, 1fr);
  // gap: 1rem;
`;

export const ColorOptContainer = styled.div`
  padding: 2rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ColorOption = styled.div`
  background-color: ${(props) => props.color};
  width: 6rem;
  height: 6rem;
  cursor: pointer;
  border-radius: 999px;
  // margin:0 1rem;
  @media only screen and (max-width: 500px) {
    width: 3rem;
    height: 3rem;
  }
`;
export const ArrowIcon = styled.div`
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d29e84;
  cursor: pointer;
`;

export const PersonalizeContainer = styled.div`
  background-color: #f3f3f3;
`;

//export const PersonalizeInput = styled(Input)``

const CustomTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    max-width: 250px;
  }
`;

export const PersonalizeInput = React.memo(
  ({ maxLength, paragraph, ...props }) => (
    <CustomTooltip
      title={`Maximum ${maxLength} characters allowed`}
      placement="topLeft"
    >
      {paragraph ? (
        <TextArea
          rows={4}
          placeholder={`maxLength is ${maxLength}`}
          maxLength={maxLength}
          {...props}
        />
      ) : (
        <Input maxLength={maxLength} {...props} />
      )}
    </CustomTooltip>
  )
);

export const MainContainer = styled.div`
  padding: 5rem;
  @media screen and (max-width: 600px) {
    padding: 0;
  }
`;

export const PersonalizeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2rem 0 0;
`;
export const PersonalizeBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
`;
