import React from 'react'
import { Row } from './styles'
import { Form, Input, Button } from 'antd'

export default function VariationField ({
  index,
  subfieldCount,
  setSubfieldCount
}) {
  const handleAddSubVriation = () => {
    setSubfieldCount(subfieldCount + 1)
  }

  return (
    <>
      <Row key={index}>
        <Form.Item
          style={{ width: '100%' }}
          name={`variationFieldName${index}`}
        >
          <Input placeholder={`Field ${index + 1} Name`} />
        </Form.Item>

        <Form.Item>
          <Button type='dashed' onClick={handleAddSubVriation}>
            Add Sub Field
          </Button>
        </Form.Item>
      </Row>
      {[...Array(subfieldCount)].map((_, idx) => (
        <Row key={index + idx}>
          <Form.Item
            style={{ width: '25%' }}
            name={`SubFieldName${idx}-variationFieldName${index}`}
          >
            <Input placeholder={`SubField ${idx + 1} Name`} />
          </Form.Item>
        </Row>
      ))}
    </>
  )
}
