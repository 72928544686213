import "./../css/working.css";
import React, { useState } from "react";
import Card from "../components/Card";
import CustomCarousel from "./CustomCarousel";
import { Button } from "react-bootstrap";
import OrderAGift from "./OrderAGift";
import { useNavigate } from "react-router-dom";

const Working = () => {
  const navigate = useNavigate();
  const cardData = [
    {
      imageUrl: "/card1.png",
      title: "Special Chocolate and Flowers Gift",
      price: 34.0,
    },
    {
      imageUrl: "/card2.png",
      title: "Unique & Handpicked Gifts for Her",
      price: 30.0,
    },
    {
      imageUrl: "/card3.png",
      title: "Happy Wishes Basket for Kids",
      price: 25.0,
    },
    {
      imageUrl: "/card1.png",
      title: "2nd Special Chocolate and Flowers Gift",
      price: 34.0,
    },
    {
      imageUrl: "/card2.png",
      title: "2nd Unique & Handpicked Gifts for Her",
      price: 30.0,
    },
    {
      imageUrl: "/card3.png",
      title: "2nd Happy Wishes Basket for Kids",
      price: 25.0,
    },

    // Add more card data as needed
  ];

  const [startIndex, setStartIndex] = useState(0);

  const nextSlide = () => {
    setStartIndex((prevIndex) => (prevIndex + 1) % cardData.length);
  };

  const prevSlide = () => {
    setStartIndex(
      (prevIndex) => (prevIndex - 1 + cardData.length) % cardData.length
    );
  };

  return (
    <>
      <section className="main-working">
        <div className="working-header">
          <h1 style={{ fontFamily: "var(--heading-font)", fontSize: "3rem" }}>
            How to <span className="header-span"> gift </span> with
            <span className="header-span"> Heartsy box</span>
          </h1>
        </div>
        <div className="d-flex flex-column gap-4">
          <div className="main-working-step">
            <h1 style={{ fontFamily: "var(--heading-font)", fontSize: "3rem" }}>
              Design a <span className="header-span">gift box</span>
            </h1>
          </div>
          <CustomCarousel />
          <button
            style={{ marginTop: "8rem" }}
            type="button"
            className="design-a-gift-box"
            onClick={() => {
              navigate("design");
            }}
          >
            Design a Gift Box
          </button>
        </div>
        <div className="gift-order-main">
          <h1>OR</h1>
          <hr className="OR-line" />
        </div>

        <div className="main-working-step">
          <h1 style={{ fontFamily: "var(--heading-font)", fontSize: "3rem" }}>
            Just order <span className="header-span">a gift</span>
          </h1>
        </div>

        <OrderAGift />
        <button
          style={{}}
          type="button"
          className="design-a-gift-box m-auto"
          onClick={() => {
            navigate("/gifts");
          }}
        >
          Shop Now
        </button>
      </section>
    </>
  );
};

export default Working;
