import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/orderagift.css";
import { useFetchProducts } from "../hooks/Products/useFetchProducts";

function OrderAGift() {
  const [activeSlide, setActiveSlide] = useState(1);
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const { isLoading, products } = useFetchProducts();
  const [showProds, setShowProds] = useState([]);

  const settings = {
    className: "center",
    centerMode: true,
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: Math.min(
      products && products.filter((prd) => !prd.isAReadyMadeBox).length - 1,
      3
    ),
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    beforeChange: (current, next) => setActiveSlide(next + 1),
    responsive: [
      {
        breakpoint: 858,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (products) {
      setShowProds(
        products?.filter(
          (prd) => !prd.isAReadyMadeBox && prd.scopes.includes("homepage")
        )
      );
    }
  }, [products]);

  useEffect(() => {
    if (showProds) {
      setProductName(
        showProds?.find((prd, index) => index + 1 === activeSlide)?.title
      );
      setProductPrice(
        showProds?.find((prd, index) => index + 1 === activeSlide)?.price
      );
    }
  }, [activeSlide, products]);

  return (
    <>
      {products && (
        <div className="order-gift-main-container">
          <div className="carousel-info-container">
            <div className="info">
              <div className="">
                <h1 style={{ color: "#a0522d" }}>{productName}</h1>
              </div>
              <div>
                <h4 style={{ color: "#a0522d" }}>${productPrice}</h4>
              </div>
            </div>
          </div>
          {products && (
            <div className="slider-container">
              <Slider
                //ref={sliderRef}
                {...settings}
              >
                {showProds.map((product, index) => (
                  <div key={product.id} className="sliding-box">
                    <img
                      src={product.imagesWithColors[0]?.src}
                      alt={product?.title}
                      className="carousel-image"
                    />
                  </div>
                ))}
              </Slider>
            </div>
          )}
        </div>
      )}
    </>
  );
}
export default OrderAGift;
