import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  background-color: rgba(15, 3, 99, 0.03);
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  padding: 20rem 22rem 5rem 22rem;
  @media only screen and (max-width: 1300px) {
    gap: 5rem;
  }
  @media only screen and (max-width: 1100px) {
    padding: 20rem 15rem 0 15rem;
  }
  @media only screen and (max-width: 800px) {
    padding: 15rem 2rem 0 2rem;
  }
  @media only screen and (min-width: 1720px) {
    padding: 20rem 20% 5rem 20%;
  }
`
export const LeftContainer = styled.div`
  flex: 1;
  width: 25rem;
  padding-left: 10rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media only screen and (max-width: 760px) {
    width: 100%;
    align-items: center;
    padding-left: 0;
    padding-bottom: 3rem;
    text-align: center;
    br {
      display: none; /* Hides <br/> on screens smaller than 600px */
    }
  }
`
export const RightContainer = styled.div`
  flex: 1;
  overflow: hidden !important;
  position: relative;
  width: 10rem;
  height: 60rem;
  @media only screen and (max-width: 760px) {
    display: none;
  }
`
export const Image1 = styled.img`
  width: 500px;
  height: 480px;
  flex-shrink: 0;
  border-radius: 20px;
  margin-top: -30px; // added later
  // position: absolute;
  // left: 23%;
  // top: -8rem;
  // z-index: 100;
  @media only screen and (max-width: 1300px) {
    width: 402px;
    height: 380px;
  }
  @media only screen and (max-width: 1000px) {
    // width: 302px;
    // height: 280px;
    width: 100%;
  }
`
export const Image2 = styled.img`
  width: 370px;
  height: 339px;
  flex-shrink: 0;
  box-shadow: 10px 10px 90px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: absolute;
  left: 0;
  top: -10rem;
  z-index: 10;
  @media only screen and (max-width: 1300px) {
    width: 270px;
    height: 239px;
  }
  @media only screen and (max-width: 1300px) {
    width: 220px;
    height: 189px;
  }
`
export const Image3 = styled.img`
  width: 170.301px;
  height: 224.544px;
  flex-shrink: 0;
  box-shadow: 10px 10px 90px 0px rgba(0, 0, 0, 0.1);
  border-radius: 1.25rem;
  position: absolute;
  left: 16%;
  top: 43%;
  @media only screen and (max-width: 1300px) {
    width: 130.301px;
    height: 154.544px;
  }
  @media only screen and (max-width: 1300px) {
    width: 80px;
    height: 100px;
    left: 20%;
    top: 34%;
  }
`
export const SVGContainer = styled.div`
  // background-color: red;
  z-index: 100000;
  width: 20rem;
  height: 25rem;
  flex-shrink: 0;
  box-shadow: 10px 10px 90px 0px rgba(0, 0, 0, 0.1);
  border-radius: 1.25rem;
  position: absolute;
  left: 16%;
  top: 43%;
`
