import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import "./step1.css";
import { useCreateOrder } from "../../../hooks/Orders/useCreateOrder";
import { useCart } from "../../../contexts/CartContext";
import { useFetchCoupons } from "../../../hooks/Coupon/useFetchCoupons";
import { message } from "antd";

const FormContainer = styled.div`
  margin: 0 15rem;
  @media only screen and (max-width: 500px) {
    margin: 0;
  }
`;

const Step6 = ({
  data,
  updateData,
  isCheckoutPageOnly = false,
  totalPrice,
  handleCreateOrder,
  totalCartPrice,
}) => {
  const [diffAddress, setDiffAddress] = useState(false);
  const { coupons } = useFetchCoupons();

  const handleDiffAddress = () => {
    setDiffAddress(!diffAddress);
  };

  const handleInfoChange = (field, value, isSender = true) => {
    const key = isSender ? "senderInfo" : "recipientInfo";
    updateData(key, {
      ...data[key],
      [field]: value,
    });
  };
  const handleCouponValidation = (e, key) => {
    if (key === "couponCode") {
      const isValidCode = coupons.find(
        (cp) => cp.code === data.senderInfo.couponCode
      );
      if (
        isValidCode &&
        new Date(isValidCode.validFrom) < new Date() &&
        new Date(isValidCode.validTo) > new Date()
      ) {
        message.success(
          "Coupon Code Validated Successfully and price is reduced"
        );
        updateData(
          "totalPrice",
          data.totalPrice -
            (data.totalPrice * isValidCode.discountPercentage) / 100
        );
        updateData("couponDiscountPercentage", isValidCode.discountPercentage);
      } else {
        message.error("Coupon Code is not valid");
        if (isCheckoutPageOnly) {
          updateData("totalPrice", totalCartPrice);
          updateData("couponDiscountPercentage", "");
        } else {
          updateData(
            "totalPrice",
            data["step1Price"] +
              data["step2Price"] +
              data["step3Price"] +
              data["step4PaperTypePrice"] +
              data["step4EnvelopeTypePrice"]
          );
          updateData("couponDiscountPercentage", "");
        }
      }
    }
  };
  useEffect(() => {
    updateData(
      "totalPrice",
      data["step1Price"] +
        data["step2Price"] +
        data["step3Price"] +
        data["step4PaperTypePrice"] +
        data["step4EnvelopeTypePrice"]
    );
  }, []);

  return (
    <>
      <FormContainer>
        <h1 className="fw-bold pt-5">Sender Information</h1>
        <Form className="fs-3">
          <div className="d-flex-column justify-content-between ">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {["firstName", "lastName"].map((key) => (
                <Form.Group key={key} className="mb-3" style={{ width: "40%" }}>
                  <Form.Label>
                    {key
                      .replace(/([A-Z])/g, " $1")
                      .trim()
                      .toLowerCase()
                      .replace(/^\w/, (c) => c.toUpperCase())}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="fs-2"
                    value={data?.senderInfo ? data?.senderInfo[key] : ""}
                    onChange={(e) => handleInfoChange(key, e.target.value)}
                    required={true}
                  />
                </Form.Group>
              ))}
            </div>
            <div>
              <Form.Group className="mb-3">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  className="fs-2"
                  value={data?.senderInfo ? data?.senderInfo?.address : ""}
                  onChange={(e) => handleInfoChange("address", e.target.value)}
                  required
                />
              </Form.Group>
            </div>
          </div>
          <div className="d-flex justify-content-between py-4">
            {["city", "state", "zipCode"].map((key) => (
              <Form.Group key={key} className="mb-3" style={{ width: "30%" }}>
                <Form.Label>
                  {key
                    .replace(/([A-Z])/g, " $1")
                    .trim()
                    .toLowerCase()
                    .replace(/^\w/, (c) => c.toUpperCase())}
                </Form.Label>
                <Form.Control
                  type="text"
                  className="fs-2"
                  value={data?.senderInfo ? data?.senderInfo[key] : ""}
                  onChange={(e) => handleInfoChange(key, e.target.value)}
                  required
                />
              </Form.Group>
            ))}
          </div>

          <div className="d-flex justify-content-between pb-5">
            {["emailAddress", "couponCode"].map((key) => (
              <Form.Group key={key} className="mb-3" style={{ width: "40%" }} q>
                <Form.Label>
                  {key
                    .replace(/([A-Z])/g, " $1")
                    .trim()
                    .toLowerCase()
                    .replace(/^\w/, (c) => c.toUpperCase())}
                </Form.Label>
                <Form.Control
                  type={key.includes("Email") ? "email" : "text"}
                  className="fs-2"
                  value={data?.senderInfo ? data?.senderInfo[key] : ""}
                  onChange={(e) => handleInfoChange(key, e.target.value)}
                  onBlur={(e) => handleCouponValidation(e, key)}
                  required
                />
              </Form.Group>
            ))}
          </div>

          <Form.Check
            type="switch"
            id="diff-address-switch"
            label="Ship to a different address?"
            checked={diffAddress}
            onChange={handleDiffAddress}
            className="mb-3 fs-2"
            style={{ margin: "1rem" }}
          />

          {diffAddress && (
            <>
              <h1 className="fw-bold pt-5">Recipient Information</h1>
              <div className="d-flex-column justify-content-between">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {["firstName", "lastName"].map((key) => (
                    <Form.Group
                      key={key}
                      className="mb-3"
                      style={{ width: "40%" }}
                    >
                      <Form.Label>
                        {key
                          .replace(/([A-Z])/g, " $1")
                          .trim()
                          .toLowerCase()
                          .replace(/^\w/, (c) => c.toUpperCase())}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="fs-2"
                        value={
                          data?.recipientInfo ? data?.recipientInfo[key] : ""
                        }
                        onChange={(e) =>
                          handleInfoChange(key, e.target.value, false)
                        }
                      />
                    </Form.Group>
                  ))}
                </div>
                <div style={{ width: "48%" }}>
                  <Form.Group className="mb-3">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      className="fs-2"
                      value={
                        data?.recipientInfo ? data?.recipientInfo?.address : ""
                      }
                      onChange={(e) =>
                        handleInfoChange("address", e.target.value, false)
                      }
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="d-flex justify-content-between py-4">
                {["city", "state", "zipCode"].map((key) => (
                  <Form.Group
                    key={key}
                    className="mb-3"
                    style={{ width: "30%" }}
                  >
                    <Form.Label>
                      {key
                        .replace(/([A-Z])/g, " $1")
                        .trim()
                        .toLowerCase()
                        .replace(/^\w/, (c) => c.toUpperCase())}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="fs-2"
                      value={
                        data?.recipientInfo ? data?.recipientInfo[key] : ""
                      }
                      onChange={(e) =>
                        handleInfoChange(key, e.target.value, false)
                      }
                    />
                  </Form.Group>
                ))}
              </div>

              <div className="d-flex justify-content-between pb-5">
                {["emailAddress"].map((key) => (
                  <Form.Group
                    key={key}
                    className="mb-3"
                    style={{ width: "40%" }}
                  >
                    <Form.Label>
                      {key
                        .replace(/([A-Z])/g, " $1")
                        .trim()
                        .toLowerCase()
                        .replace(/^\w/, (c) => c.toUpperCase())}
                    </Form.Label>
                    <Form.Control
                      type={key.includes("Email") ? "email" : "text"}
                      className="fs-2"
                      value={
                        data?.recipientInfo ? data?.recipientInfo[key] : ""
                      }
                      onChange={(e) =>
                        handleInfoChange(key, e.target.value, false)
                      }
                    />
                  </Form.Group>
                ))}
              </div>
            </>
          )}

          <div
            className="d-flex justify-content-between"
            style={{ margin: "5rem 0", fontSize: "5rem" }}
          >
            <div>Your Total Is</div>
            <div style={{ color: "#0084BE" }}>${totalPrice || 0}</div>
          </div>
        </Form>
        {isCheckoutPageOnly && (
          <div className="d-flex">
            <button
              className="custom-btn-next fs-3 m-auto px-5"
              onClick={handleCreateOrder}
              type="submit"
            >
              Pay
            </button>
          </div>
        )}
      </FormContainer>
    </>
  );
};

export default Step6;
