import React, { useState } from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
import '../css/navbar.css'
import logo from '../assets/Logo/logo.png'
import { MobileMenu } from './MobileMenu'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Badge } from 'antd'
import DropDown from './Menu'
import { useCart } from '../contexts/CartContext'

const CustomNavbar = () => {
  const [activeLink, setActiveLink] = useState('/')
  const [menuVisible, setMenuVisible] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const items = [
    {
      key: 1,
      label: <div onClick={() => navigate('/gifts/For Him')}>For Him</div>
    },
    {
      key: 2,

      label: <div onClick={() => navigate('/gifts/For Her')}>For Her</div>
    },
    {
      key: 3,
      label: <div onClick={() => navigate('/gifts/For Kids')}>For Kids</div>
    }
  ]

  const onClick = ({ key }) => {
    navigate(`/${key}`)
  }

  const PageTitle = styled.div`
    font-family: Tangerin;
    font-size: 15px;
    cursor: pointer;
    ${props =>
      props.active &&
      `
      display:flex;
      align-items:center;
    border-radius: 5px;
    background-image: url(/active-tab-bg.png);
    background-size: 100% 100%;
    color: white;
    width: min-content;
    cursor:pointer;
    &:hover {
    color: white;
  }
    `}
  `
  const onChange = route => {
    navigate(route)
    setActiveLink(route)
  }

  const { cart } = useCart()
  return (
    <Navbar bg='light' expand='lg' className='sticky-top mb-5'>
      <Container fluid>
        <Navbar.Brand href='/' className='heartsy-logo'>
          <img
            src='/Heartsybox-Logo.png'
            className='d-inline-block align-top '
            alt='Heartsybox Logo'
          />
        </Navbar.Brand>
        <Navbar.Brand href='/' className='heartsy-logo-2'>
          <img src={logo} style={{ margin: 0 }} alt='Heartsybox Logo' />
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls='basic-navbar-nav'
          className='custom-toggler mx-5'
          style={{ transform: 'scale(1.6)', border: 'none' }}
        >
          <MobileMenu />
        </Navbar.Toggle>

        <Nav className='myNav ms-auto fs-2 gap-4'>
          <PageTitle
            active={location.pathname === '/' && activeLink === '/'}
            to='/'
            className='nav-link px-3'
            onClick={() => onChange('/')}
          >
            Home
          </PageTitle>

          <PageTitle
            active={
              location.pathname.includes('/gifts') || activeLink === '/gifts'
            }
            to='/gifts'
            className='nav-link px-3'
            onClick={() => {
              onChange('/gifts')
              setMenuVisible(true)
            }}
          >
            Gifts
          </PageTitle>
          <DropDown items={items} active={menuVisible} />

          <PageTitle
            active={location.pathname === '/picker' || activeLink === '/picker'}
            to='/picker'
            className='nav-link px-3'
            onClick={() => onChange('/picker')}
          >
            GiftsPicker
          </PageTitle>
          <PageTitle
            active={
              location.pathname === '/ready-made' ||
              location.pathname.includes('/giftBox') ||
              activeLink === '/ready-made'
            }
            to='/ready-made'
            className='nav-link px-3'
            onClick={() => onChange('/ready-made')}
          >
            <div style={{ whiteSpace: 'nowrap', fontFamily: 'tangerin' }}>
              Ready-Made
            </div>
          </PageTitle>
          <PageTitle
            active={
              location.pathname.includes('blogs') || activeLink === '/blogs'
            }
            to='/blogs'
            className='nav-link px-3'
            onClick={() => onChange('/blogs')}
          >
            Blog
          </PageTitle>

          {/* <PageTitle
            active={location.pathname === '#' || activeLink === '#'}
            to='#'
            className='nav-link px-3'
            onClick={() => onChange('#')}
          >
            Contact
          </PageTitle> */}
        </Nav>

        <div
          onClick={() => navigate('/cart')}
          style={{ cursor: 'pointer' }}
          className='nav-link d-flex align-items-center mx-5 cart-icon'
        >
          <Badge count={cart.length} showZero={true} color='#D29E84'>
            <img
              style={{ width: '3rem' }}
              src='/shopping-cart-big.png'
              className='d-inline-block align-top'
              alt='Shopping Cart'
            />
          </Badge>
        </div>
      </Container>
    </Navbar>
  )
}

export default CustomNavbar
