import React from 'react'
import { AntdDropdown, MenuButton, DesktopMenu, MobileMenu } from './styles'
import {
  ExportOutlined,
  DeleteOutlined,
  CheckCircleFilled,
  CloseCircleFilled
} from '@ant-design/icons'
import {
  SettingOutlined,
  UserOutlined,
  LogoutOutlined
} from '@ant-design/icons'
import { Avatar } from 'antd'
import TextWithIcon from '../TextWithIcon'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { clearUserToken } from '../../../../utils/LocalStorage/localstorage'
import { FaBoxesStacked } from 'react-icons/fa6'

export default function MobileDropDown ({
  header,
  id,
  state,
  onVerify,
  onDelete,
  currentPage,
  email,
  placement
}) {
  const navigate = useNavigate()
  const logout = () => {
    clearUserToken()
    // navigate("/admin/products");
  }
  const items = [
    {
      key: 1,
      label: (
        <a href='/admin/products'>
          <TextWithIcon
            text={'Products'}
            icon={<LogoutOutlined />}
            color={'#74829C'}
          />
        </a>
      )
    },
    {
      key: 2,
      label: (
        <a href='/admin/orders'>
          <TextWithIcon
            text={'Orders'}
            icon={<LogoutOutlined />}
            color={'#74829C'}
          />
        </a>
      )
    },
    {
      key: 3,
      label: (
        <a href='/admin/vouchers'>
          <TextWithIcon
            text={'Vouchers'}
            icon={<LogoutOutlined />}
            color={'#74829C'}
          />
        </a>
      )
    },
    {
      key: 4,
      label: (
        <a href='/admin/blogs'>
          <TextWithIcon
            text={'Blogs'}
            icon={<LogoutOutlined />}
            color={'#74829C'}
          />
        </a>
      )
    },
    {
      key: 5,
      label: (
        <a onClick={logout} href='/admin/login'>
          <TextWithIcon
            text={'Logout'}
            icon={<LogoutOutlined />}
            color={'#74829C'}
          />
        </a>
      )
    }
  ]

  return (
    <AntdDropdown menu={{ items }} placement={placement}>
      <MenuButton header={header}>
        {header ? (
          <Avatar size='middle' icon={<UserOutlined />} />
        ) : (
          <BsThreeDotsVertical size={'1rem'} />
        )}
      </MenuButton>
    </AntdDropdown>
  )
}
