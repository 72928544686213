import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import "./step1.css";
import { useFetchProducts } from "../../../hooks/Products/useFetchProducts";
import styled from "styled-components";

const OrderInfo = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  padding: 2.5rem 0;
  @media only screen and (max-width: 450px) {
    // flex-wrap: wrap;
  }
`;
const Title = styled.div`
  font-size: 14px;
  @media only screen and (max-width: 450px) {
    font-size: 11px;
  }
`;

const Step5 = ({ data, updateData, setStep }) => {
  const { isLoading, products: allProducts } = useFetchProducts();
  const products = allProducts?.filter((prd) => !prd?.isAReadyMadeBox) || [];
  const [clickedImage, setClickedImage] = useState(null);
  const sweet = products?.find((prd) => prd._id === data?.sweet);
  const flower = products?.find((prd) => prd._id === data?.flower);
  const gift = products?.find((prd) => prd._id === data?.gift);
  const paperType = products?.find((prd) => prd._id === data?.paperType);
  const envelopeType = products?.find((prd) => prd._id === data?.envelopeType);

  useEffect(() => {setStep(5)},[])

  return (
    <>
      <h1 className="fw-bold pt-5">Order Information</h1>
      <OrderInfo>
        <Card className="border-0" style={{ transform: "none" }}>
          <Card.Img
            variant="top"
            src={sweet?.imagesWithColors[0]?.src}
            className={`rounded-4`}
          />
          <Card.Body className="text-center">
            <Title>Sweet</Title>
          </Card.Body>
        </Card>
        <Card className="border-0" style={{ transform: "none" }}>
          <Card.Img
            variant="top"
            src={flower?.imagesWithColors[0]?.src}
            className={`rounded-4`}
          />
          <Card.Body className="text-center">
            <Title>Flower</Title>
          </Card.Body>
        </Card>
        <Card className="border-0" style={{ transform: "none" }}>
          <Card.Img
            variant="top"
            src={gift?.imagesWithColors[0]?.src}
            className={`rounded-4`}
          />
          <Card.Body className="text-center">
            <Title>Gift</Title>
          </Card.Body>
        </Card>
        <Card className="border-0" style={{ transform: "none" }}>
          <Card.Img
            variant="top"
            src={paperType?.imagesWithColors[0]?.src}
            className={`rounded-4`}
          />
          <Card.Body className="text-center">
            <Title>Paper Type</Title>
          </Card.Body>
        </Card>
        <Card className="border-0" style={{ transform: "none" }}>
          <Card.Img
            variant="top"
            src={envelopeType?.imagesWithColors[0]?.src}
            className={`rounded-4}`}
          />
          <Card.Body className="text-center">
            <Title>Envelope Type</Title>
          </Card.Body>
        </Card>
      </OrderInfo>
      <Form>
        <Form.Group controlId="personalMessage">
          <Form.Label className="fs-1 fw-bold">Personal Message</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Your personal message is empty"
            style={{
              border: "1.5px solid #D29E84",
              margin: "3.5rem auto",
              borderRadius: "0.8rem",
              fontSize: "16px",
            }}
            rows={5}
            onChange={(e) => {
              updateData("personalMessage", e.target.value);
            }}
            value={data?.personalMessage}
            readOnly
          />
        </Form.Group>
      </Form>
    </>
  );
};

export default Step5;
