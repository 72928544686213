import React from "react";
import "./index.css";

export default function PriceTag({ price, small }) {
  return (
    <div
      className="priceTag-container"
      style={
        small && { padding: "1rem", fontSize: "2rem", margin: 0, width: "5rem" }
      }
    >
      ${price}
    </div>
  );
}
