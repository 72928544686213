import axiosInstance from "../../utils/Axios/axiosConfig";

export const fetchProducts = async () => {
  const result = await axiosInstance.get("/products");
  return result.data;
};

export const fetchProduct = async (id) => {
  const result = await axiosInstance.get("/products/" + id);
  return result.data;
};

export const createProduct = async (productData) => {
  const result = await axiosInstance.post("/products", productData);
  return result.data;
};

export const updateProduct = async (productData) => {
  const result = await axiosInstance.put(
    `/products/${productData.id}`,
    productData.data
  );
  return result.data;
};

export const deleteProduct = async (productId) => {
  const result = await axiosInstance.delete(`/products/${productId}`);
  return result.data;
};
