import styled from 'styled-components'
import { Table } from 'antd'

export const AntdTable = styled(Table)`
  .ant-table {
    border-radius: 0px !important;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  // .ant-table-container {
  //   border-radius: 0px !important;
  // }
  // .ant-table-content > table {
  //   border-radius: 0px !important;
  // }

  .ant-table-cell,
  .ant-table-cell-fix-left {
    border-radius: 0px !important;
    font-family: IBM Plex Sans;
    // color: #74829c;
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: 1200px) {
      font-size: 16px !important;
    }
  }
  thead tr th {
    font-size: 18px;

    &::before {
      display: none; // Remove the ::before pseudo-element
    }
  }
  // .ant-table-tbody > tr > td {
  //   border: none !important;
  // }
  .ant-table-thead th {
    // font-size: 18px !important;
    background-color: #f2f2f3 !important;

    @media (max-width: 1200px) {
      font-size: 16px !important;
    }
  }
  width: 100%;
  position: absolute;
`
export const TableWrapper = styled.div`
  height: 100%;
  overflow-x: scroll;
  position: relative;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 480px) {
    height: 800px;
  }
`
