import styled from 'styled-components'
import { Button } from 'antd'

export const PrimaryBtn = styled(Button)`
  ${props =>
    !props.simple
      ? `padding: 1.25rem; width: 100%; border-radius: 10px;font-size: 16px;`
      : `font-size: 14px;`}
  ${props => !props.noPadding && props.simple && `padding:10px 15px;`}  
  background-color: #0548dd;
  color: white;
  height: auto;
  justify-content: center;
  @media (max-width: 480px) {
    font-size: 14px;
    padding: 0.75rem;
  }
`
export const BtnText = styled.div`
  ${props =>
    props.responsive === true &&
    ` @media (max-width: 480px) {
   display:none;
  }`}
`
