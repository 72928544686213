import React from "react";
import {
  Container,
  InfoContainer,
  SuggestionsContainer,
} from "../GiftDetails/styles";
import ImageSlider from "../GiftDetails/ImageSlider";
import GiftBoxInfo from "./Info";
import GiftsCollection from "../GiftsCollection";
import { useParams } from "react-router-dom";
import { useProduct } from "../../hooks/Products/useProduct";
import { Spin } from "antd";
import { useFetchProducts } from "../../hooks/Products/useFetchProducts";
import { useCart } from "../../contexts/CartContext";
import BackButton from "../../AdminPanel/Components/UIKit/Buttons/BackButton";
import styled from "styled-components";

export default function GiftBoxDetailsComponent() {
  const { id } = useParams();
  const { isLoading, product } = useProduct(id?.split("_")[0]);
  const { products } = useFetchProducts();

  const Title = styled.div`
    color: #874e31;
    font-size: 4rem;
    @media only screen and (max-width: 670px) {
      font-size: 22px;
    }
  `;
  const Description = styled.div`
    font-size: 2rem;
    text-align: justify;
    padding: 0 5rem;
    @media only screen and (max-width: 670px) {
      font-size: 16px;
      padding: 0 1rem;
    }
  `;

  return (
    <Container>
      <BackButton />
      {isLoading && <Spin fullscreen />}
      {product && (
        <>
          <InfoContainer>
            <ImageSlider product={product} />
            <GiftBoxInfo product={product} />
          </InfoContainer>
          <SuggestionsContainer>
            <GiftsCollection
              title={"What's in the Box?"}
              suggestion={true}
              det={true}
              products={products
                ?.filter((prd) => product?.containedProducts?.includes(prd._id))
                ?.slice(-3)}
            />
            {products && (
              <GiftsCollection
                title={"You might also like"}
                suggestion={true}
                det={true}
                products={products
                  ?.filter(
                    (prd) => prd?.isAReadyMadeBox && prd._id !== product._id
                  )
                  ?.slice(-3)}
              />
            )}
          </SuggestionsContainer>
        </>
      )}
    </Container>
  );
}
