import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Select,
  Spin,
  message,
} from "antd";
import { Row, Divider } from "../AddGift/styles";
import TextArea from "antd/es/input/TextArea";
import ImagesUploadToCloudinary from "../../ImageUploadCloudinary/imageUploadToCloudinary";
import { CategoryOptions } from "../../../Utils/constants";
import { useFetchProducts } from "../../../../hooks/Products/useFetchProducts";
import { useCreateProduct } from "../../../../hooks/Products/useCreateProduct";
import { useUpdateProduct } from "../../../../hooks/Products/useUpdateProduct";
import { useNavigate } from "react-router-dom";
import { useProduct } from "../../../../hooks/Products/useProduct";
import QuillEditor from "../../UIKit/TextEditor";

export default function AddGiftBox({ edit, product }) {
  const [form] = Form.useForm();
  const [customIsLoading, setCustomIsLoading] = useState(false);
  const { products, refetch } = useFetchProducts();
  const {
    isLoading: isCreatingProduct,
    isSuccess,
    data,
    mutate,
    error,
  } = useCreateProduct();
  const { refetch: refetchCurrentProductOnUpdate } = useProduct(
    product && edit ? product._id : null
  );
  const {
    mutate: updateProduct,
    data: updatedProduct,
    error: updateProductError,
    isLoading: isUpdatingProduct,
  } = useUpdateProduct();
  const [showOnHomePage, setShowOnHomePage] = useState(false);
  const navigate = useNavigate();

  const onFinish = (values) => {
    const formData = new FormData();
    const images = values.images;

    formData.append("imagesWithColors", JSON.stringify(images));
    formData.append("title", values.name);
    formData.append("description", values.description);
    formData.append("price", values.price);
    if (values.products && values.products.length > 0) {
      if (typeof values.products[0] === "string") {
        formData.append("containedProducts", JSON.stringify(values.products));
      } else {
        formData.append(
          "containedProducts",
          JSON.stringify(values.products.map((prd) => prd.value))
        );
      }
    }

    formData.append(
      "scopes",
      showOnHomePage ? JSON.stringify(["homepage"]) : JSON.stringify([])
    );

    formData.append("isAReadyMadeBox", "true");

    formData.forEach(function (value, key) {
      console.log(key + ": " + value);
    });
    if (edit) {
      setCustomIsLoading(true);
      updateProduct({ id: product._id, data: formData });
    } else {
      setCustomIsLoading(true);
      mutate(formData);
    }
  };
  // For product upload success and error handling
  useEffect(() => {
    if (isSuccess) {
      setCustomIsLoading(false);
      message.success("Product Uploaded Successfully");
      refetch();
      navigate("/admin/products");
    }

    if (error) {
      setCustomIsLoading(false);
      message.error("An Error Occurred");
    }
  }, [error, isSuccess, navigate, refetch]);

  // For product update success and error handling
  useEffect(() => {
    if (updatedProduct) {
      setCustomIsLoading(false);
      const successMessage = `${updatedProduct.title} is successfully updated`;
      message.success(successMessage);
      refetch();
      refetchCurrentProductOnUpdate();
      navigate("/admin/products");
    }

    if (updateProductError) {
      setCustomIsLoading(false);
      const errorMessage =
        updateProductError?.response?.data?.error || "Product Update Failed";
      message.error(errorMessage);
    }
  }, [
    updatedProduct,
    updateProductError,
    refetchCurrentProductOnUpdate,
    refetch,
    navigate,
  ]);

  const transformProductToFormValues = (product) => {
    return {
      name: product.title,
      description: product.description,
      price: product.price,
      images: product.imagesWithColors,
      products: products
        .filter((prd) => product.containedProducts.includes(prd._id))
        .map((p) => {
          return { label: p.title, value: p._id };
        }),
    };
  };
  useEffect(() => {
    if (edit && product && products) {
      const initalValues = transformProductToFormValues(product);

      form.setFieldsValue(initalValues);
      if (product?.scopes?.includes("homepage")) {
        setShowOnHomePage(true);
      }
    }
  }, [edit, product, form, products]);
  console.log("product", product);
  return (
    <>
      {customIsLoading && <Spin fullscreen />}
      <Form
        style={{
          // maxWidth: 800
          // width: '100%',
          flex: 2,
        }}
        onFinish={onFinish}
        layout="vertical"
        form={form}
      >
        {/* <Divider>Add Details</Divider> */}
        <Form.Item
          name="name"
          label="Box Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Box Name" />
        </Form.Item>

        <Form.Item
          label="Box Description"
          name="description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          {/* <TextArea rows={6} placeholder="Description" /> */}
          <QuillEditor />
        </Form.Item>
        {products && (
          <Form.Item
            label="Add Products"
            name="products"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              options={products
                .filter((prd) => !prd?.isAReadyMadeBox)
                .map((prd) => {
                  return { label: prd?.title, value: prd?._id };
                })}
              placeholder="Select Products"
              mode="multiple"
            />
          </Form.Item>
        )}
        <Form.Item name="images" label="Upload Images">
          <ImagesUploadToCloudinary />
        </Form.Item>
        <Row>
          <Form.Item
            name="price"
            label="Price"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Box Price" />
          </Form.Item>
          {/* <Form.Item
          label="Box Quantity"
          name="quantity"
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber placeholder="Quantity" style={{ width: "100%" }} />
        </Form.Item> */}
        </Row>

        <Divider>Publish Options</Divider>
        <Form.Item name="homepage" style={{ width: "100%" }}>
          <Checkbox
            checked={showOnHomePage}
            onChange={(e) => {
              setShowOnHomePage(e.target.checked);
            }}
          >
            Show on HomePage
          </Checkbox>
        </Form.Item>

        {edit ? (
          <Row style={{ justifyContent: "flex-start" }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Changes
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Cancel
              </Button>
            </Form.Item>
          </Row>
        ) : (
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Box
            </Button>
          </Form.Item>
        )}
      </Form>
    </>
  );
}
