import "./../css/footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faPinterest,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Spin, message } from "antd";
import { useCreateSubscription } from "../hooks/Subscription/useCreateSubscription";

const Footer = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { mutate, error, isSuccess } = useCreateSubscription();

  const handleLinkClick = (link) => {
    // Use window.open to open the link in a new tab
    window.open(link, "_blank");
  };

  const handleSubscriptionSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      message.error("Please write a valid email");
      return;
    }
    setIsLoading(true);
    mutate({ email });
  };

  useEffect(() => {
    if (isSuccess) {
      message.success("You have been subscribed successfully");
      setIsLoading(false);
    }
    if (error) {
      if (error?.response?.data?.message.includes("duplicate")) {
        message.error("You are already subscribed ");
      } else {
        message.error("Error while subscribing");
      }
      setIsLoading(false);
    }
  }, [error, isSuccess]);

  return (
    <>
      {isLoading && <Spin fullscreen />}
      <footer className="footer-root">
        <div className="footer-parent">
          <div className="footer-child footer-logo-child">
            <img
              src="/Heartsybox-Logo.png"
              alt="heartsybox-log"
              className="footer-logo"
            />
            <p>
              Build custom gift boxes for your loved ones and for all occasions.
              Whether you’re just next door or in another country, Heartsy Box
              allows you to seamlessly send the perfect gift box right to your
              loved one's door.
            </p>
            <ul className="footer-icons-list">
              <li className="list-icons">
                {/* <img src="/fb-icon.png" alt="facebook-icon" /> */}
                <FontAwesomeIcon
                  icon={faFacebook}
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={() =>
                    handleLinkClick("https://facebook.com/heartsybox")
                  }
                />
              </li>
              <li className="list-icons">
                <FontAwesomeIcon
                  icon={faInstagram}
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={() =>
                    handleLinkClick("https://www.instagram.com/heartsybox")
                  }
                />
              </li>
              <li className="list-icons">
                {/* <img src='/p-icon.png' alt='pinterest-icon' /> */}
                <FontAwesomeIcon
                  icon={faPinterest}
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={() =>
                    handleLinkClick("https://www.pinterest.com/heartsybox/")
                  }
                />
              </li>
            </ul>
          </div>
          <div className="footer-child footer-menu">
            <h3>Menu</h3>
            <ul className="footer-menu-list">
              <li style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
                Home
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/picker")}
              >
                Giftspicker
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/blogs")}
              >
                Blog
              </li>
              <li style={{ cursor: "pointer" }}>contact</li>
            </ul>
          </div>
          <div className="footer-child footer-contact">
            <h3>Contact</h3>
            <ul className="footer-contact-list">
              <li className="subscribe-text">Subscribe to our email</li>
              <li>For Latest News & Updates</li>
              <li>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </li>
              <li>
                <button
                  type="button"
                  className="footer-subscribe-btn"
                  onClick={handleSubscriptionSubmit}
                >
                  Subscribe
                </button>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <section className="bottom-banner">
        <p className="roboto-font">
          Copyright 2023 M&G Reservations group Ltd | All Rights Reserved |
          Powered by HeartsyBox
        </p>
      </section>
    </>
  );
};

export default Footer;
