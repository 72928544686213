import React from "react";
import { AntSearch } from "./styles";
import { SearchOutlined } from "@ant-design/icons";

export default function Search({ placeholder, filter }) {
  const handleChange = (e) => {
    const searchTerm = e.target.value;
    filter(searchTerm);
  };

  return (
    <AntSearch
      placeholder={placeholder}
      prefix={<SearchOutlined style={{ color: "lightgray" }} />}
      onChange={handleChange}
    />
  );
}
