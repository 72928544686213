import React from 'react'
import AddBlog from '../../../Components/AddNewBlog'
import PageContent from '../../../Components/PageContent'
import PageHeading from '../../../Components/PageHeading'

export default function AddNewBlog () {
  return (
    <PageContent>
      <PageHeading heading={'New Blog'} />
      <AddBlog />
    </PageContent>
  )
}
