import { useQuery } from "@tanstack/react-query";
import { fetchCategories } from "../../services/category/categoryApi";
export const useFetchCategories = () => {
  const {
    data: categories,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useQuery({ queryKey: ["categories"], queryFn: fetchCategories });
  return {
    categories,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};
