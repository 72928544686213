import { useQuery } from "@tanstack/react-query";
import { fetchCategory } from "../../services/category/categoryApi";
export const useCategory = (id) => {
  const {
    data: category,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ["category", id],
    queryFn: () => (id ? fetchCategory(id) : null),
  });
  return {
    category,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};
