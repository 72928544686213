import styled from 'styled-components'

export const CardContainer = styled.div`
  border: 1px solid #ccc;
  background-color: white;
  padding: 2rem;
  border: 2px solid lightgray;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ProductItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 2rem;
  color: gray;
  @media only screen and (max-width: 500px) {
    font-size: 16px;
  }
`

export const GrandTotal = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 2rem;
  color: gray;
  border-top: 2px solid lightgray;
  padding: 1rem 0;
  @media only screen and (max-width: 500px) {
    font-size: 16px;
  }
`

export const ProceedButton = styled.button`
  background-color: #d29e84;
  color: white;
  border: none;
  padding: 1rem;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  margin-top: 2rem;
  width: 90%;
  font-size: 2rem;
  white-space: nowrap;
  border-radius: 10px;
  transition: 0.3s;

  @media only screen and (max-width: 500px) {
    font-size: 3rem;
  }

  &:hover {
    background-color: ${props => (props.disabled ? '#d29e84' : '#D29E84')};
    color: white;
    width: ${props => (props.disabled ? '90%' : '100%')};
    font-size: ${props => (props.disabled ? '2rem' : '2.1rem')};
  }

  &:disabled {
    background-color: #9e9e9e;
    color: lightgray;
    cursor: not-allowed;
  }
`
