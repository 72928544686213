import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 25rem;
  padding: 0 22rem;
  justify-content: space-between;

  @media only screen and (max-width: 1100px) {
    padding: 20rem 15rem 0 15rem;
    gap: 15rem;
  }
  @media only screen and (max-width: 1000px) {
    gap: 5rem;
  }
  @media only screen and (max-width: 800px) {
    padding: 15rem 2rem 0 2rem;
  }
  @media only screen and (max-width: 760px) {
    flex-direction: column;
  }
`
export const LeftContainer = styled.div``
export const RightContainer = styled.div`
  padding-left: 10rem;
  @media only screen and (max-width: 760px) {
    padding: 0;
    text-align: center;
    align-items: center;
    br {
      display: none;
    }
  }
`
export const Image = styled.img`
  width: 536px;
  height: 450px;
  flex-shrink: 0;
  @media only screen and (max-width: 1200px) {
    width: 436px;
    height: 350px;
  }
  @media only screen and (max-width: 760px) {
    width: 236px;
    height: 170px;
  }
`
