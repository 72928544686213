import './../css/sale.css'
import { useNavigate } from 'react-router-dom'

const Sale = () => {
  const navigate = useNavigate()
  return (
    <>
      <section className='main-sale'>
        <div
          className='sale-menu sale-menu1'
          onClick={() => navigate('/gifts')}
        >
          <div className='sale-menu-text'>
            <p>#KeepCelebrating</p>
            <h1 style={{ fontSize: '3.5rem' }}>With Heartsy Box</h1>
            <p className='sale-shop-now'>Shop Now</p>
          </div>
        </div>
        <div className='sale-menu sale-menu2'>
          <div className='sale-menu2-inner'>
            <p>Launch Offers</p>
            <h1 style={{ fontSize: '3.5rem' }}>Sale 10% Off</h1>
            <p className='sale-code'>CODE: WELCOME2024</p>
          </div>
        </div>
      </section>
    </>
  )
}

export default Sale
