import styled from 'styled-components'
import { Radio } from 'antd'

export const FilterContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  gap: 2rem;
  border-radius: 10px;
  height: 400px;
  padding: 1rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

  @media only screen and (max-width: 1100px) {
    flex: 3;
  }
  @media only screen and (max-width: 780px) {
    display: none;
  }
`
export const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
`
export const FilterTitle = styled.div`
  font-size: 18px;
  color: #d29e84;
`
export const FilterType = styled.div`
  font-size: 2rem;
  padding: 0 2rem;
  color: white;
  background-color: #d29e84;
`
export const Filter = styled(Radio)`
  color: #444141;
  .ant-radio-checked .ant-radio-inner {
    border-color: #d29e84 !important;
    background-color: #d29e84 !important;    
  }
  

`
export const ProductsContainer = styled.div`
  display: flex;
  flex: 8;
  gap: 2rem;
  padding-botton: 7rem;
`
