import React, { useState } from 'react'
import { Container, Title } from './styles'
import { Drawer, Button } from 'antd'
import FilterForm from './FilterForm'
import { FaFilter } from 'react-icons/fa6'

export default function MobileFilter ({
  setFilteredProducts,
  products,
  category,
  setCategory,
  priceRange,
  setPriceRange
}) {
  const handleApplyFilter = values => {
    // Gets values from FilterForm comonent
    const category = values?.category
    const priceRange = values.priceRange
    let filteredProducts = products

    if (category !== 'all') {
      filteredProducts = filteredProducts.filter(
        product => product?.categoryFilters?.type === category
      )
    }

    if (priceRange) {
      filteredProducts = filteredProducts.filter(product => {
        const productPrice = product?.price

        switch (priceRange) {
          case 1:
            return productPrice <= 50
          case 2:
            return productPrice > 50 && productPrice <= 100
          case 3:
            return productPrice > 100 && productPrice <= 200
          case 4:
            return productPrice > 200 && productPrice <= 500
          case 5:
            return productPrice > 500 && productPrice <= 1000
          case 6:
            return productPrice > 1000
          case 7:
            return true
          default:
            return false
        }
      })
    }

    // Set filtered products
    setFilteredProducts(filteredProducts)
    setOpen(false) // Close the drawer
  }

  const [open, setOpen] = useState(false)
  const showDrawer = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  return (
    <Container>
      <Title>Filter</Title>
      <Button onClick={showDrawer}>
        <FaFilter style={{ color: '#d29e84' }} />
      </Button>
      <Drawer
        // title='Filters'
        placement={'bottom'}
        closable={false}
        onClose={onClose}
        open={open}
        key={'left'}
        height={'80%'}
      >
        <FilterForm
          products={products}
          category={category}
          setCategory={setCategory}
          priceRange={priceRange}
          setPriceRange={setPriceRange}
          handleApplyFilter={handleApplyFilter}
        />
      </Drawer>
    </Container>
  )
}
