import React, { useEffect, useState } from "react";
import { Container, CardHeader, CardHeading } from "./styles";
import AntTable from "../Table";
import TableActions from "../TableActions";
import { useNavigate } from "react-router-dom";
import Search from "../UIKit/Search";
import img from "../../Assets/product_1.jpg";
import { useFetchProducts } from "../../../hooks/Products/useFetchProducts";
import { Spin, Space, message } from "antd"; // Import Spin and Space from antd
import { useDeleteProduct } from "../../../hooks/Products/useDeleteProduct";
import { useFetchCoupons } from "../../../hooks/Coupon/useFetchCoupons";
import { useDeleteCoupon } from "../../../hooks/Coupon/useDeleteCoupon";
import { formatDate } from "../../../utils/Shared/formatDate";

export default function VouchersList() {
  const navigate = useNavigate();
  const [customLoader, setCustomLoader] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const { coupons, isLoading: fetchingCoupons, refetch } = useFetchCoupons();
  const {
    mutate: deleteCoupon,
    isLoading: deletingCoupon,
    error: couponDeleteError,
    isSuccess: couponDeleted,
  } = useDeleteCoupon();

  const deleteProd = (id) => {
    setCustomLoader(true);
    deleteCoupon(id);
  };

  const filter = (searchInput) => {
    const filtered = coupons.filter((coupon) =>
      coupon.code.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilterData(filtered);
  };

  useEffect(() => {
    if (coupons) {
      setFilterData(coupons);
    }
  }, [coupons]);

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "id",
      render: (_id) => _id.substr(-5),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Valid From",
      dataIndex: "validFrom",
      key: "valid_from",
      render: (_) => formatDate(_),
    },
    {
      title: "Valid Till",
      dataIndex: "validTo",
      key: "valid_till",
      render: (_) => formatDate(_),
    },
    {
      title: "Discount Percentage",
      dataIndex: "discountPercentage",
      key: "discount",
    },
    {
      title: " ",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <TableActions edit={true} onDelete={() => deleteProd(record._id)} />
      ),
    },
  ];

  useEffect(() => {
    if (couponDeleted) {
      message.success("Coupon Deleted Successfully");
      refetch();
      setCustomLoader(false);
    }
    if (couponDeleteError) {
      message.error("Coupon Delete Failed");
      setCustomLoader(false);
    }
  }, [couponDeleted, couponDeleteError]);

  return (
    <>
      {(customLoader || fetchingCoupons) && <Spin fullscreen />}
      <Container>
        <CardHeader>
          <CardHeading>Vouchers</CardHeading>
          <Search placeholder="Search by code" filter={filter} />
        </CardHeader>

        <AntTable
          columns={columns}
          dataSource={filterData}
          pagination={{ pageSize: 10 }}
          scroll={{ y: 480 }}
        />
      </Container>
    </>
  );
}
