import React, { useRef, useState } from "react";
import {
  Container,
  ImageContainer,
  ImageSliderContainer,
  SliderImage,
  SliderImageContainer,
  ArrowIcon,
  AntCarousel
} from "./styles";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Carousel, Image } from "antd";
import styled from "styled-components";

export default function ImageSlider({ product, selectedColor }) {
  const slider = useRef(null);
  const [isSelected, setIsSelected] = useState(null);

  // Function to handle selection
  const handleSelection = (value) => {
    setIsSelected(value);
  };

  const settings = {
    dots: false,
    // infinite: true,
    speed: 500,
    slidesToScroll: 4,
  };

  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };



  
  return (
    <Container>
      {product && (
        <>
          <ImageContainer>
            <SliderImage
              src={
                selectedColor
                  ? product.imagesWithColors.find(
                      (imgWColor) => imgWColor.color === selectedColor
                    ).src
                  : product.imagesWithColors[0]["src"]
              }
            />
          </ImageContainer>
          <ImageSliderContainer>
            {product.imagesWithColors.length > 4 && (
              <ArrowIcon onClick={() => slider.current.prev()}>
                <LeftOutlined />
              </ArrowIcon>
            )}

            <div style={{ width: "100%" }}>
              <AntCarousel
                {...settings}
                afterChange={onChange}
                slidesToShow={Math.min(product?.imagesWithColors?.length, 4)}
                ref={slider}
              >
                {product.imagesWithColors.map((img) => (
                  <SliderImageContainer
                    onClick={() => handleSelection(img.src)}
                  >
                    <SliderImage
                      src={img.src}
                      isSelected={isSelected}
                      style={
                        isSelected === 1 ? { border: "2px solid #874e31" } : {}
                      }
                    />
                  </SliderImageContainer>
                ))}
              </AntCarousel>
            </div>

            {product.imagesWithColors.length > 4 && (
              <ArrowIcon onClick={() => slider.current.next()}>
                <RightOutlined />
              </ArrowIcon>
            )}
          </ImageSliderContainer>
        </>
      )}
    </Container>
  );
}
