import React, { useEffect, useState } from "react";
import { Container, CardHeader, CardHeading } from "./styles";
import AntTable from "../Table";
import TableActions from "../TableActions";
import { useNavigate } from "react-router-dom";
import Search from "../UIKit/Search";
import { useFetchBlogs } from "../../../hooks/Blogs/useFetchBlogs";
import { formatDate } from "../../../utils/Shared/formatDate";
import { useDeleteBlog } from "../../../hooks/Blogs/useDeleteBlog";
import { Spin, message } from "antd";

export default function BlogsList() {
  const navigate = useNavigate();
  const [customLoader, setCustomLoader] = useState(false);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const { blogs, refetch } = useFetchBlogs();
  const {
    mutate: deleteBlog,
    isSuccess: blogDeleted,
    error: blogDeleteError,
  } = useDeleteBlog();

  // To details page
  const showDetails = (id) => {
    navigate(`/admin/blogs/${id}`);
  };
  const onDelete = (id) => {
    setCustomLoader(true);
    deleteBlog(id);
  };
  const onEdit = (id) => {
    navigate(`/admin/blogs/${id}/edit`);
  };

  // Search Filter
  const filter = (searchInput) => {
    const filteredBlogs = blogs.filter((blg) =>
      blg?.title?.includes(searchInput)
    );
    setFilteredBlogs(filteredBlogs);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Date Added",
      dataIndex: "createdAt",
      key: "date",
      render: (v) => formatDate(v),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <div
          style={
            status === "suspended" ? { color: "orange" } : { color: "green" }
          }
        >
          {status}
        </div>
      ),
    },
    {
      title: " ",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <TableActions
          details={true}
          onExpand={() => showDetails(record._id)}
          onDelete={() => onDelete(record._id)}
          onEdit={() => onEdit(record._id)}
        />
      ),
    },
  ];

  useEffect(() => {
    if (blogs) {
      setFilteredBlogs(blogs);
    }
  }, [blogs]);

  console.log(blogs);

  useEffect(() => {
    if (blogDeleted) {
      setCustomLoader(false);
      refetch();
      message.success("Blog Deleted Successfully");
    }
    if (blogDeleteError) {
      setCustomLoader(false);
      message.error("Blog deletion failed");
    }
  }, [blogDeleted, blogDeleteError]);
  return (
    <>
      <Container>
        {customLoader && <Spin fullscreen />}
        <CardHeader>
          <CardHeading>Blogs</CardHeading>
          <Search placeholder="Search by Title" filter={filter} />
        </CardHeader>
        <AntTable
          columns={columns}
          dataSource={filteredBlogs}
          pagination={{ pageSize: 10 }}
          scroll={{ y: 480 }}
        />
      </Container>
    </>
  );
}
