import React from 'react'
import { AntdTable, TableWrapper } from './styles'

export default function AntTable ({
  columns,
  dataSource,
  pagination,
  height,
  expandableConfig
}) {
  return (
    <TableWrapper height={height}>
      <AntdTable
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        scroll={{ x: 600 }}
        expandable={expandableConfig}
      />
    </TableWrapper>
  )
}
