import React, { useEffect, useState } from "react";
import Step3SliderComponent from "./Step3SIiderComponent";
import styled from "styled-components";
import SideFilter from "../../../components/Filter";
import FilterProducts from "../../../components/FilterProducts";
import MobileFilter from "../../../components/Filter/MobileFilter";
import { useFetchProducts } from "../../../hooks/Products/useFetchProducts";
import { Spin } from "antd";

const Step3 = ({ data, updateData, nextStep, setStep }) => {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const { isLoading, products: allProducts } = useFetchProducts();
  const products = allProducts?.filter((prd) => !prd?.isAReadyMadeBox) || [];
  const [category, setCategory] = useState("all");
  const [priceRange, setPriceRange] = useState(7);
  const [showMore, setShowMore] = useState(6);

  const Container = styled.div`
    display: flex;
    gap: 2rem;
    padding-bottom: 7rem;
    @media only screen and (max-width: 780px) {
      flex-direction: column;
    }
  `;
  const handleProductSelection = (selectedProduct) => {
    // Update the selected product id in the formData
    updateData("gift", selectedProduct._id);
    setStep(3);
  };

  useEffect(() => {
    // Reset personalized inputs when the selected product changes

    updateData("giftPersonalization", {});
  }, [data.gift]);

  useEffect(() => {
    products && setFilteredProducts(products);
  }, [allProducts]);

  // console.log("formDataa", data);
  return (
    <>
      {isLoading && <Spin fullscreen />}
      {allProducts && (
        <Container>
          <MobileFilter
            products={products}
            setFilteredProducts={setFilteredProducts}
            category={category}
            setCategory={setCategory}
            priceRange={priceRange}
            setPriceRange={setPriceRange}
          />
          <SideFilter
            products={products}
            setFilteredProducts={setFilteredProducts}
            category={category}
            setCategory={setCategory}
            priceRange={priceRange}
            setPriceRange={setPriceRange}
          />
          <FilterProducts
            data={filteredProducts
              .filter((p) => p?.scopes?.includes("buildABox3"))
              .sort((a, b) => {
                const dateA = a.createdAt ? new Date(a.createdAt) : new Date(0);
                const dateB = b.createdAt ? new Date(b.createdAt) : new Date(0);
                return dateB.getTime() - dateA.getTime();
              })}
            handleProductSelection={handleProductSelection}
            nextStep={nextStep}
            updateData={updateData}
            showMore={showMore}
            setShowMore={setShowMore}
          />
        </Container>
      )}
    </>
  );
};

export default Step3;
