import React, { useState, useEffect, useRef, useCallback } from 'react'
import Slider from 'react-slick'
import { Modal, Button } from 'antd'
import {
  SubTitle,
  ColorsContainer,
  ArrowIcon,
  ColorOptContainer,
  ColorOption,
  PersonalizeHeader,
  PersonalizeContainer,
  PersonalizeBody,
  PersonalizeInput,
  MainContainer
} from '../../GiftDetails/InfoContainer/styles'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import PriceTag from '../../UiKit/PriceTag'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ImagesUploadBox from '../../../AdminPanel/Components/ImagesUpload'
import { convertToBase64 } from '../../../utils/LocalStorage/storingImages'
import { Select, message } from 'antd'
import ImageSlider from '../../GiftDetails/ImageSlider'
import { AntModal, InnerModal } from './styles'

const ProductCustomizationModal = ({
  show,
  item,
  handleProductSelection,
  nextStep,
  updateData
}) => {
  const [isModalOpen, setIsModalOpen] = useState(show)
  const [input, setInput] = useState({})
  const [personalizedCalPrice, setPersonalizedCalPrice] = useState(0)
  const slider = useRef(null)
  const [selectedColor, setSelectedColor] = useState('')
  const [guide, setGuide] = useState(false)
  const [personalizedImage, setPersonalizedImage] = useState()
  const [selectedVarations, setSelectedVariations] = useState({})
  const { Option } = Select

  const handleGuide = () => {
    setGuide(!guide)
  }

  const handleColorSelect = color => {
    setSelectedColor(color)
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(item?.imagesWithColors?.length, 4),
    slidesToScroll: 4
  }

  useEffect(() => {
    setIsModalOpen(show)
  }, [show])

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    //all variations selected validation
    if (!validateAllVariationSelected()) {
      message.error('Please Select All Variations Before Adding into Cart')
      return
    }

    setIsModalOpen(false)
    handleProductSelection(item)
    updateData('giftPersonalization', input)
    updateData('variations', selectedVarations)
    updateData('selectedColorStep3', selectedColor)

    const pInputs = Object.keys(input)
      .map(pKey => {
        return {
          key: pKey,
          value: input[pKey],
          price: item.personalizedKeys.find(v => v.label === pKey)?.price
        }
      })
      .filter(v => v.value)
    const pInputPriceSum = pInputs.reduce((sum, curr) => sum + curr?.price, 0)
    updateData('step3Price', item?.price + pInputPriceSum)
    nextStep()
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    handleProductSelection('')
    updateData('giftPersonalization', {})
  }

  const handleInput = useCallback(
    (field, value) => {
      if (!value && typeof value !== 'string') {
        return
      }
      setInput(prevInput => {
        const updatedInput = { ...prevInput, [field]: value }
        setPersonalizedCalPrice(
          item?.personalizedKeys
            .filter(pk =>
              Object.entries(updatedInput).some(
                i => i[0] === pk.label && i[1].length > 0
              )
            )
            .map(p => parseInt(p?.price))
            .reduce((acc, curr) => acc + curr, 0)
        )

        return updatedInput
      })
    },
    [item, setInput, setPersonalizedCalPrice]
  )

  useEffect(() => {
    handleInput({})
  }, [item])

  const handleVariationSelect = (variation, value) => {
    setSelectedVariations(prevState => ({
      ...prevState,
      [variation]: value
    }))
  }

  const validateAllVariationSelected = () => {
    const productVariationsNames = item.variations.map(v => v.variation)
    const selectedVariationNames = Object.keys(selectedVarations)
    if (
      JSON.stringify(productVariationsNames.sort()) ===
      JSON.stringify(selectedVariationNames.sort())
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (guide) {
      const modalContent = document.querySelector('.ant-modal-body')
      const sectionToScroll = document.querySelector('#personalization-text')
      if (modalContent && sectionToScroll) {
        sectionToScroll.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }
  }, [guide])

  return (
    <AntModal
      title={item?.title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      footer={[
        <Button key='back' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key='submit'
          style={{ backgroundColor: '#d29e84', color: 'white' }}
          onClick={handleOk}
        >
          Add To Cart
        </Button>
      ]}
    >
      <InnerModal>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {item.image && (
            <ImageSlider product={item} selectedColor={selectedColor} />
          )}
        </div>

        {!item?.shouldIgnoreColors && (
          <ColorsContainer>
            {item?.imagesWithColors?.length > 4 && (
              <ArrowIcon onClick={() => slider.current.slickPrev()}>
                <LeftOutlined />
              </ArrowIcon>
            )}
            <div style={{ width: '100%' }}>
              {item.imagesWithColors.length > 0 && <SubTitle>Colors:</SubTitle>}
              <Slider {...settings} ref={slider}>
                {item?.imagesWithColors?.map((clr, index) => (
                  <ColorOptContainer key={index}>
                    <ColorOption
                      color={clr.color}
                      onClick={() => handleColorSelect(clr.color)}
                      style={{
                        boxShadow:
                          selectedColor === clr.color
                            ? '0 0 5px 5px rgba(0,0,0,0.5)'
                            : 'none'
                      }}
                    />
                  </ColorOptContainer>
                ))}
              </Slider>
            </div>
            {item?.imagesWithColors?.length > 4 && (
              <ArrowIcon onClick={() => slider.current.slickNext()}>
                <RightOutlined />
              </ArrowIcon>
            )}
          </ColorsContainer>
        )}
        {item.variations.length > 0 && (
          <>
            <SubTitle>Variations</SubTitle>
            {item.variations.map(option => (
              <div key={option.variation}>
                <div style={{ color: 'lightgray' }}>
                  Select {option.variation}:
                </div>
                <Select
                  placeholder='Select an option'
                  style={{ width: '100%' }}
                  onChange={value =>
                    handleVariationSelect(option.variation, value)
                  }
                  value={selectedVarations[option.variation]}
                >
                  {option.subVariations.map(value => (
                    <Option key={value} value={value}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </div>
            ))}
          </>
        )}
        {item.personalizedKeys.length > 0 ? (
          <>
            <PersonalizeHeader>
              <SubTitle>Personalization</SubTitle>
              <div style={{ color: '#d29e84', fontSize: '2rem' }}>
                <i>
                  <div
                    style={{ cursor: 'pointer', fontSize: '18px' }}
                    onClick={handleGuide}
                  >
                    Guide
                  </div>
                </i>
              </div>
            </PersonalizeHeader>

            <PersonalizeContainer>
              <PersonalizeHeader>
                <div></div>
                <PriceTag price={personalizedCalPrice} small={true} />
              </PersonalizeHeader>
              <PersonalizeBody>
                {item &&
                  item?.personalizedKeys?.map((pk, index) =>
                    pk.label === 'image' ? (
                      <ImagesUploadBox
                        value={personalizedImage}
                        onChange={async e => {
                          setPersonalizedImage(
                            e.length ? [e[e?.length - 1]] : []
                          )
                          handleInput(
                            pk?.label,
                            e.length
                              ? await convertToBase64(e[e?.length - 1])
                              : ''
                          )
                        }}
                      />
                    ) : pk?.label === 'message' ? (
                      <PersonalizeInput
                        paragraph={true}
                        key={index}
                        placeholder={pk.label}
                        maxLength={pk.count}
                        onChange={e => handleInput(pk?.label, e.target.value)}
                      />
                    ) : (
                      <PersonalizeInput
                        key={index}
                        placeholder={pk?.label}
                        maxLength={pk.count}
                        value={input[pk?.label]}
                        onChange={e => {
                          handleInput(pk?.label, e.target.value)
                        }}
                      />
                    )
                  )}
              </PersonalizeBody>
            </PersonalizeContainer>
          </>
        ) : (
          <SubTitle style={{ color: 'gray', fontSize: '16px' }}>
            No personalization options for this product
          </SubTitle>
        )}
        {guide && (
          <section style={{ marginTop: '10%' }} id='personalization-text'>
            <h1 style={{ color: '#d29e84', fontSize: '22px' }}>
              Personalization Guide
            </h1>
            <p style={{ color: 'gray', textAlign: 'justify' }}>
              At Heartsy Box, we believe in making every gift uniquely yours.
              Personalize your chosen gifts with our range of customization
              options, adding that extra touch of warmth and thoughtfulness. You
              can personalize gifts by name, birth month, year, initials, image,
              and more. Personalization options will depend on the gift
              selected. Please, follow this guide to ensure you receive the
              appropriate gift.
            </p>
            <h2 style={{ fontSize: '16px', marginTop: '10px' }}>
              Name{' '}
              <span style={{ fontSize: '14px' }}>(First and/or Last).</span>
            </h2>
            <p style={{ color: 'gray', textAlign: 'justify' }}>
              Double-check that names are accurately spelled when submitting an
              order. If you need to make a name change, kindly email
              orders@heartsybox.com within the first hour of placing your order.
            </p>
            <h2 style={{ fontSize: '16px', marginTop: '10px' }}>Initials.</h2>
            <p style={{ color: 'gray', textAlign: 'justify' }}>
              Initial personalization includes the First and Last initials only.
            </p>

            <h2 style={{ fontSize: '16px', marginTop: '10px' }}>
              Birth month.
            </h2>
            <p style={{ color: 'gray', textAlign: 'justify' }}>
              Birth month personalization determines the design used for your
              gift. Embrace the uniqueness tied to each month.
            </p>

            <h2 style={{ fontSize: '16px', marginTop: '10px' }}>Image.</h2>
            <p style={{ color: 'gray', textAlign: 'justify' }}>
              For gifts with image personalization, ensure your images meet our
              minimum size requirements. If an uploaded image doesn't meet
              specifications, you'll be notified promptly.
            </p>

            <h2 style={{ fontSize: '16px', marginTop: '10px' }}>Date.</h2>
            <p style={{ color: 'gray', textAlign: 'justify' }}>
              Celebrate special occasions by adding significant dates to your
              gifts. Ensure accuracy and include any date-specific details in
              your order.
            </p>

            <h2 style={{ fontSize: '16px', marginTop: '10px' }}>
              Color Selection.
            </h2>
            <p style={{ color: 'gray', textAlign: 'justify' }}>
              Some gifts may offer personalization through color choices.
              Specify preferred colors to match the recipient's taste or the
              occasion.
            </p>

            <h2 style={{ fontSize: '16px', marginTop: '10px' }}>
              Quote or Message.
            </h2>
            <p style={{ color: 'gray', textAlign: 'justify' }}>
              Infuse your gifts with sentiments by including a personalized
              quote or message. Share your feelings or commemorate special
              occasions with words.
            </p>

            <h2 style={{ fontSize: '16px', marginTop: '10px' }}>Font Style.</h2>
            <p style={{ color: 'gray', textAlign: 'justify' }}>
              Choose from a selection of font styles for text-based
              personalizations. This adds a layer of customization to the visual
              presentation.
            </p>

            <h2 style={{ fontSize: '16px', marginTop: '10px' }}>
              Location Coordinates.
            </h2>
            <p style={{ color: 'gray', textAlign: 'justify' }}>
              Capture the essence of a specific location by incorporating
              coordinates. Ideal for commemorating places with sentimental
              value.
            </p>

            <h2 style={{ fontSize: '16px', marginTop: '10px' }}>
              Custom Artwork.
            </h2>
            <p style={{ color: 'gray', textAlign: 'justify' }}>
              Submit custom artwork or designs for personalization. This is
              perfect for one-of-a-kind, artistic gifts.
            </p>
            <p style={{ textAlign: 'justify', fontSize: '12px' }}>
              For any queries or assistance, feel free to contact our dedicated
              team at{' '}
              <a
                href='mailto:support@heartsybox.com'
                style={{ textDecoration: 'none' }}
              >
                support@heartsybox.com
              </a>
              .
            </p>
            <p
              style={{
                textAlign: 'center',
                fontSize: '13px',
                color: 'rgb(210, 158, 132)',
                paddingTop: '1rem'
              }}
            >
              Thank you for choosing Heartsy Box, where every gift tells a
              personalized story!
            </p>
          </section>
        )}
      </InnerModal>
    </AntModal>
  )
}

export default ProductCustomizationModal
