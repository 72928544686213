import React from "react";
import "../css/giftsCollection.css";
import ShopByCollection from "./UiKit/Buttons/ShopByCollection";
import ProductCard from "./ProductCard";
import { useNavigate } from "react-router-dom";
import { Empty } from "antd";

export default function GiftsCollection({
  category,
  title,
  btnTitle,
  suggestion,
  det,
  products,
}) {
  const navigate = useNavigate();
  const goToCategory = (title) => {
    navigate(`/gifts/${title}`);
  };
  const seeDetails = (id) => {
    navigate(`/${id}`);
  };

  return (
    <div className="collection-container">
      <div
        className="collection-header"
        style={suggestion && { color: "#874e31" }}
      >
        {title}
      </div>
      <div className="collection-body" style={det && { gap: "5rem" }}>
        {products &&
          products?.map((item) => {
            return (
              <ProductCard
                key={item._id}
                id={item._id}
                title={item.title}
                price={item.price}
                img={
                  item.imagesWithColors
                    ? item?.imagesWithColors[0]?.src
                    : item.image[0]
                }
                det={det}
                prd={item}
              />
            );
          })}
      </div>
      {!category && !suggestion && (
        <div className="collection-footer">
          <ShopByCollection
            keyword={btnTitle}
            // onCLick={() => navigate(`/gifts/${title}`)}
            // onClick={()=> console.log("Clicked on ",title)}
            onClick={() => goToCategory(title)}
          />
        </div>
      )}
      {products && products.length < 1 && (
        <Empty
          description="No products available"
          style={{ marginTop: "1rem" }}
        />
      )}
    </div>
  );
}
