import React, { useState } from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { message } from 'antd'
import { Alert } from 'antd'

const ImagesUploadBox = ({ value = [], onChange, readonly = false }) => {
  const [messageApi, contextHolder] = message.useMessage()

  const onDrop = acceptedFiles => {
    if (acceptedFiles.length > 1) {
      messageApi.open({
        type: 'error',
        content: 'Please upload only iamge file and it should be less than 2 MB'
      })
      return
    }

    const newImages = acceptedFiles.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    )

    onChange([...value, ...newImages])
  }

  const removeImage = (index, event) => {
    event.preventDefault()

    const updatedImages = [...value]
    updatedImages.splice(index, 1)
    onChange(updatedImages)
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop,
    maxFiles: 1,
    maxSize: 2 * 1024 * 1024,
  })

  return (
    <Container>
      {!readonly && (
        <DropzoneContainer {...getRootProps()}>
          <input {...getInputProps()} />
          <DropzoneText>
          <>
            <p>Drag & drop here, or click to select</p>
            <p> <span  style={{ color: 'red' }}>*</span>Only a single file is allowed.</p>
          </>
          </DropzoneText>
        </DropzoneContainer>
      )}
      <ImageContainer>
        {value.map((file, index) => (
          <ImagePreview key={index}>
            <Image src={file.preview} alt={`Preview ${index}`} />
            {!readonly && (
              <RemoveButton onClick={e => removeImage(index, e)}>
                x
              </RemoveButton>
            )}
          </ImagePreview>
        ))}
      </ImageContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const DropzoneContainer = styled.div`
  border: 2px dashed gray;
  // background-color: lightgray;
  color: gray;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
`

const DropzoneText = styled.p`
  margin: 0;
`

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ImagePreview = styled.div`
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
`

const Image = styled.img`
  width: 75px;
  height: 75px;
  object-fit: cover;
  margin-right: 5px;
  margin-bottom: 5px;
`

const RemoveButton = styled.button`
  position: absolute;
  top: -5px;
  padding: 0 !important;
  right: 7px;
  color: black;
  border: none;
  cursor: pointer;
  background-color: transparent;
`
// };

export default ImagesUploadBox
