import React from 'react'
import OrdersList from '../../Components/OrdersList'
import PageContent from '../../Components/PageContent'
import PageHeading from '../../Components/PageHeading'

export default function OrderManagement () {
  return (
    <PageContent>
      <PageHeading heading={'Orders Management'} />
      <OrdersList />
    </PageContent>
  )
}
