import React, { useEffect, useState } from "react";
import { Container, CardHeader, CardHeading } from "./styles";
import AntTable from "../Table";
import TableActions from "../TableActions";
import { useNavigate } from "react-router-dom";
import Search from "../UIKit/Search";
import img from "../../Assets/product_1.jpg";
import { useFetchProducts } from "../../../hooks/Products/useFetchProducts";
import { Spin, Space, message } from "antd"; // Import Spin and Space from antd
import { useDeleteProduct } from "../../../hooks/Products/useDeleteProduct";

export default function ProductsList({ deleteProd }) {
  const navigate = useNavigate();
  const [filterData, setFilterData] = useState([]);
  const { isLoading, products, refetch } = useFetchProducts();
  const {
    mutate: deleteProduct,
    isLoading: isDeleting,
    data: deletedProduct,
    error: deleteProductError,
  } = useDeleteProduct();

  const showDetails = (id) => {
    navigate(`${id}`);
  };

  const edit = (id) => {
    navigate(`/admin/products/${id}`);
  };

  const filter = (searchInput) => {
    // Filter products based on the title containing the search input
    const filteredProducts = products.filter((product) =>
      product.title.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilterData(filteredProducts);
  };

  useEffect(() => {
    deletedProduct &&
      refetch() &&
      message.success(`${deletedProduct.title} is successfully deleted`);
    deleteProductError &&
      message.error(
        deleteProductError?.response?.data?.error || "Product Delete Failed"
      );
  }, [deletedProduct, deleteProductError, refetch]);

  const columns = [
    {
      title: "Product",
      dataIndex: "_id",
      key: "_id",
      render: (_, record) => (
        <Space>
          <img
            src={record.imagesWithColors[0]?.src}
            alt={record.title}
            style={{ width: "55px", height: "55px" }}
          />
          <div>{record.title}</div>
        </Space>
      ),
    },
    {
      title: "Type",
      dataIndex: "isAReadyMadeBox",
      key: "isAReadyMadeBox",
      render: (isAReadyMadeBox) => (
        <div>{isAReadyMadeBox === true ? "Gift Box" : "Gift"}</div>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (_, record) => <>${record?.price}</>,
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <TableActions
          onEdit={() => edit(record._id)}
          onDelete={() => deleteProduct(record._id)}
        />
      ),
    },
  ];

  useEffect(() => {
    if (products) {
      setFilterData(products);
    }
  }, [products]);

  return (
    <>
      {(isLoading || isDeleting) && <Spin fullscreen />}
      <Container>
        <CardHeader>
          <CardHeading>Products</CardHeading>
          <Search placeholder="Search by name" filter={filter} />
        </CardHeader>
        {products && (
          <AntTable
            columns={columns}
            dataSource={filterData.sort((a, b) => {
              const dateA = a.createdAt ? new Date(a.createdAt) : new Date(0);
              const dateB = b.createdAt ? new Date(b.createdAt) : new Date(0);
              return dateB.getTime() - dateA.getTime();
            })}
            pagination={{ pageSize: 10 }} // Adjust the pageSize as needed
            scroll={{ y: 480 }} // Adjust the height as needed
          />
        )}
      </Container>
    </>
  );
}
