import React, { useState } from "react";
import { Container, CardHeader, CardHeading } from "./styles";
import AntTable from "../Table";
import { useNavigate } from "react-router-dom";
import { useFetchOrders } from "../../../hooks/Orders/useFetchOrders";
import Search from "../UIKit/Search";
import { Spin, Space } from "antd";
import { formatDate } from "../../../utils/Shared/formatDate";
import { EyeOutlined } from "@ant-design/icons"; // Import the EyeOutlined icon

export default function OrdersList() {
  const { orders, isLoading } = useFetchOrders();
  const navigate = useNavigate();
  const [filteredOrders, setFilteredOrders] = useState(null);

  // To details page
  const showDetails = (id) => {
    navigate(`/admin/orders/${id}`);
  };

  // Search Filter
  const filter = (searchInput) => {
    // Implement your search logic here
    // For simplicity, let's assume you want to filter by any column
    const filteredData = orders.filter((order) => {
      return Object.values(order).some((value) =>
        String(value).toLowerCase().includes(searchInput.toLowerCase())
      );
    });

    // Set the filtered data to state
    setFilteredOrders(filteredData);
  };

  const columns = [
    {
      title: "#Order",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (status) => (
    //     <div
    //       style={
    //         status === "Pending" ? { color: "orange" } : { color: "green" }
    //       }
    //     >
    //       {status}
    //     </div>
    //   ),
    // },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => <>${price}</>,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <EyeOutlined
            onClick={() => showDetails(record.id)}
            style={{ cursor: "pointer" }}
          />
        </Space>
      ),
    },
  ];

  return (
    <Container>
      {isLoading && <Spin fullscreen />}
      <CardHeader>
        <CardHeading>Orders</CardHeading>
        <Search placeholder="Search by ID" filter={filter} />
      </CardHeader>
      {filteredOrders ? (
        <AntTable
          columns={columns}
          dataSource={filteredOrders.map((ord) => ({
            id: ord._id,
            date: formatDate(ord.createdAt),
            items: ord.products.length,
            status: ord.paymentStatus,
            price: ord.totalPrice,
          }))}
          pagination={{ pageSize: 10 }}
          scroll={{ y: 480 }}
        />
      ) : (
        orders && (
          <AntTable
            columns={columns}
            dataSource={orders.sort((a, b) => {
                const dateA = a.createdAt ? new Date(a.createdAt) : new Date(0);
                const dateB = b.createdAt ? new Date(b.createdAt) : new Date(0);
                return dateB.getTime() - dateA.getTime();
              }).map((ord) => ({
              id: ord._id,
              date: formatDate(ord.createdAt),
              items: ord.products.length,
              status: ord.paymentStatus,
              price: ord.totalPrice,
            }))}
            pagination={{ pageSize: 10 }}
            scroll={{ y: 480 }}
          />
        )
      )}
    </Container>
  );
}
