import React from "react";
import {
  BlogDetailsContainer,
  BlogTitle,
  BlogDescription,
  BlogImage,
  BlogBody,
} from "./styles";
import moment from "moment";
import { Avatar, Space,SL } from "antd";
import { AntDesignOutlined, UserOutlined } from "@ant-design/icons";
import SanitizedHTML from "../../AdminPanel/Components/SanitizeHtml";

export default function BlogDetailsComponent({
  title,
  description,
  imageUrl,
  body,
  author,
  publishedDate,
}) {
  const formattedDate = moment(publishedDate).format("LL");
  return (
    <BlogDetailsContainer>
      <Space direction="vertical" style={{ rowGap: 0 }}>
        {/* <span style={{ color: "#888", fontSize: "10px" }}>
          Published By :
          <span
            style={{ fontWeight: "bold", fontSize: "12px", color: "black" }}
          >
            {" " + author}
          </span>
        </span> */}
        <span style={{ color: "#888", fontSize: "10px" }}>{formattedDate}</span>
      </Space>

      <BlogTitle>{title}</BlogTitle>
      <BlogDescription>{description}</BlogDescription>
      <BlogImage src={imageUrl} alt="Blog" />
      <BlogBody>
        <SanitizedHTML html={body} />
      </BlogBody>
    </BlogDetailsContainer>
  );
}
