import React, { useEffect, useState } from "react";
import Step6 from "../DesignGift/components/Step6";
import AntSteps from "../../components/AntSteps";
import { useCart } from "../../contexts/CartContext";
import { useCreateOrder } from "../../hooks/Orders/useCreateOrder";
import { Spin, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchOrders } from "../../hooks/Orders/useFetchOrders";
import { loadStripe } from "@stripe/stripe-js";
import { useFetchCoupons } from "../../hooks/Coupon/useFetchCoupons";
import axiosInstance from "../../utils/Axios/axiosConfig";
import { useCreateCheckoutSession } from "../../hooks/Stripe/useCreateCheckoutSession";
import { checkSessionStatus } from "../../services/Stripe/stripeApi";
import {
  storeImagesInLocalStorage,
  getImagesFromLocalStorage,
  convertBase64ToFiles,
} from "../../utils/LocalStorage/storingImages";
import {
  clearBuildABoxCurrentStepToLocalStorage,
  clearBuildABoxDataToLocalStorage,
} from "../../utils/Shared/buildABoxBackup";

export default function Checkout() {
  const stripe = window.Stripe(
    "pk_live_51K9Lu5KEiBYYKUXN409JZtyVkYBxpkjnx6ed5mbyrEjXaRceHNutaPruYVcQECnfrf5CutSanx24qhFA4mCPJUGY00jlp7ZP5d"
  );
  const [stripeSessionId, setStripeSessionId] = useState("");
  const [customLoader, setIsCustomLoader] = useState(true);
  const { totalCartPrice, cart, clearCart } = useCart();
  const { refetch: refetchOrders } = useFetchOrders();
  const navigate = useNavigate();
  const { mutate, isSuccess, error } = useCreateOrder();
  const { orders } = useFetchOrders();
  const {
    mutate: createCheckoutSession,
    data: stripeResponse,
    isSuccess: stripeSuccess,
    error: stripeError,
    isLoading,
  } = useCreateCheckoutSession();

  const [formData, setFormData] = useState({
    products: cart || [{}],
    totalPrice: totalCartPrice,
    couponCode: "",
    paymentStatus: "",
    stripePaymentId: "",
    senderInfo: {
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      emailAddress: "",
      couponCode: "",
    },
    recipientInfo: {
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      emailAddress: "",
    },
    couponDiscountPercentage: "",
  });
  const updateFormData = (step, data) => {
    setFormData((prevData) => ({
      ...prevData,
      [step]: data,
    }));
  };

  const handleCreateOrder = async () => {
    if (!validateSenderInfo(formData.senderInfo)) {
      // Validation failed, don't proceed with order creation
      return;
    }

    setIsCustomLoader(true);
    const dataForOrder = {
      products: formData.products,
      paymentStatus: "",
      stripePaymentId: "",
      totalPrice: formData.totalPrice,
      couponCode: formData?.senderInfo?.couponCode,
      senderInfo: formData.senderInfo,
      recipientInfo: formData.recipientInfo,
      images: formData.products
        .filter((prd) =>
          prd.personalizedInputs?.some((pI) => pI.key === "image")
        )
        .map(
          (p) => p.personalizedInputs?.find((pI) => pI.key === "image").value
        ),
    };

    localStorage.setItem("dataForOrder", JSON.stringify(dataForOrder));

    try {
      createCheckoutSession({
        cart: cart.map((p, i) => ({
          ...p,
          personalizedInputs: p?.personalizedInputs?.map((pI) =>
            pI.key === "image" ? { ...pI, value: "img" } : pI
          ),
        })),
        couponDiscount: formData.couponDiscountPercentage,
      });
    } catch (err) {
      message.error(err.message);
      return;
    }
  };

  const stepProps = {
    data: formData,
    updateData: updateFormData,
  };

  useEffect(() => {
    if (isSuccess) {
      setIsCustomLoader(false);
      refetchOrders();
      clearCart();
      message.success("Your order is created successfully");
      localStorage.removeItem("dataForOrder");
      clearBuildABoxCurrentStepToLocalStorage();
      clearBuildABoxDataToLocalStorage();
      navigate("/orderConfirmed");
    }
    if (error) {
      setIsCustomLoader(false);
      clearCart();
      localStorage.removeItem("dataForOrder");
      clearBuildABoxCurrentStepToLocalStorage();
      clearBuildABoxDataToLocalStorage();
      message.error("Order is not successful. Something went wrong");
    }
  }, [isSuccess, error, refetchOrders]);

  const validateSenderInfo = (senderInfo) => {
    const requiredFields = [
      "firstName",
      "lastName",
      "address",
      "city",
      "state",
      "zipCode",
      "emailAddress",
    ];

    for (const field of requiredFields) {
      if (!senderInfo[field]) {
        message.error(
          `Please fill in the ${
            field === "zipCode" ? "ZIP code" : field
          } field.`
        );
        return false;
      }
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(senderInfo.emailAddress)) {
      message.error("Please enter a valid email address.");
      return false;
    }

    // Additional validation checks as needed

    return true; // Validation passed
  };

  useEffect(() => {
    async function fetchStatus() {
      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get("session_id");
      if (sessionId) {
        setStripeSessionId(sessionId);
        const { session: sessionFromStripe } = await checkSessionStatus(
          sessionId
        );
        if (sessionFromStripe.status === "complete" && orders) {
          const isOrderCreated = orders.find(
            (ord) => ord.stripePaymentId === sessionFromStripe.id
          );
          if (isOrderCreated) {
            message.error("Order already created against this payment");
            return;
          }

          const orderDataFromLocalStorage = JSON.parse(
            localStorage.getItem("dataForOrder")
          );
          //new flow when image added in personalized
          const retrievedFiles = convertBase64ToFiles(
            orderDataFromLocalStorage.images
          );
          orderDataFromLocalStorage.images = retrievedFiles;

          const formDataForOrder = new FormData();

          // Append each property to FormData

          let modifiedProducts = orderDataFromLocalStorage.products.map(
            (p, i) =>
              p?.personalizedInputs
                ? {
                    ...p,
                    personalizedInputs: p.personalizedInputs.map((pI) =>
                      pI.key === "image" ? { ...pI, value: "img" } : pI
                    ),
                  }
                : p
          );

          //phase2
          //Variations
          modifiedProducts = modifiedProducts.map((prdData) => ({
            ...prdData,
            variations: prdData?.variations
              ? Object.keys(prdData?.variations).map((varKey) => ({
                  key: varKey,
                  value: prdData?.variations[varKey],
                }))
              : [],
          }));

          //before that detaching base64 image
          //
          formDataForOrder.append("products", JSON.stringify(modifiedProducts));
          formDataForOrder.append(
            "paymentStatus",
            orderDataFromLocalStorage.paymentStatus
          );
          formDataForOrder.append("stripePaymentId", sessionFromStripe?.id);
          formDataForOrder.append(
            "totalPrice",
            orderDataFromLocalStorage.totalPrice
          );
          formDataForOrder.append(
            "personalMessage",
            orderDataFromLocalStorage?.personalMessage
          );
          formDataForOrder.append(
            "couponCode",
            orderDataFromLocalStorage.couponCode
          );

          // Append nested objects or arrays after stringifying them
          formDataForOrder.append(
            "senderInfo",
            JSON.stringify(orderDataFromLocalStorage.senderInfo)
          );
          formDataForOrder.append(
            "recipientInfo",
            JSON.stringify(orderDataFromLocalStorage.recipientInfo)
          );

          // Handle File objects separately
          orderDataFromLocalStorage.images.forEach((image, index) => {
            formDataForOrder.append("images", image);
          });
          formDataForOrder.append("paymentStatus", sessionFromStripe.status);

          for (const pair of formDataForOrder.entries()) {
            console.log(pair[0], pair[1]);
          }

          mutate(formDataForOrder);
        }
      } else {
        setIsCustomLoader(false);
      }
    }
    fetchStatus();
  }, [orders, mutate]);

  if (stripeSuccess && stripeResponse) {
    stripe.redirectToCheckout({
      sessionId: stripeResponse.id,
    });
  }
  if (stripeError) {
    message.error("Couldnt made stripe session");
    setIsCustomLoader(false);
  }

  useEffect(() => {
    updateFormData("totalPrice", totalCartPrice);
  }, []);

  return (
    <div>
      {customLoader && <Spin fullscreen />}
      <AntSteps checkout={true} />
      {!stripeSessionId && (
        <Step6
          isCheckoutPageOnly={true}
          {...stepProps}
          totalPrice={formData.totalPrice}
          handleCreateOrder={handleCreateOrder}
          totalCartPrice={totalCartPrice}
        />
      )}
    </div>
  );
}
