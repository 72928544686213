import React from 'react'
import Banner from '../../components/Banner'
import Sale from '../../components/Sale'
import Services from '../../components/Services'
import Working from '../../components/Working'
import ProductCategory from '../../components/ProductCategory'
import Giftpack from '../../components/Giftpack'
import GiftPickerSection from '../../components/GiftPicker'
import CustomGiftbox from '../../components/CustomGiftbox'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import '../../App.css'
import MetaTags from '../../components/MetaTags'

function HomePage () {
  return (
    <>
      <MetaTags
        title='HeartsyBox - Build Custom
               Gift Boxes For Your Loved Ones '
        description='Discover a world
                     of thoughtfully curated gifts at
                     HeartsyBox. From personalized
                     treasures to ready-made surprises,
                     find the perfect expression for every
                     occasion.'
      />
      <Banner />
      <Services />
      <Working />
      <ProductCategory />
      <Giftpack />
      <Sale />
      <GiftPickerSection />
      <CustomGiftbox />
    </>
  )
}

export default HomePage
