import styled from 'styled-components'
import { Dropdown } from 'antd'
import { Button } from 'antd'

export const AntdDropdown = styled(Dropdown)`
  padding: 0;
  box-shadow: 0 0 0 0;
  margin-left: -2.5rem;
  margin-top: -.7rem;
  @media only screen and (max-width: 1440px) {
    margin-top: -0.8rem;
  }
  @media only screen and (max-width: 1300px) {
    margin-top: -1.2rem;
  }
  @media only screen and (max-width: 1200px) {
    margin-top: -0.5rem;
  }
`

export const MenuButton = styled(Button)`
  background-color: transparent;
  border: none;
  padding: 0;
`
