import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Space, message } from "antd";

const VideoUploader = ({ value, onChange }) => {
  const [videoPreview, setVideoPreview] = useState(value ? value : null);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 1) {
      message.error("Please upload only one video file.");
      return;
    }
    const videoFile = acceptedFiles[0];

    if (videoFile && videoFile.type.startsWith("video/")) {
      setVideoPreview(URL.createObjectURL(videoFile));
      onChange(videoFile);
      // Handle the uploaded file here if needed
      console.log(videoFile);
    } else {
      console.log(videoFile)
      message.error("Please upload a valid video file and it should be less than 50 MB.");
    }
  }, []);

  const onDeleteClick = () => {
    if (videoPreview) {
      URL.revokeObjectURL(videoPreview);
      setVideoPreview(null);
      onChange(null)
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "video/*",
    maxFiles: 1,
    maxSize: 50 * 1024 * 1024,
  });


  
  return (
    <div>
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the video here...</p>
        ) : (
          <>
            <p>Drag & drop here, or click to select</p>
            <p>
              {" "}
              <span style={{ color: "red" }}>*</span>Only a single file is
              allowed.
            </p>
          </>
        )}
      </div>
      {videoPreview && (
        <Space direction={"vertical"} style={{ marginTop: "10px" }}>
          <p>Video Preview:</p>
          <video width="320" height="240" controls>
            <source src={videoPreview} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <Button
            onClick={onDeleteClick}
            style={{
              backgroundColor: "#800020",
              color: "white",
              border: "none",
            }}
          >
            Remove Video
          </Button>
        </Space>
      )}
    </div>
  );
};

const dropzoneStyles = {
  border: "2px dashed gray",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  color: "gray",
};

export default VideoUploader;
