import React, { useEffect, useState } from "react";
import { Container, CardHeader, Title, Content, DataContainer } from "./styles";
import BackButton from "../UIKit/Buttons/BackButton";
import SanitizedHTML from "../SanitizeHtml";
import { Button, message } from "antd";
import { RiDeleteBinLine } from "react-icons/ri";
import { useUpdateBlog } from "../../../hooks/Blogs/useUpdateBlog";
import { useFetchBlogs } from "../../../hooks/Blogs/useFetchBlogs";
import ImagesUploadBox from "../ImagesUpload";

export default function BlogDetailsComponent({ data, refetch }) {
  const [customLoader, setCustomLoader] = useState(false);
  const { mutate, isLoading, isSuccess, error } = useUpdateBlog();
  const { refetch: refetchAllBlogs } = useFetchBlogs();

  const handleBlogUpdate = (updateData) => {
    mutate({ id: data._id, data: updateData });
  };
  
  useEffect(() => {
    if (isSuccess) {
      setCustomLoader(false);
      refetch();
      refetchAllBlogs();
      message.success("Blog Updated Successfully");
    }
    if (error) {
      setCustomLoader(false);
      message.error("Blog Update Error");
    }
  }, [isSuccess, error]);

  
  return (
    <Container>
      <CardHeader>
        <BackButton />
        <div style={{ display: "flex", gap: "2rem", border: "none" }}>
          <Button
            type="primary"
            onClick={() => {
              handleBlogUpdate({ status: "published" });
            }}
            disabled={data?.status === "published"}
          >
            Publish
          </Button>
          <Button
            disabled={data?.status === "suspended"}
            onClick={() => {
              handleBlogUpdate({ status: "suspended" });
            }}
          >
            Suspend
          </Button>
        </div>
      </CardHeader>

      <div style={{ padding: "2rem" }}>
        <DataContainer>
          <Title>ID:</Title>
          <Content>{data._id}</Content>
        </DataContainer>
        <DataContainer>
          <Title>Title:</Title>
          <Content>{data.title}</Content>
        </DataContainer>
        <DataContainer>
          <Title>Desription:</Title>
          <Content style={{ color: "gray" }}>{data.description}</Content>
        </DataContainer>
        <DataContainer>
          <Title>Content:</Title>
          <Content>
            <Content style={{ color: "gray" }}>
              <SanitizedHTML html={data.content} />
            </Content>
          </Content>
        </DataContainer>
        <DataContainer>
          <Title>Images:</Title>
          <Content>
            <ImagesUploadBox
              value={data.images.map((i) => {
                return { preview: i };
              })}
              readonly
            />
          </Content>
        </DataContainer>
      </div>
    </Container>
  );
}
