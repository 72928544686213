import React, { useState } from "react";
import { Container, Header, Option, Selectable } from "../Q1/styles";
import { Question, OptionText } from "../../styles";
import { Radio, Space } from "antd";
import { AntRadio } from "./styles";

export default function Q3({
  setStep,
  value = null,
  setValue,
  she,
  categories,
}) {
  const [isClicked, setIsClicked] = useState(value);

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setIsClicked(e.target.value);
    setValue(e.target.value);
    setStep(3);
  };
  const forPersonRelationCategory = categories.find(
    (cat) => cat.name === "forPersonRelation"
  );

  return (
    <Container>
      <Header>
        <Question>Let me guess {she ? "she's" : "he's"} your?</Question>
      </Header>
      <div>
        <Radio.Group onChange={onChange} value={value}>
          <Space direction="vertical">
            {categories &&
              forPersonRelationCategory &&
              forPersonRelationCategory.values.map((c) => (
                <AntRadio value={c}>
                  <OptionText>{c}</OptionText>
                </AntRadio>
              ))}
          </Space>
        </Radio.Group>
      </div>
    </Container>
  );
}
