import styled from 'styled-components'
import { Input } from 'antd'

export const AntSearch = styled(Input)`
  width: 80rem;
  // padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  @media (max-width: 1100px) {
    width: 60rem;
  }
  @media (max-width: 1200px) {
    width: 60rem;
  }
  @media (max-width: 1020px) {
    width: 40rem;
  }
  @media (max-width: 480px) {
    width: 30rem;
  }
`
