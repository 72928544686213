import React from 'react'
import { Container, Image } from './styles'
import img3 from '../../../assets/images/giftPicker/img6.jpg'
import { SecondaryHeading, Text } from '../styles'

export default function Section3 () {
  return (
    <Container>
      <SecondaryHeading>How it works</SecondaryHeading>
      <Text>
        Spend less time finding the perfect gift and more
        <br /> time celebrating with those you love.
      </Text>

      <Image src={img3} />
    </Container>
  )
}
