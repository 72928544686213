import styled from 'styled-components'
import { Button } from 'antd'

export const AntButton = styled(Button)`
  border: none;
  color: white;
  background-color: #d90000;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  height: auto;
  width: 8rem;
  box-shadow: 0 0 0 0;
  ${props => !props.simple && `flex:1; padding:0;`}

  &:hover {
    color: white !important;
  }

  @media (max-width: 540px) {
    font-size: 14px;
    padding: 0.75rem;
  }
`
