import { Modal } from 'antd'
import styled from 'styled-components'

export const AntModal = styled(Modal)`
  width: 40%;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`

export const InnerModal = styled.div`
  width: 100%;
  height: 60rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  /* Hide scrollbar */
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 480px) {
    height: 55rem;
  }
`
