import React, { useEffect, useState } from "react";
import {
  Container,
  ImageContainer,
  Image,
  InfoContainer,
  Title,
  Description,
  AntInput,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { useCreateSubscription } from "../../../hooks/Subscription/useCreateSubscription";
import { Spin, message } from "antd";

export default function BlogCard({ img, flex, info, full, data }) {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { mutate, error, isSuccess } = useCreateSubscription();
  const navigate = useNavigate();

  const handleSubscriptionSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      message.error("Please write a valid email");
      return;
    }
    setIsLoading(true);
    mutate({ email });
  };

  useEffect(() => {
    if (isSuccess) {
      message.success("You have been subscribed successfully");
      setIsLoading(false);
      setEmail("");
    }
    if (error) {
      if (error?.response?.data?.message.includes("duplicate")) {
        message.error("You are already subscribed ");
      } else {
        message.error("Error while subscribing");
      }
      setIsLoading(false);
    }
  }, [error, isSuccess]);

  return (
    <Container flex={flex} style={{ cursor: "pointer" }}>
      {isLoading && <Spin fullscreen />}
      {info ? (
        <InfoContainer info={info}>
          <Title>
            Subscribe for more gift idea tips - right in your inbox!
          </Title>
          <AntInput
            placeholder="email"
            style={{ maxWidth: "500px" }}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <button
            type="button"
            className="footer-subscribe-btn"
            onClick={handleSubscriptionSubmit}
          >
            Subscribe
          </button>
        </InfoContainer>
      ) : (
        <>
          <div
            onClick={() => {
              navigate("/blogs/" + data?._id);
            }}
          >
            <ImageContainer>
              <Image src={img} full={full} />
            </ImageContainer>
            <InfoContainer>
              <Title>{data?.title}</Title>
              <Description>{data?.description}</Description>
            </InfoContainer>
          </div>
        </>
      )}
    </Container>
  );
}
