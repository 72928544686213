import styled from 'styled-components'
import { Modal } from 'antd'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
`

export const Heading = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: #141414;
  letter-spacing: -0.48px;
`
export const AntModal = styled(Modal)`
  .ant-modal,
  .ant-modal-content {
    border-radius: 20px !important;
    overflow: hidden;
  }
`
export const DeleteText = styled.div`
  color: #141414;
  text-align: center;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`
