import React, { useState } from 'react'
import { Container, Button } from './styles'
import { FiEdit2 } from 'react-icons/fi'
import { AiOutlineExport } from 'react-icons/ai'
import DeleteModal from '../Modal/Delete'
// import AlertModal from '../Modal/Alerts'

export default function TableActions ({
  edit,
  remove,
  details,
  onExpand,
  onEdit,
  onDelete,
  detailsNotAllowed,
  removeNotAllowed
}) {
  return (
    <Container>
      {details && (
        <Button color='#74829C' onClick={onExpand}>
          <AiOutlineExport />
        </Button>
      )}
      {!edit && (
        <Button color='#74829C' onClick={onEdit}>
          <FiEdit2 />
        </Button>
      )}
      {!remove && (
        <Button color='#D90000'>
          <DeleteModal onSubmit={onDelete} />
        </Button>
      )}
    </Container>
  )
}
