import styled from 'styled-components'
import { Button } from 'antd'

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 2px solid lightgray;
  padding: 2rem;
  gap: 2rem;

  @media only screen and (max-width: 500px) {
    gap: 2rem !important;
    padding:0;
  }
`
export const ImageContainer = styled.div`
  width: 20rem;
  // border: 2px solid #874e31;
  border-radius: 5px;
  overflow: hidden;
  @media only screen and (max-width: 500px) {
    width: 280px;
    height: 180px;
  }
`

export const Image = styled.img`
  width: 100%;
  object-fit: cover;
  @media only screen and (max-width: 500px) {
    // width: 150px;
    // height: 200px;
    width: 100%;
  }
`

export const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    // gap: 2rem;
    margin:0 !important;
  }
`

export const Name = styled.h3`
  margin: 0;
  font-size: 3rem;
  padding: 1rem 0;
  color: #585555;
  @media only screen and (max-width: 500px) {
    font-size: 20px;
    color: black;
    padding:0;
  }
`

export const Color = styled.p`
  margin: 0;
  font-size: 2rem;
  color: #585555;
  @media only screen and (max-width: 500px) {
    font-size: 16px;
    // padding: 1rem 0;
  }
`

export const QuantitySection = styled.div`
  display: flex;
  align-items: center;
  color: #585555;
  font-size: 2rem;
  gap: 2rem;
  @media only screen and (max-width: 500px) {
    font-size: 16px;
    padding: 0.5rem 0;
    order: 3;
  }
`

export const QuantityButton = styled(Button)`
  // &:hover {
  //   color: #874e31 !important;
  // }
  @media only screen and (max-width: 500px) {
    padding: 0 5px;
    box-shadow: 0 0 0 0;
    border: 1px solid black;
    padding: 0 1rem;
  }
`

export const Price = styled.div`
  margin: 0;
  font-size: 2rem;
  text-align: center;
  @media only screen and (max-width: 500px) {
    font-size: 20px;
    order: 2;
  }
`

export const RemoveButton = styled(Button)`
  color: red;
  border: none;
  font-size: 2rem;
  padding: 0;
  box-shadow: 0 0 0 0;
  &:hover {
    background-color: white;
    color: red !important;
  }

  @media only screen and (max-width: 500px) {
    font-size: 20px;
    // margin-left: auto;
    // padding: 0 3rem !important;
    order: 4;
  }
`
