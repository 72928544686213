import React from 'react'
import '../css/giftBox.css'
import PriceTag from './UiKit/PriceTag'
import AddToCartButton from './UiKit/Buttons/AddToCart'
import { useNavigate } from 'react-router-dom'
import { useCart } from '../contexts/CartContext'
import SanitizedHTML from '../AdminPanel/Components/SanitizeHtml'

export default function GiftBox ({
  img,
  title,
  price,
  description,
  // reverse,
  id,
  idx,
  prd
}) {
  const navigate = useNavigate()
  const { addToCart } = useCart()

  const truncateDescription = (text, limit) => {
    const words = text.split(' ')
    if (words.length > limit) {
      return words.slice(0, limit).join(' ') + '...'
    } else {
      return text
    }
  }

  const reverse = idx % 2 != 0
  console.log(reverse)

  // Styles
  const titleContainerStyle = {
    textAlign: reverse ? 'right' : 'left',
    cursor: 'pointer'
  }

  const imgContainerStyle = {
    order: reverse ? 2 : 1,
    cursor: 'pointer',
    backgroundImage: `url(${img})`
  }

  const infoContainerStyle = {
    order: reverse ? 1 : 2
  }

  const infoStyle = reverse ? { alignItems: 'self-end' } : {}

  const seeDetails = (id, title) => {
    navigate("/giftBox/" + id + "_" + title);
  };
  return (
    <div className='giftBox-container'>
      <div
        className='title-container'
        style={titleContainerStyle}
        onClick={() => seeDetails(id, title)}
      >
        {title || 'Pink Box'}
      </div>
      <div
        className='img-container'
        style={imgContainerStyle}
        onClick={() => seeDetails(id, title)}
      />
      <div className='info-container' style={infoContainerStyle}>
        <div className='info' style={infoStyle}>
          <p>
            <SanitizedHTML html={truncateDescription(description, 15)}/>
            <span
              style={{ color: '#874e31', cursor: 'pointer' }}
              onClick={() => seeDetails(id)}
            >
              {' '}
              [See more]
            </span>
          </p>
          <PriceTag price={price} />
          <AddToCartButton
            onClick={() => {
              addToCart(prd)
            }}
          />
        </div>
      </div>
    </div>
  )
}
