import React, { useEffect, useState } from "react";
import {
  Container,
  CardHeader,
  CardHeading,
  AntTable,
  TableWrapper,
  Row,
} from "./styles";

import TableActions from "../TableActions";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../Modal/Delete";
import { Button, Spin, message } from "antd";
import { useFetchCategories } from "../../../hooks/category/useFetchCategories";
import { useDeleteCategory } from "../../../hooks/category/useDeleteCategory";
import { useUpdateCategory } from "../../../hooks/category/useUpdateCategory";

export default function CategoriesList() {
  const navigate = useNavigate();

  const { categories: data, isLoading, refetch } = useFetchCategories();
  const {
    mutate: updateCategory,
    isLoading: updatingCategory,
    isSuccess: updateSuccess,
    error: updateError,
  } = useUpdateCategory();
  const [customLoader, setCustomLoader] = useState(false);
  const {
    mutate: deleteCategory,
    isLoading: deletingCategory,
    isSuccess: deleteSuccess,
    error: deleteError,
  } = useDeleteCategory();

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "Category",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Sub Categories",
      dataIndex: "values",
      key: "values",
      render: (values) => <>{values?.length}</>,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <TableActions
          onEdit={() => {
            navigate(record._id);
          }}
          onDelete={() => {
            setCustomLoader(true);
            deleteCategory(record._id);
          }}
        />
      ),
    },
    AntTable.EXPAND_COLUMN,
  ];

  useEffect(() => {
    if (deleteSuccess) {
      message.success("Category Deleted Successfully");
      refetch();
      setCustomLoader(false);
    }
    if (deleteError) {
      message.error("Error while Deleting Category");
      setCustomLoader(false);
    }
  }, [deleteSuccess, deleteError]);

  const onSubcategoryDelete = (cat, subC) => {
    setCustomLoader(true);
    const updateCategoryData = {
      id: cat._id,
      data: {
        name: cat.name,
        values: cat.values.filter((sc) => sc !== subC),
      },
    };
    updateCategory(updateCategoryData);
  };

  useEffect(() => {
    if (updateSuccess) {
      message.success("Subcategory Delete Success");
      refetch();
      setCustomLoader(false);
    }
    if (updateError) {
      message.error("Subcategory Delete Error");
      setCustomLoader(false);
    }
  }, [updateSuccess, updateError]);

  return (
    <>
      {(isLoading || customLoader) && <Spin fullscreen />}
      <Container>
        <CardHeader>
          <CardHeading>Categories</CardHeading>
        </CardHeader>
        <TableWrapper>
          <AntTable
            columns={columns}
            dataSource={data}
            pagination={{ pageSize: 10 }}
            expandable={{
              expandedRowRender: (record) => (
                <>
                  {record.values.length > 0 && (
                    <div
                      style={{ fontSize: "14px", color: "gray" }}
                      key={`expandedRow_${record._id}`}
                    >
                      <div style={{ marginBottom: "2rem", color: "black" }}>
                        Sub Categories:
                      </div>
                      {record.values.map((subC, index) => (
                        <Row
                          key={`${subC}_${index}`}
                          style={{
                            justifyContent: "flex-start",
                            gap: "5rem",
                            margin: "1rem 0",
                            padding: "0 1rem",
                            // backgroundColor: 'white',
                            // borderBottom:'1px solid gray',
                            // borderTop:'1px solid gray'
                          }}
                        >
                          <div style={{ width: "200px" }}>{subC}</div>

                          <div>
                            <Button
                              style={{
                                color: "#D90000",
                                border: "none",
                              }}
                            >
                              <DeleteModal
                                onSubmit={() =>
                                  onSubcategoryDelete(record, subC)
                                }
                              />
                            </Button>
                          </div>
                        </Row>
                      ))}
                    </div>
                  )}
                </>
              ),
            }}
          />
        </TableWrapper>
      </Container>
    </>
  );
}
