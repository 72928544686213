import styled from 'styled-components'
import { Radio, Button, Form } from 'antd'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (min-width: 780px) {
    display: none;
  }
`
export const Title = styled.div`
  font-size: 3rem;
  color: #d29e84;
`
export const FilterButton = styled(Button)`
  display: hidden;
  @media only screen and (min-width: 779px) {
    display: block;
    width: 20rem;
  }
`
export const FilterContainer = styled(Form)`
  display: flex;
  flex: 2;
  flex-direction: column;
  gap: 5rem;
  border-radius: 10px;
  // height: auto;
  only screen and (max-width: 1100px) {
    flex: 3;
  }
`
export const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
`
export const FilterTitle = styled.div`
  font-size: 20px;
  color: #d29e84;
`
export const FilterType = styled.div`
  font-size: 18px;
  padding: 0 2rem;
  color: white;
  background-color: #d29e84;
`
export const Filter = styled(Radio)`
  color: #444141;
  .ant-radio-checked .ant-radio-inner {
    border-color: #d29e84 !important;
    background-color: #d29e84 !important;
  }
`
