import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { Spin, message } from "antd";
import { Alert } from "antd";
import { useUploadImage } from "../../../hooks/cloudinary/useUploadImage";

const ImagesUploadToCloudinary = ({
  value = [],
  onChange,
  readonly = false,
}) => {
  const { mutate, isLoading, error, data, isSuccess } = useUploadImage();
  const [messageApi, contextHolder] = message.useMessage();
  const [customLoader, setCustomLoader] = useState(false);

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles.length > 1) {
      messageApi.open({
        type: "error",
        content:
          "Please upload only iamge file and it should be less than 2 MB",
      });
      return;
    }

    const newImages = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    //as we have allow only 1 image so it will be on index 0
    const formData = new FormData();
    formData.append("image", newImages[0]);
    mutate(formData);
    setCustomLoader(true);
  };

  const removeImage = (index, event) => {
    event.preventDefault();

    const updatedImages = [...value];
    updatedImages.splice(index, 1);
    onChange(updatedImages);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
    maxFiles: 1,
    maxSize: 2 * 1024 * 1024,
  });

  useEffect(() => {
    if (isSuccess) {
      onChange([...value, { color: "", src: data.imageUrl }]);
      setCustomLoader(false);
    }
    if (error) {
      message.error("Error Uploading Image To Cloudinary");
      setCustomLoader(false);
    }
  }, [isSuccess, error, data]);
  console.log(value);
  return (
    <Container>
      {customLoader && <Spin fullscreen />}
      {!readonly && (
        <DropzoneContainer {...getRootProps()}>
          <input {...getInputProps()} />
          <DropzoneText>
            <>
              <p>Drag & drop here, or click to select</p>
              <p>
                {" "}
                <span style={{ color: "red" }}>*</span>Only a single file is
                allowed.
              </p>
            </>
          </DropzoneText>
        </DropzoneContainer>
      )}
      <ImageContainer>
        {value.map((imageWithColor, index) => (
          <ImagePreview key={index}>
            <Image src={imageWithColor.src} alt={`Preview ${index}`} />
            {!readonly && (
              <RemoveButton onClick={(e) => removeImage(index, e)}>
                x
              </RemoveButton>
            )}
          </ImagePreview>
        ))}
      </ImageContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DropzoneContainer = styled.div`
  border: 2px dashed gray;
  // background-color: lightgray;
  color: gray;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
`;

const DropzoneText = styled.p`
  margin: 0;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ImagePreview = styled.div`
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const Image = styled.img`
  width: 75px;
  height: 75px;
  object-fit: cover;
  margin-right: 5px;
  margin-bottom: 5px;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: -5px;
  padding: 0 !important;
  right: 7px;
  color: black;
  border: none;
  cursor: pointer;
  background-color: transparent;
`;
// };

export default ImagesUploadToCloudinary;
