import styled from 'styled-components'
import { Button } from 'antd'

export const MainHeading = styled.div`
  color: var(--415161, #415161);
  // color: black;
  font-family: 'Tangerin';
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 60px */
  @media only screen and (max-width: 1300px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 30px;
  }
`
export const SecondaryHeading = styled.div`
  color: var(--415161, #415161);
  font-family: 'Tangerin';
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 60px */
  @media only screen and (max-width: 1300px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 1300px) {
    font-size: 20px;
  }
`

export const PinkText = styled.div`
  color: var(--ed-82-b-1, #d29e84);
  // font-family: Nunito;
  font-family: Euclid Circular A;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 166.667% */
  @media only screen and (max-width: 1100px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 1300px) {
    font-size: 16px;
  }
`
export const Text = styled.div`
  color: var(--415161, #415161);
  // font-family: Nunito;
  font-family: Euclid Circular A;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 177.778% */
  @media only screen and (max-width: 1100px) {
    font-size: 16px;
  }
`
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 760px) {
    padding: 2rem;
  }
  @media only screen and (min-width: 2080px) {
    margin: auto 15%;
  }
`
export const MainButton = styled(Button)`
  background-color: #d29e84;
  color: white;
  border: none;
  flex-shrink: 0;
  text-align: center;
  // font-family: Nunito;
  font-family: Euclid Circular A;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  border-radius: 50px;
  display: inline-flex;
  padding: 4.25rem 2.5rem !important;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  width: 30rem;
  @media only screen and (max-width: 1300px) {
    font-size: 16px;
  }

  &:hover {
    color: white !important;
  }
`
