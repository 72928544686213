import styled from 'styled-components'
import { Input } from 'antd'

export const AntInput = styled(Input)`
  width: 100%;
  padding: 1.25rem;
  border-radius: 10px;
  font-size: 16px;
  height: auto;
  color: black;
  background-color: #f5f5f5;
  border: none;
  @media (max-width: 480px) {
    font-size: 14px;
    padding: 0.75rem;
  }
`
export const AntPasswordInput = styled(Input.Password)`
  width: 100%;
  padding: 1.25rem;
  border-radius: 10px;
  font-size: 16px;
  height: auto;
  color: black;
  background-color: #f5f5f5;
  border: none;
  @media (max-width: 480px) {
    font-size: 14px;
    padding: 0.75rem;
  }

  input {
    background-color: #f5f5f5;
  }
`
