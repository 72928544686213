export const setBuildABoxDataToLocalStorage = (formData) => {
  localStorage.setItem("buildABoxData", JSON.stringify(formData));
};

export const getBuildABoxDataFromLocalStorage = () => {
  return localStorage.getItem("buildABoxData");
};

export const setBuildABoxCurrentStepToLocalStorage = (step) => {
  localStorage.setItem("buildABoxCurrentStepToLocalStorage", step);
};

export const getBuildABoxCurrentStepFromLocalStorage = () => {
  return parseInt(localStorage.getItem("buildABoxCurrentStepToLocalStorage"));
};

export const clearBuildABoxDataToLocalStorage = () => {
  localStorage.removeItem("buildABoxData");
};

export const clearBuildABoxCurrentStepToLocalStorage = () => {
  localStorage.removeItem("buildABoxCurrentStepToLocalStorage");
};
