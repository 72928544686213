import React from 'react'
import ProductsList from '../../../Components/ProductList'
import PageContent from '../../../Components/PageContent'
import PageHeading from '../../../Components/PageHeading'
import PrimaryButton from '../../../Components/UIKit/Buttons/Primary'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

export default function Products () {
  const navigate = useNavigate()
  return (
    <PageContent>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          position: 'absolute',
          right: '10rem',
          top: '4rem'
        }}
      >
        <PrimaryButton
          text={'Add Product'}
          icon={<PlusOutlined />}
          simple={true}
          responsive={true}
          onClick={() => navigate('/admin/newProduct')}
        />
      </div>
      <PageHeading heading={'Products Management'} />
      <ProductsList />
    </PageContent>
  )
}
