import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Heading, GrayText, PinkText } from "./styles";
import { Container, Row, Col } from "react-bootstrap";
import Q1 from "./Questions/Q1";
import Q2 from "./Questions/Q2";
import Q3 from "./Questions/Q3";
import Q4 from "./Questions/Q4";
import Q5 from "./Questions/Q5";
import { Button, Spin } from "antd";
import Q6 from "./Questions/Q6";
import Q7 from "./Questions/Q7";
import Recommended from "./Questions/Recommended";
import { useFetchProducts } from "../../hooks/Products/useFetchProducts";
import { useFetchCategories } from "../../hooks/category/useFetchCategories";

const totalSteps = 8;
export default function QuizComponent() {
  const [currentStep, setCurrentStep] = useState(1);
  const [completedStep, setCompletedStep] = useState(null);
  const [q1Value, setQ1Value] = useState(null);
  const [q2Value, setQ2Value] = useState(null);
  const [q3Value, setQ3Value] = useState(null);
  const [q4Value, setQ4Value] = useState(null);
  const [q5Value, setQ5Value] = useState([]);
  const [q6Value, setQ6Value] = useState(null);
  const [q7Value, setQ7Value] = useState(null);
  const navigate = useNavigate();
  const { categories, isLoading: categoriesLoading } = useFetchCategories();

  const CloseIcon = styled.div`
    position: absolute;
    top: 3rem;
    left: 95%;
    font-size: 20px;
    cursor: pointer;
    color: #d29e84;
    @media only screen and (max-width: 470px) {
      top: 1rem;
      left: 95%;
    }
  `;
  const StepBtn = styled.button`
    @media only screen and (max-width: 470px) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      // font-size:16px;
    }
  `;
  const AntBtn = styled(Button)`
    &:not(:disabled):hover {
      color: white !important;
      background-color: #d29e84;
      font-size: 2rem;
    }
    border: none !important;
  `;
  console.log(q1Value);
  const nextStep = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderStep = (step, products, categories) => {
    let filteredProducts = products.filter((prd) => {
      return (
        !prd.isAReadyMadeBox &&
        //q1 filter
        prd.categories
          ?.find((cat) => cat.category === stepFormCategoriesWithIds["gender"])
          ?.values.includes(q1Value) &&
        //q2 filter
        prd.categories
          ?.find((cat) => cat.category === stepFormCategoriesWithIds["ageType"])
          ?.values.includes(q2Value) &&
        //q3 filter
        prd.categories
          ?.find(
            (cat) =>
              cat.category === stepFormCategoriesWithIds["forPersonRelation"]
          )
          ?.values.includes(q3Value) &&
        //q4 filter
        prd.categories
          ?.find(
            (cat) => cat.category === stepFormCategoriesWithIds["celebration"]
          )
          ?.values.includes(q4Value) &&
        //q5 and q6 filter same (q6 applicable here)
        prd.categories
          ?.find(
            (cat) => cat.category === stepFormCategoriesWithIds["interest"]
          )
          ?.values.includes(q6Value)
      );
    });
    if (q7Value !== "Any Price") {
      filteredProducts = filteredProducts.filter((prd) => prd.price < q7Value);
    }

    switch (step) {
      case 1:
        return (
          <Q1
            setStep={setCompletedStep}
            value={q1Value}
            setValue={setQ1Value}
            categories={categories}
          />
        );
      case 2:
        return (
          <Q2
            setStep={setCompletedStep}
            value={q2Value}
            setValue={setQ2Value}
            she={q1Value === "For her" ? true : false}
            categories={categories}
          />
        );
      case 3:
        return (
          <Q3
            setStep={setCompletedStep}
            value={q3Value}
            setValue={setQ3Value}
            she={q1Value === "For her" ? true : false}
            categories={categories}
          />
        );
      case 4:
        return (
          <Q4
            setStep={setCompletedStep}
            value={q4Value}
            setValue={setQ4Value}
            products={products}
            categories={categories}
          />
        );
      case 5:
        return (
          <Q5
            setStep={setCompletedStep}
            value={q5Value}
            setValue={setQ5Value}
            products={products}
            categories={categories}
          />
        );
      case 6:
        return (
          <Q6
            setStep={setCompletedStep}
            value={q6Value}
            setValue={setQ6Value}
            step5Values={q5Value}
            products={products}
          />
        );
      case 7:
        return (
          <Q7
            setStep={setCompletedStep}
            value={q7Value}
            setValue={setQ7Value}
            she={q1Value === "For her" ? true : false}
          />
        );
      case 8:
        return <Recommended products={filteredProducts} />;
      default:
        return null;
    }
  };

  const backNextBtnPd = currentStep === totalSteps ? "0.9rem 17%" : "";

  console.log(q1Value, q2Value, q3Value, q4Value, q5Value, q6Value, q7Value);
  const { products, isLoading } = useFetchProducts();

  const stepFormCategoriesWithIds = {
    gender: categories?.find((cat) => cat.name === "gender")?._id,
    ageType: categories?.find((cat) => cat.name === "ageType")?._id,
    forPersonRelation: categories?.find(
      (cat) => cat.name === "forPersonRelation"
    )?._id,
    celebration: categories?.find((cat) => cat.name === "celebration")?._id,
    interest: categories?.find((cat) => cat.name === "interest")?._id,
  };

  return (
    <div className="app-root-container">
      {(isLoading || categoriesLoading) && <Spin fullscreen />}
      <Container className="step-form-container p-5" style={{ padding: 0 }}>
        <Row
          className="justify-content-md-center step-indicator-row"
          style={{ position: "relative" }}
        >
          <CloseIcon onClick={() => navigate("/")}>X</CloseIcon>
          <Col md={12} className="text-center py-5">
            <Heading>
              {/* <GrayText>Heartsy Box </GrayText> */}
              <PinkText>GiftPicker</PinkText>
            </Heading>
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          {["1", "2", "3", "4", "5", "6", "7", "8"].map((val, index) => (
            <Col
              key={index}
              xs={2}
              className={`step-indicator-col p-3 ${
                index + 1 === currentStep ? "step-active" : "step-inactive"
              }`}
              style={{ width: "12.5%" }}
            >
              <div className="d-flex align-items-center">
                <StepBtn
                  className={`step-btn ${
                    index + 1 === currentStep ? "step-btn-border" : ""
                  }`}
                >
                  {index + 1}
                </StepBtn>
                {/* <div
                  className={`step-text fs-3 mb-4 ${
                    index + 1 === currentStep
                      ? 'step-text-active'
                      : 'step-text-inactive'
                  }`}
                >
                  {}
                </div> */}
              </div>
            </Col>
          ))}
        </Row>
        {products && (
          <Row style={{ width: "100%" }}>
            <Col md={12}>
              {renderStep(
                currentStep,
                products?.filter((prd) => !prd?.isAReadyMadeBox),
                categories
              )}
            </Col>
          </Row>
        )}
        <div className="d-flex justify-content-center" style={{ gap: "3rem" }}>
          <AntBtn
            className="custom-btn-back fs-3"
            onClick={() => prevStep()}
            disabled={currentStep === 1}
          >
            {currentStep !== totalSteps ? (
              <>
                <span>&lt;</span> Back
              </>
            ) : (
              "Back"
            )}
          </AntBtn>
          <AntBtn
            className="custom-btn-next"
            disabled={currentStep > completedStep}
            onClick={() => nextStep()}
          >
            Next
          </AntBtn>
        </div>
      </Container>
    </div>
  );
}
