import React, { createContext, useContext, useState, useEffect } from "react";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const CART_KEY = "cart";

  const getCart = () => {
    try {
      const storedCart = localStorage.getItem(CART_KEY);
      return storedCart ? JSON.parse(storedCart) : [];
    } catch (error) {
      console.error("Error parsing cart data:", error);
      return [];
    }
  };

  const setCart = (cart) => {
    localStorage.setItem(CART_KEY, JSON.stringify(cart));
  };

  const calculateTotalPrice = (cart) => {
    return cart?.reduce((total, item) => {
      const productPrice = item.product.price || 0;
      const personalizedInputPrices = item.personalizedInputs
        ? item.personalizedInputs?.reduce(
            (sum, input) => sum + (input.price || 0),
            0
          )
        : 0;
      return total + (productPrice + personalizedInputPrices) * item.quantity;
    }, 0);
  };

  const [cart, setCartState] = useState(getCart);

  useEffect(() => {
    setCart(cart);
  }, [cart]);

  const updateCart = (newCart) => {
    setCartState(newCart);
  };

  const addToCart = (
    product,
    personalizedInputs,
    quantity = 1,
    color,
    variations
  ) => {
    const currentCart = getCart();

    const existingItemIndex = currentCart.findIndex(
      (item) =>
        item.product._id === product._id &&
        JSON.stringify(item.personalizedInputs) ===
          JSON.stringify(personalizedInputs) &&
        item.color === color &&
        JSON.stringify(item.variations) === JSON.stringify(variations)
    );

    if (existingItemIndex !== -1) {
      currentCart[existingItemIndex].quantity += quantity;
    } else {
      currentCart.push({
        product,
        personalizedInputs,
        quantity,
        color,
        variations,
      });
    }

    updateCart(currentCart);
  };

  const removeFromCart = (
    productId,
    color,
    personalizedInputs,
    completeDelete = false,
    variations
  ) => {
    const currentCart = getCart();
    const updatedCart = currentCart
      .map((item) => {
        const isMatchingProduct = item.product._id === productId;
        const isMatchingColor = color ? item.color === color : true;
        const isMatchingPersonalizedInputs = personalizedInputs
          ? JSON.stringify(item.personalizedInputs) ===
            JSON.stringify(personalizedInputs)
          : true;
        const isMatchingVariations = variations
          ? JSON.stringify(item.variations) === JSON.stringify(variations)
          : true;

        if (
          isMatchingProduct &&
          isMatchingColor &&
          isMatchingPersonalizedInputs &&
          isMatchingVariations
        ) {
          if (item.quantity > 1 && !completeDelete) {
            // If quantity is more than 1 and not doing a complete delete, decrease the quantity
            return {
              ...item,
              quantity: item.quantity - 1,
            };
          } else {
            // If quantity is 1 or doing a complete delete, remove the item
            return null;
          }
        }

        return item;
      })
      .filter(Boolean); // Filter out null entries (removed items)

    updateCart(updatedCart);
  };

  const clearCart = () => {
    localStorage.removeItem(CART_KEY);
    updateCart([]);
  };

  const totalCartPrice = calculateTotalPrice(cart);

  return (
    <CartContext.Provider
      value={{ cart, totalCartPrice, addToCart, removeFromCart, clearCart }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
