import React from 'react'
import BlogsComponent from '../../components/Blogs'
import MetaTags from '../../components/MetaTags'

export default function Blogs () {
  return (
    <>
      <MetaTags
        title={
          'HeartsyBox Blog - Find Stories, Tips, and Inspiration in Gifting'
        }
        description={
          'Discover stories, thoughtful tips, and inspiring ideas that elevate the way you celebrate moments. Immerse yourself in the joy of giving with HeartsyBox.'
        }
      />
      <BlogsComponent />
    </>
  )
}
