import styled from 'styled-components'

export const Heading = styled.div`
  font-size: 4rem;
  font-weight: 700;
`
export const GrayText = styled.span`
  color: #737373;
`
export const PinkText = styled.span`
  color: #d29e84;
  font-family: tangerin;
`

export const Question = styled.div`
  font-size: 3rem;
  color: #737373;
`

export const OptionText = styled.div`
  font-size: 2rem;
  color: #d29e84;
`
