import React from 'react'
import {
  FilterContainer,
  FilterHeader,
  FilterTitle,
  Filter,
  FilterType
} from '../styles'
import { Radio, Form, Button } from 'antd'

export default function FilterForm ({
  category,
  setCategory,
  priceRange,
  setPriceRange,
  handleApplyFilter
}) {
  const handleChange = values => {
    setCategory(values.category)
    setPriceRange(values.priceRange)
    handleApplyFilter(values)
  }

  return (
    <FilterContainer onFinish={handleChange}>
      <FilterHeader>
        <FilterTitle>Filters</FilterTitle>

        <Button
          type='primary'
          style={{ backgroundColor: '#d29e84' }}
          htmlType='submit'
        >
          Apply
        </Button>
      </FilterHeader>

      <FilterType>Categories</FilterType>
      <Form.Item name={'category'} initialValue={category}>
        <Radio.Group
          value={category}
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 2rem',
            gap: '2rem'
          }}
        >
          <Filter value='all'>All Products</Filter>
          <Filter value='For him'>For Him</Filter>
          <Filter value='For her'>For Her</Filter>
          <Filter value='For kids'>For Kids</Filter>
        </Radio.Group>
      </Form.Item>

      <FilterType>Price</FilterType>
      <Form.Item name={'priceRange'} initialValue={priceRange}>
        <Radio.Group
          value={priceRange}
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 2rem',
            gap: '2rem'
          }}
        >
          <Filter value={7}>All Prices</Filter>
          <Filter value={1}>$0 - $50</Filter>
          <Filter value={2}>$51 - $100</Filter>
          <Filter value={3}>$101 - $200</Filter>
          <Filter value={4}>$201 - $500</Filter>
          <Filter value={5}>$501 - $1000</Filter>
          <Filter value={6}>Above $1000</Filter>
        </Radio.Group>
      </Form.Item>
    </FilterContainer>
  )
}
