import styled from 'styled-components'

export const Container = styled.div`
  padding: 1rem 3rem;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
