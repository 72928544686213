import styled from 'styled-components'

export const Container = styled.div`
  padding: 1rem;
  width: 230px;
  height: 270px;
  border-radius: 10px;
  cursor: pointer;
  margin: auto;
  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }

  @media only screen and (max-width: 800px) {
    width: 180px;
    height: 200px;
  }

  @media only screen and (max-width: 500px) {
    width: 120px;
    height: 160px;
  }
`
export const Image = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  // border-radius: 10px;
  @media only screen and (max-width: 500px) {
    height: 100px;
    width: 100px;
  }
`
export const Title = styled.div`
  color: #444141;
  padding: 0 1rem;
  text-align: center;
`
export const Price = styled.div`
  color: #d29e84;
  padding: 0 1rem;
`
export const InfoContainer = styled.div`
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
