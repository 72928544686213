import React from "react";
import GiftDetailsComponent from "../../components/GiftDetails";
import { useParams } from "react-router-dom";
import { useProduct } from "../../hooks/Products/useProduct";
import { Spin } from "antd";

export default function GiftDetails() {
  const { id } = useParams();
  const { product, isLoading } = useProduct(id?.split("_")[0]);

  return (
    <>
      {isLoading && <Spin fullscreen />}
      {product && <GiftDetailsComponent product={product} />}
    </>
  );
}
