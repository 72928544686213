import React from 'react'
import './index.css'

export default function ShopByCollection ({ keyword, onClick }) {
  return (
    <button className='by-colllection-btn' onClick={onClick}>
      Shop {keyword} Collection
    </button>
  )
}
