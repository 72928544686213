import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  // justify-content: space-around;
  // background-color:red;
  @media (max-width: 1100px) {
    gap: 0.5rem;
  }
`
export const Button = styled.div`
  font-size: 20px;
  color: ${props => props.color};
  cursor: pointer;
  @media (max-width: 1100px) {
    font-size: 20px;
  }
`
