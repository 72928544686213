import React, { useEffect, useState } from 'react'
import { Container } from './styles'
import BackButton from '../UIKit/Buttons/BackButton'
import { Button, Form, Input, Spin, message } from 'antd'
import PrimaryButton from '../UIKit/Buttons/Primary'
import { PlusOutlined } from '@ant-design/icons'
import { useCreateCategory } from '../../../hooks/category/useCreateCategory'
import { useUpdateCategory } from '../../../hooks/category/useUpdateCategory'
import { useFetchCategories } from '../../../hooks/category/useFetchCategories'
import { useNavigate } from 'react-router-dom'
import { MdDelete } from 'react-icons/md'

export default function AddNewCategory ({ data }) {
  const [form] = Form.useForm()
  const [count, setCount] = useState(0)
  const navigate = useNavigate()
  const {
    mutate: createCategory,
    isLoading: creatingCategory,
    isSuccess: createSuccess,
    error: createError
  } = useCreateCategory()
  const {
    mutate: updateCategory,
    isLoading: updatingCategory,
    isSuccess: updateSuccess,
    error: updateError
  } = useUpdateCategory()
  const { refetch: refetchAllCategories } = useFetchCategories()
  const [customLoader, setCustomLoader] = useState(false)

  const handleAddSubCategory = () => {
    setCount(count + 1)
  }

  const handleSave = async () => {
    try {
      const values = await form.validateFields()
      setCustomLoader(true)

      const categoryName = values.category

      // Remove the main category from the values object
      delete values.category

      // Extract sub-categories and convert them to an array
      const subCategories = Object.values(values)

      // Prepare the data object to send to the API
      const categoryData = {
        name: categoryName,
        values: subCategories
      }
      if (data) {
        // If in edit mode, call updateCategory
        updateCategory({ id: data._id, data: categoryData })
      } else {
        // If in add mode, call createCategory
        createCategory(categoryData)
      }
    } catch (errorInfo) {
      console.log('Failed:', errorInfo)
    }
  }

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      const successMessage = data
        ? 'Category updated successfully'
        : 'Category created successfully'

      message.success(successMessage)
      refetchAllCategories()
      setCustomLoader(false)
      navigate('/admin/categories')
    }
    if (createError || updateError) {
      message.error('Error while saving category')
      setCustomLoader(false)
    }
  }, [createSuccess, updateSuccess, createError, updateError])

  useEffect(() => {
    if (data) {
      // If in edit mode, populate the form with existing data
      form.setFieldsValue({
        category: data.name,
        ...data.values.reduce((acc, val, idx) => {
          acc[`Sub-Category ${idx + 1}`] = val
          return acc
        }, {})
      })
      setCount(data.values.length)
    }
  }, [data, form])

  return (
    <Container>
      {customLoader && <Spin fullscreen />}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <BackButton />
        <PrimaryButton
          text={data ? 'Update' : 'Save'}
          simple={true}
          noPadding={true}
          onClick={handleSave}
        />
      </div>
      <Form layout='vertical' form={form}>
        <Form.Item
          label='Category Name'
          name={'category'}
          rules={[{ required: true, message: 'Please enter a category name' }]}
        >
          <Input placeholder='Category Name' />
        </Form.Item>
        {Array.from(Array(count)).map((index, counter) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <Form.Item
                key={index}
                label={'Sub-Category ' + (counter + 1)}
                name={'Sub-Category ' + (counter + 1)}
              >
                <Input placeholder={`Sub-Category ${counter + 1} Name`} />
              </Form.Item>
              {/* {count === counter + 1 && (
                <Button
                  style={{
                    border: 'none',
                    boxShadow: '0 0 0 0',
                    fontSize: '18px'
                  }}
                >
                  <MdDelete onClick={() => setCount(count - 1)} />
                </Button>
               )} */}
            </div>
          )
        })}
        <PrimaryButton
          text={'Add Sub-Category'}
          icon={<PlusOutlined />}
          simple={true}
          noPadding={true}
          onClick={handleAddSubCategory}
        />
      </Form>
    </Container>
  )
}
