import axiosInstance from "../../utils/Axios/axiosConfig";

export const createCoupon = async (couponData) => {
  const result = await axiosInstance.post("/coupons", couponData);
  return result.data;
};

export const deleteCoupon = async (couponId) => {
  const result = await axiosInstance.delete(`/coupons/${couponId}`);
  return result.data;
};

export const getCouponByCode = async (couponCode) => {
  const result = await axiosInstance.get(`/coupons/${couponCode}`);
  return result.data;
};

export const fetchCoupons = async () => {
  const result = await axiosInstance.get(`/coupons`);
  return result.data;
};
