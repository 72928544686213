import { styled } from 'styled-components'
import { Button } from 'antd'

export const Back = styled(Button)`
  border: none;
  color: gray;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  // margin-left: -3rem;
  // background-color:gray;
  // @media (max-width: 768px) {
  //   font-size: 1rem;
  // }
  @media (max-width: 48px) {
    display: none !important;
  }
`
