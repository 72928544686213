import React, { useState } from "react";
import {
  CardContainer,
  Image,
  Details,
  Name,
  Color,
  QuantitySection,
  QuantityButton,
  Price,
  RemoveButton,
  ImageContainer,
} from "./styles";
import { DeleteOutlined } from "@ant-design/icons";
import { useCart } from "../../../contexts/CartContext";

export default function ItemCard({
  image,
  name,
  color,
  quantity,
  price,
  personalizedInputs,
  productId,
  cartRawItem,
}) {
  const [itemQuantity, setItemQuantity] = useState(quantity);
  const { removeFromCart, addToCart } = useCart();

  const handleIncrement = () => {
    setItemQuantity(itemQuantity + 1);
    addToCart(
      cartRawItem.product,
      cartRawItem.personalizedInputs,
      1,
      cartRawItem.color,
      cartRawItem.variations
    );
  };

  const handleDecrement = () => {
    if (itemQuantity > 1) {
      setItemQuantity(itemQuantity - 1);
      removeFromCart(
        cartRawItem.product._id,
        cartRawItem.color,
        cartRawItem.personalizedInputs,
        false,
        cartRawItem.variations
      );
    }
  };

  const handleRemove = () => {
    removeFromCart(
      productId,
      color,
      personalizedInputs,
      true,
      cartRawItem.variations
    );
  };
  const personalizedInputsPrice = personalizedInputs
    ? personalizedInputs.reduce((sum, input) => sum + (input.price || 0), 0)
    : 0;

  return (
    <CardContainer>
      <ImageContainer>
        <Image src={image} alt={name} />
      </ImageContainer>

      <div style={{ width: "100%" }}>
        <Name>{name}</Name>
        <Details>
          <QuantitySection>
            {/* quatity: */}
            <QuantityButton onClick={handleDecrement}>-</QuantityButton>
            <>{itemQuantity}</>
            <QuantityButton onClick={handleIncrement}>+</QuantityButton>
          </QuantitySection>
          <Color>{color}</Color>
          <Price>${(price + personalizedInputsPrice).toFixed(2)}</Price>
          <RemoveButton onClick={handleRemove}>
            <DeleteOutlined />
          </RemoveButton>
        </Details>
      </div>
    </CardContainer>
  );
}
