import React from 'react'
import { AntButton } from './styles'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export default function DeleteButton ({
  text,
  icon,
  onClick,
  simple,
  id,
  route,
  toPage
}) {
  const token = localStorage.getItem('token')
  const navigate = useNavigate()

  // Delete
  const deleteHandler = () => {
    axios
      .delete(route + `/${id}`, { headers: { token: token } })
      .then(response => {
        console.log(response)
        navigate(toPage)
      })
      .catch(error => console.log(error))
  }
  return (
    <AntButton onClick={onClick || deleteHandler} simple={simple}>
      {icon}
      {text}
    </AntButton>
  )
}