import React, { useState } from 'react'
import {
  Container,
  SecButton,
  MobileContainer,
  ResponsiveMenu,
  MenuIcon,
  IconContainer,
  LogoContainer
} from './styles'
import {
  GlobalOutlined,
  SettingOutlined,
  UserOutlined,
  MenuOutlined,
  LogoutOutlined
} from '@ant-design/icons'
import { Avatar } from 'antd'
import { IoNotifications } from 'react-icons/io5'
import img from '../../Assets/logo.png'
import { useLocation, useNavigate } from 'react-router-dom'
// import SideMenu from '../SideMenu'
import DropDown from '../UIKit/Dropdown'
import { clearUserToken } from '../../../utils/LocalStorage/localstorage'
import MobileDropDown from '../UIKit/MobileDropdown'

export default function Header () {
  const [menu, setMenu] = useState(false)
  const [active, setActive] = useState(false)
  const [activeLink, setActiveLink] = useState('/admin/products')
  const navigate = useNavigate()
  const location = useLocation()

  const jumpPage = link => {
    navigate(link)
    setActiveLink(link)
  }

  return (
    <>
      <Container>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <IconContainer>
            <MenuIcon
              size={'large'}
              onClick={() => setMenu(!menu)}
              style={{ color: '#74829C', fontSize: '20px' }}
            />
            {/* <ResponsiveMenu showMenu={menu}>
              <SideMenu />
            </ResponsiveMenu> */}
          </IconContainer>
          <LogoContainer
            style={{ cursor: 'pointer' }}
            logo={img}
            onClick={() => navigate('/admin/products')}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SecButton
            onClick={() => jumpPage('/admin/products')}
            active={
              location.pathname.includes('/admin/products') ||
              activeLink === '/admin/products'
            }
          >
            Products
          </SecButton>
          <SecButton
            onClick={() => jumpPage('/admin/categories')}
            active={
              location.pathname.includes('/admin/categories') ||
              activeLink === '/admin/categories'
            }
          >
            Categories
          </SecButton>
          <SecButton
            onClick={() => jumpPage('/admin/orders')}
            active={
              location.pathname.includes('/admin/orders') ||
              activeLink === '/admin/orders'
            }
          >
            Orders
          </SecButton>
          <SecButton
            onClick={() => jumpPage('/admin/vouchers')}
            active={
              location.pathname.includes('/admin/vouchers') ||
              activeLink === '/admin/vouchers'
            }
          >
            Vouchers
          </SecButton>
          <SecButton
            onClick={() => jumpPage('/admin/blogs')}
            active={
              location.pathname.includes('/admin/blogs') ||
              activeLink === '/admin/blogs'
            }
          >
            Blogs
          </SecButton>
          <SecButton
            onClick={() => jumpPage('/admin/subscriptions')}
            active={
              location.pathname.includes('/admin/subscriptions') ||
              activeLink === '/admin/subscriptions'
            }
          >
            Subscriptions
          </SecButton>
          <SecButton>
            <DropDown header={true} placement={'bottomLeft'} />
            {/* <>Admin</> */}
          </SecButton>
          {/* <SecButton style={{ fontSize: '20px' }}>
            <IoNotifications />
          </SecButton> */}
          {/* <SecButton
            style={{ color: 'gray', fontSize: '20px' }}
            onClick={logout}
          >
            <LogoutOutlined />
          </SecButton> */}
        </div>
      </Container>
      <MobileContainer>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <IconContainer>
            {/* <MenuOutlined onClick={() => setMenu(!menu)} /> */}
            {/* <ResponsiveMenu showMenu={menu}>
              <SideMenu />
            </ResponsiveMenu> */}
          </IconContainer>
          <LogoContainer logo={img} />
        </div>
        <SecButton>
          {/* <Avatar size='middle' icon={<UserOutlined />} /> */}
          <MobileDropDown header={true} placement={'bottomRight'} />
        </SecButton>
      </MobileContainer>
    </>
  )
}
