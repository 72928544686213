import React, { useEffect, useState } from 'react'
import { Container, Button, Row, Col } from 'react-bootstrap'
import Step1 from './components/Step1'
import Step2 from './components/Step2'
import Step3 from './components/Step3'
import Step4 from './components/Step4'
import Step5 from './components/Step5'
import './designgift.css'
import Step6 from './components/Step6'
import '../../App.css'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Spin, message, Modal } from 'antd'
import { useFetchProducts } from '../../hooks/Products/useFetchProducts'
import { useCreateCheckoutSession } from '../../hooks/Stripe/useCreateCheckoutSession'
import {
  clearBuildABoxCurrentStepToLocalStorage,
  clearBuildABoxDataToLocalStorage,
  getBuildABoxCurrentStepFromLocalStorage,
  getBuildABoxDataFromLocalStorage,
  setBuildABoxCurrentStepToLocalStorage,
  setBuildABoxDataToLocalStorage
} from '../../utils/Shared/buildABoxBackup'
import { convertToBase64 } from '../../utils/LocalStorage/storingImages'

const totalSteps = 6 // Assuming you have 6 steps as per your design

const DesignGift = () => {
  let stripe
  let hasDisplayedMessage = false
  if (navigator.onLine) {
    stripe = window.Stripe(
      'pk_live_51K9Lu5KEiBYYKUXN409JZtyVkYBxpkjnx6ed5mbyrEjXaRceHNutaPruYVcQECnfrf5CutSanx24qhFA4mCPJUGY00jlp7ZP5d'
    )
  }
  const {
    mutate: createCheckoutSession,
    data: stripeResponse,
    isSuccess: stripeSuccess,
    error: stripeError,
    isLoading
  } = useCreateCheckoutSession()
  const [formData, setFormData] = useState({
    sweet: '',
    flower: '',
    gift: '',
    giftPersonalization: {},
    variations: {},
    personalMessage: '',
    paperType: '',
    envelopeType: '',
    senderInfo: {
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      emailAddress: '',
      couponCode: ''
    },
    recipientInfo: {
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      emailAddress: '',
      couponCode: ''
    },
    couponDiscountPercentage: '',
    selectedColorStep3: '',
    totalPrice: 0,
    step1Price: 0,
    step2Price: 0,
    step3Price: 0,
    step4PaperTypePrice: 0,
    step4EnvelopeTypePrice: 0
  })

  const [currentStep, setCurrentStep] = useState(1)
  const [selectedStep, setSelectedStep] = useState(0)
  const [customLoader, setIsCustomLoader] = useState(false)
  const [hasBackupData, setHasBackupData] = useState(false)
  const { products: availableProducts } = useFetchProducts()

  const navigate = useNavigate()
  console.log(formData, currentStep)

  const CloseIcon = styled.div`
    position: absolute;
    top: 3rem;
    left: 98%;
    font-size: 20px;
    cursor: pointer;
    color: #d29e84;
    @media only screen and (max-width: 470px) {
      top: 1rem;
      left: 95%;
    }
  `

  const nextStep = () => {
    if (currentStep < totalSteps) {
      setBuildABoxCurrentStepToLocalStorage(currentStep + 1)
      setCurrentStep(currentStep + 1)
    }
  }

  const prevStep = () => {
    if (currentStep > 1) {
      setBuildABoxCurrentStepToLocalStorage(currentStep - 1)
      setCurrentStep(currentStep - 1)
    }
  }

  const renderStep = (step, props, nextStep) => {
    switch (step) {
      case 1:
        return <Step1 {...props} />
      case 2:
        return <Step2 {...props} />
      case 3:
        return <Step3 {...props} nextStep={nextStep} />
      case 4:
        return <Step4 {...props} />
      case 5:
        return <Step5 {...props} />
      case 6:
        return <Step6 {...props} totalPrice={formData['totalPrice']} />
      default:
        return null
    }
  }

  const updateFormData = (step, data) => {
    // Update the LocalStorage first
    setBuildABoxDataToLocalStorage({
      ...formData, // Use formData since it reflects the current state
      [step]: data
    })

    // Then update the component state to trigger a re-render
    setFormData(prevData => ({
      ...prevData,
      [step]: data
    }))
  }

  const stepProps = {
    data: formData,
    updateData: updateFormData,
    setStep: setSelectedStep
  }

  const backNextBtnPd = currentStep === totalSteps ? '0.9rem 17%' : ''

  const handleCreateOrder = async () => {
    if (!validateSenderInfo(formData.senderInfo)) {
      // Validation failed, don't proceed with order creation
      return
    }
    setIsCustomLoader(true)
    let productsForOrder = []

    const step3Gift = availableProducts.find(p => p._id === formData['gift'])
    const pInputs = Object.keys(formData.giftPersonalization)
      .map(pKey => {
        return {
          key: pKey,
          value: pKey === 'image' ? 'img' : formData.giftPersonalization[pKey],
          price: step3Gift.personalizedKeys.find(v => v.label === pKey).price
        }
      })
      .filter(v => v.value)

    ;['sweet', 'flower', 'gift', 'envelopeType', 'paperType'].forEach(
      (_, index) => {
        if (formData[_]) {
          productsForOrder.push(
            index === 2
              ? {
                  product: step3Gift,
                  color: formData?.selectedColorStep3,
                  personalizedInputs: pInputs,
                  variations: formData?.variations,
                  quantity: 1
                }
              : {
                  product: availableProducts.find(p => p._id === formData[_]),
                  quantity: 1
                }
          )
        }
      }
    )

    const dataForOrder = {
      products: productsForOrder,
      personalMessage: formData.personalMessage,
      totalPrice: formData?.totalPrice,
      couponCode: formData?.senderInfo?.couponCode,
      senderInfo: formData.senderInfo,
      recipientInfo: formData.recipientInfo,
      images: [formData.giftPersonalization['image']]
    }
    localStorage.removeItem('dataForOrder')
    localStorage.setItem('dataForOrder', JSON.stringify(dataForOrder))

    try {
      createCheckoutSession({
        cart: productsForOrder,
        couponDiscount: formData.couponDiscountPercentage
      })
    } catch (err) {
      message.error(err.message)
      return
    }
  }

  const validateSenderInfo = senderInfo => {
    const requiredFields = [
      'firstName',
      'lastName',
      'address',
      'city',
      'state',
      'zipCode',
      'emailAddress'
    ]

    for (const field of requiredFields) {
      if (!senderInfo[field]) {
        message.error(
          `Please fill in the ${
            field === 'zipCode' ? 'ZIP code' : field
          } field.`
        )
        return false
      }
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(senderInfo.emailAddress)) {
      message.error('Please enter a valid email address.')
      return false
    }

    // Additional validation checks as needed

    return true // Validation passed
  }

  if (stripeSuccess && stripeResponse) {
    stripe.redirectToCheckout({
      sessionId: stripeResponse.id
    })
  }
  if (stripeError) {
    message.error('Couldnt made stripe session')
    setIsCustomLoader(false)
  }
  useEffect(() => {
    if (!stripe && !hasDisplayedMessage) {
      message.warning('No Internet Connection')
      hasDisplayedMessage = true
    }
  }, [stripe])

  useEffect(() => {
    const backupFormData = getBuildABoxDataFromLocalStorage()
    if (backupFormData) {
      setFormData(JSON.parse(backupFormData))
      setHasBackupData(true)
    }
    const backupCurrentStep = getBuildABoxCurrentStepFromLocalStorage()
    if (backupCurrentStep) {
      setCurrentStep(backupCurrentStep)
    }
  }, [])
  const confirm = () => {
    setFormData({})
    setHasBackupData(false)
    setCurrentStep(1)
    clearBuildABoxCurrentStepToLocalStorage()
    clearBuildABoxDataToLocalStorage()
  }
  const cancel = () => {
    setHasBackupData(false)
  }

  return (
    <div className='app-root-container'>
      {hasBackupData && (
        <Modal
          title='Confirmation'
          open={hasBackupData}
          onOk={confirm}
          onCancel={cancel}
        >
          <p>Do you want to restart from the beginning?</p>
        </Modal>
      )}
      {customLoader && <Spin fullscreen />}
      <Container className='step-form-container p-5'>
        <Row
          className='justify-content-md-center step-indicator-row'
          style={{ position: 'relative' }}
        >
          <CloseIcon onClick={() => navigate('/')}>X</CloseIcon>
          <Col md={12} className='text-center py-5'>
            {/* <img
              src='/packgift.png'
              alt='PackGift'
              className='img-fluid mb-4 w-25'
            /> */}
            <div
              style={{
                fontSize: '5rem',
                fontFamily: 'Tangerin',
                color: '#D29E84'
              }}
            >
              Build-a-Box
            </div>
          </Col>

          {[
            'Pick Sweet',
            'Pick Flower',
            'Pick Gift',
            'Envelope and Note',
            'Confirmation',
            'Info & Payment'
          ].map((val, index) => (
            <Col
              key={index}
              xs={2}
              className={`step-indicator-col p-3 ${
                index + 1 === currentStep ? 'step-active' : 'step-inactive'
              }`}
            >
              <div
                className='d-flex align-items-center'
                // onClick={() => {
                //   setBuildABoxCurrentStepToLocalStorage(index + 1);
                //   setCurrentStep(index + 1);
                // }}
              >
                <button
                  className={`step-btn ${
                    index + 1 === currentStep ? 'step-btn-border' : ''
                  }`}
                >
                  {index + 1} {/* Step number */}
                </button>
                <div
                  className={`step-text fs-3 mb-4 ${
                    index + 1 === currentStep
                      ? 'step-text-active'
                      : 'step-text-inactive'
                  }`}
                >
                  {val}
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Row>
          <Col md={12}>
            {/* This will render the current step component */}
            {renderStep(currentStep, stepProps, nextStep)}
          </Col>
        </Row>
        <div className='d-flex justify-content-center' style={{ gap: '3rem' }}>
          <button
            className='custom-btn-back fs-3'
            onClick={() => prevStep()}
            disabled={currentStep === 1}
            style={{ padding: backNextBtnPd }}
          >
            {currentStep !== totalSteps ? (
              <>
                <span className='arrow-left'>&lt;</span> Back
              </>
            ) : (
              'Back'
            )}
          </button>
          {currentStep !== totalSteps && (
            <button
              className='custom-btn-next fs-3'
              onClick={() => nextStep()}
              style={{ padding: backNextBtnPd }}
              disabled={currentStep > selectedStep}
            >
              Next <span className='arrow-right'>&gt;</span>
            </button>
          )}
          {currentStep === totalSteps && availableProducts && (
            <button
              className='custom-btn-next fs-3'
              onClick={handleCreateOrder}
              style={{ padding: backNextBtnPd }}
            >
              Pay
            </button>
          )}
        </div>
      </Container>
    </div>
  )
}

export default DesignGift
