import React, { useState } from "react";
import { Container, Header, Option, Selectable } from "../Q1/styles";
import { OptionBody } from "./styles";
import { Question, OptionText } from "../../styles";
import maletoddler from "../.././../../assets/images/quiz/male toddler.png";
import malebaby from "../.././../../assets/images/quiz/male baby.png";
import maleteen from "../.././../../assets/images/quiz/male teen.png";
import maleyAdult from "../.././../../assets/images/quiz/male young adult.png";
import maleadult from "../.././../../assets/images/quiz/male adult.png";
import malesenior from "../.././../../assets/images/quiz/male senior.png";
//Female images
import femaletoddler from "../.././../../assets/images/quiz/female toddler.png";
import femalebaby from "../.././../../assets/images/quiz/female baby.png";
import femaleteen from "../.././../../assets/images/quiz/female teen.png";
import femaleyadult from "../.././../../assets/images/quiz/female young adult.png";
import femaleadult from "../.././../../assets/images/quiz/female adult.png";
import femalesenior from "../.././../../assets/images/quiz/female senior.png";

export default function Q2({ setStep, value = null, setValue, she }) {
  const [isClicked, setIsClicked] = useState(value);

  const handleClick = (val) => {
    setIsClicked(val);
    setValue(val);
    setStep(2);
  };

  return (
    <Container>
      <Header>
        <Question>How old is {she ? "she" : "he"} ?</Question>
      </Header>
      <OptionBody>
        <Option>
          <Selectable
            style={
              isClicked === "Baby"
                ? { border: "4px solid #d29e84", borderRadius: "5px" }
                : { backgroundColor: "#d29e84" }
            }
            onClick={() => handleClick("Baby")}
          >
            <img src={she ? femalebaby : malebaby} style={{ width: "100%" }} />
          </Selectable>
          <OptionText>Baby</OptionText>
        </Option>
        <Option>
          <Selectable
            style={
              isClicked === "Toddler"
                ? { border: "4px solid #d29e84", borderRadius: "5px" }
                : { backgroundColor: "#d29e84" }
            }
            onClick={() => handleClick("Toddler")}
          >
            <img
              src={she ? femaletoddler : maletoddler}
              style={{ width: "100%" }}
            />
          </Selectable>
          <OptionText>Toddler</OptionText>
        </Option>
        <Option>
          <Selectable
            style={
              isClicked === "Teen"
                ? { border: "4px solid #d29e84", borderRadius: "5px" }
                : { backgroundColor: "#d29e84" }
            }
            onClick={() => handleClick("Teen")}
          >
            <img src={she ? femaleteen : maleteen} style={{ width: "100%" }} />
          </Selectable>
          <OptionText>Teen</OptionText>
        </Option>
        <Option>
          <Selectable
            style={
              isClicked === "Young Adult"
                ? { border: "4px solid #d29e84", borderRadius: "5px" }
                : { backgroundColor: "#d29e84" }
            }
            onClick={() => handleClick("Young Adult")}
          >
            <img
              src={she ? femaleyadult : maleyAdult}
              style={{ width: "100%" }}
            />
          </Selectable>
          <OptionText>Young Adult</OptionText>
        </Option>
        <Option>
          <Selectable
            style={
              isClicked === "Adult"
                ? { border: "4px solid #d29e84", borderRadius: "5px" }
                : { backgroundColor: "#d29e84" }
            }
            onClick={() => handleClick("Adult")}
          >
            <img
              src={she ? femaleadult : maleadult}
              style={{ width: "100%" }}
            />
          </Selectable>
          <OptionText>Adult</OptionText>
        </Option>
        <Option>
          <Selectable
            style={
              isClicked === "Senior"
                ? { border: "4px solid #d29e84", borderRadius: "5px" }
                : { backgroundColor: "#d29e84" }
            }
            onClick={() => handleClick("Senior")}
          >
            <img
              src={she ? femalesenior : malesenior}
              style={{ width: "100%" }}
            />
          </Selectable>
          <OptionText>Senior</OptionText>
        </Option>
      </OptionBody>
    </Container>
  );
}
