import React from 'react'
import {
  MainContainer,
  SideMenuContainer,
  HeaderContainer,
  LogoContainer
} from './styles'
import Header from '../../Components/Header'

export default function Layout ({ children }) {
  return (
    <MainContainer>
      <Header />
      <>{children}</>
    </MainContainer>
  )
}
