import React from "react";
import { Container, TopContainer, BottomContainer } from "./styles";
import BlogCard from "./Card";
import { useFetchBlogs } from "../../hooks/Blogs/useFetchBlogs";
import { Spin } from "antd";

export default function BlogsComponent() {
  const { blogs, isLoading } = useFetchBlogs();

  return (
    <Container>
      {isLoading && <Spin fullscreen />}
      <TopContainer>
        {blogs &&
          blogs
            .slice(-3)
            .map((b) => <BlogCard flex={2} img={b?.images[0]} data={b} />)}
      </TopContainer>
      <BottomContainer>
        <BlogCard flex={1} info={true} />
      </BottomContainer>
    </Container>
  );
}
