import React, { useState } from "react";
import { Container, Header, Option, Selectable } from "../Q1/styles";
import { Question, OptionText } from "../../styles";
import { Radio, Space } from "antd";
import { AntRadio } from "./styles";

export default function Q4({
  setStep,
  value = null,
  setValue,
  products,
  categories,
}) {
  const [isClicked, setIsClicked] = useState(value);

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setIsClicked(e.target.value);
    setValue(e.target.value);
    setStep(4);
  };
  const options = [
    ...new Set(
      products
        ?.filter((p) => !p.isAReadyMadeBox)
        .map((prd) => prd?.categoryFilters?.celebration)
    ),
  ];
  const celebrationCategory = categories.find(
    (cat) => cat.name === "celebration"
  );
  return (
    <Container>
      <Header>
        <Question>
          So many reasons to give an awesome gift! What are you celebrating?
        </Question>
      </Header>
      <div>
        <Radio.Group onChange={onChange} value={value}>
          <Space direction="vertical">
            {categories &&
              celebrationCategory &&
              celebrationCategory.values.map((v) => (
                <AntRadio value={v}>
                  <OptionText>{v}</OptionText>
                </AntRadio>
              ))}
          </Space>
        </Radio.Group>
      </div>
    </Container>
  );
}
