import { useQuery } from "@tanstack/react-query";
import { getBlog } from "../../services/Blogs/blogsApi";

export const useBlog = (blogId) => {
  const {
    data: blog,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ["blog", blogId],
    queryFn: () => (blogId ? getBlog(blogId) : null),
  });

  return {
    blog,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};
