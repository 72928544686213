import React from 'react'
import { AntModal, InnerModal } from '../ProductCustomizationModal/styles'

const CustomizationGuideModal = ({
  showModal,
  isModalOpen,
  handleCancel,
  handleOk
}) => {
  return (
    <>
      <div style={{ cursor: 'pointer', fontSize: '18px' }} onClick={showModal}>
        Guide
      </div>
      <AntModal
        centered
        title={
          <h1 style={{ color: '#d29e84', fontSize: '22px' }}>
            Personalization Guide
          </h1>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={<></>}
      >
        <InnerModal>
          <p style={{ color: 'gray', textAlign: 'justify', fontSize: '12px' }}>
            At Heartsy Box, we believe in making every gift uniquely yours.
            Personalize your chosen gifts with our range of customization
            options, adding that extra touch of warmth and thoughtfulness. You
            can personalize gifts by name, birth month, year, initials, image,
            and more. Personalization options will depend on the gift selected.
            Please, follow this guide to ensure you receive the appropriate
            gift.
          </p>
          <h2 style={{ fontSize: '14px', marginTop: '10px' }}>
            Name <span style={{ fontSize: '14px' }}>(First and/or Last).</span>
          </h2>
          <p style={{ color: 'gray', textAlign: 'justify', fontSize: '12px' }}>
            Double-check that names are accurately spelled when submitting an
            order. If you need to make a name change, kindly email
            orders@heartsybox.com within the first hour of placing your order.
          </p>
          <h2 style={{ fontSize: '14px', marginTop: '10px' }}>Initials.</h2>
          <p style={{ color: 'gray', textAlign: 'justify', fontSize: '12px' }}>
            Initial personalization includes the First and Last initials only.
          </p>

          <h2 style={{ fontSize: '14px', marginTop: '10px' }}>Birth month.</h2>
          <p style={{ color: 'gray', textAlign: 'justify', fontSize: '12px' }}>
            Birth month personalization determines the design used for your
            gift. Embrace the uniqueness tied to each month.
          </p>

          <h2 style={{ fontSize: '14px', marginTop: '10px' }}>Image.</h2>
          <p style={{ color: 'gray', textAlign: 'justify', fontSize: '12px' }}>
            For gifts with image personalization, ensure your images meet our
            minimum size requirements. If an uploaded image doesn't meet
            specifications, you'll be notified promptly.
          </p>

          <h2 style={{ fontSize: '14px', marginTop: '10px' }}>Date.</h2>
          <p style={{ color: 'gray', textAlign: 'justify', fontSize: '12px' }}>
            Celebrate special occasions by adding significant dates to your
            gifts. Ensure accuracy and include any date-specific details in your
            order.
          </p>

          <h2 style={{ fontSize: '14px', marginTop: '10px' }}>
            Color Selection.
          </h2>
          <p style={{ color: 'gray', textAlign: 'justify', fontSize: '12px' }}>
            Some gifts may offer personalization through color choices. Specify
            preferred colors to match the recipient's taste or the occasion.
          </p>

          <h2 style={{ fontSize: '14px', marginTop: '10px' }}>
            Quote or Message.
          </h2>
          <p style={{ color: 'gray', textAlign: 'justify', fontSize: '12px' }}>
            Infuse your gifts with sentiments by including a personalized quote
            or message. Share your feelings or commemorate special occasions
            with words.
          </p>

          <h2 style={{ fontSize: '14px', marginTop: '10px' }}>Font Style.</h2>
          <p style={{ color: 'gray', textAlign: 'justify', fontSize: '12px' }}>
            Choose from a selection of font styles for text-based
            personalizations. This adds a layer of customization to the visual
            presentation.
          </p>

          <h2 style={{ fontSize: '14px', marginTop: '10px' }}>
            Location Coordinates.
          </h2>
          <p style={{ color: 'gray', textAlign: 'justify', fontSize: '12px' }}>
            Capture the essence of a specific location by incorporating
            coordinates. Ideal for commemorating places with sentimental value.
          </p>

          <h2 style={{ fontSize: '14px', marginTop: '10px' }}>
            Custom Artwork.
          </h2>
          <p style={{ color: 'gray', textAlign: 'justify', fontSize: '12px' }}>
            Submit custom artwork or designs for personalization. This is
            perfect for one-of-a-kind, artistic gifts.
          </p>
          <p style={{ textAlign: 'justify', fontSize: '12px' }}>
            For any queries or assistance, feel free to contact our dedicated
            team at{' '}
            <a
              href='mailto:support@heartsybox.com'
              style={{ textDecoration: 'none' }}
            >
              support@heartsybox.com
            </a>
            .
          </p>
          <p
            style={{
              textAlign: 'center',
              fontSize: '13px',
              color: 'rgb(210, 158, 132)',
              paddingTop: '1rem'
            }}
          >
            Thank you for choosing Heartsy Box, where every gift tells a
            personalized story!
          </p>
        </InnerModal>
      </AntModal>
    </>
  )
}
export default CustomizationGuideModal
