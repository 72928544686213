// styles.jsx
import styled from 'styled-components'

export const BlogDetailsContainer = styled.div`
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
`

export const BlogTitle = styled.h2`
  font-size: 32px;
  text-align: center;
  margin: 5rem 0;
  color: #333;
`

export const BlogDescription = styled.p`
  font-size: 14px;
  color: #888;
`

export const BlogImage = styled.img`
  width: 100%;
  height: 500px;
  margin-top: 10px;
  object-fit: cover;
`

export const BlogBody = styled.div`
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
`
