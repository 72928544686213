import React from 'react'
import GiftPickerPageComponent from '../../components/GiftPicker/index'
import MetaTags from '../../components/MetaTags'

export default function MainGiftPicker () {
  return (
    <>
      <MetaTags
        title={'GiftPicker - Your Personal Gift Assistant'}
        description={
          "Let HeartsyBox's GiftPicker be your guide in finding the perfect gift. Tailored recommendations for every occasion, ensuring your gesture is as unique as your sentiment."
        }
      />
      <GiftPickerPageComponent />
    </>
  )
}
