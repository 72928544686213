import React from 'react'
import OrderDetailsComponent from '../../../Components/OrderDetails'
import PageContent from '../../../Components/PageContent'
import PageHeading from '../../../Components/PageHeading'

export default function OrderDetails () {
  return (
    <PageContent>
      <PageHeading heading={'Details'} />
      <OrderDetailsComponent />
    </PageContent>
  )
}
