import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import "./step1.css";
import { useFetchProducts } from "../../../hooks/Products/useFetchProducts";
import { Spin } from "antd";
import PriceTag from "../../../components/UiKit/PriceTag";
import styled from "styled-components";

const Step2 = ({ data, updateData, setStep }) => {
   const Image = styled.div`
    object-fit: cover;
    overflow: hidden;
    width:25rem;
    height:25rem;
    transition: width 0.3s ease;
    &:hover{
    width:27rem;
    }
    @media only screen and (max-width: 600px) {
     width: 20rem;
     height:20rem;
    }
  `
  const AntBCard = styled.div`
    cursor:pointer;
    transform: none;
    max-width: 30rem;
    overflow: hidden;
  `

  const [clickedImage, setClickedImage] = useState(null);
  const { products: allProducts, isLoading } = useFetchProducts();
  const products = allProducts?.filter((prd) => !prd?.isAReadyMadeBox) || [];

  const handleImageClick = (prd) => {
    setStep(2)
    setClickedImage(prd._id);
    handleFlowerSelection(prd);
  };
  const handleFlowerSelection = (selectedFlower) => {
    updateData("step2Price", selectedFlower.price);
    updateData("flower", selectedFlower._id);
  };
  useEffect(() => {
    if (data && data.flower) {
      setClickedImage(data?.flower);
    }
  }, []);
  return (
    <>
      {isLoading && <Spin fullscreen />}
      <div
        className="d-flex flex-sm-column flex-md-row justify-content-center"
        style={{ gap: "2rem", paddingTop: "10rem", paddingBottom: "12rem" }}
      >
        {allProducts &&
          products
            .filter((prd) => prd.scopes.includes("buildABox2"))
            .slice(-2)
            .map((prd) => (
              <AntBCard
                className="border-0"
              >
                <Image>
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    // variant='top'
                    src={prd.imagesWithColors[0]?.src}
                    className={`rounded-4 ${
                      clickedImage === prd._id ? "border-11" : ""
                    }`}
                    onClick={() => handleImageClick(prd)}
                  />
                </Image>
                <Card.Body style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                  <Card.Title className="fw-bold" style={{ fontSize: "16px" }}>
                    {prd.title}
                  </Card.Title>
                  <div style={{ margin: "auto" }}>
                    <PriceTag price={prd.price} />
                  </div>
                </Card.Body>
              </AntBCard>
            ))}
      </div>
    </>
  );
};

export default Step2;
