import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  // gap: 25rem;
  padding: 10rem 22rem;
  @media only screen and (max-width: 1100px) {
    padding: 20rem 15rem 0 15rem;
    gap: 15rem;
  }
  @media only screen and (max-width: 1000px) {
    gap: 5rem;
  }
  @media only screen and (max-width: 800px) {
    padding: 15rem 2rem 0 2rem;
  }
  @media only screen and (max-width: 760px) {
    flex-direction: column;
    gap: 15rem;
  }
`
export const LeftContainer = styled.div`
  padding-left: 10rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media only screen and (max-width: 1000px) {
    padding-left: 0;
  }
  @media only screen and (max-width: 760px) {
    text-align: center;
    align-items: center;
    br {
      display: none; /* Hides <br/> on screens smaller than 600px */
    }
  }
`
export const RightContainer = styled.div`
  width: 453.26px;
  height: 457.134px;
  flex-shrink: 0;
  border-radius: 1.25rem;
  background: #d29e84;
  position: relative;
  @media only screen and (max-width: 900px) {
    width: 353.26px;
    height: 357.134px;
  }
  @media only screen and (max-width: 760px) {
    width: 253.26px;
    height: 257.134px;
  }
`
export const Image = styled.img`
  width: 374px;
  height: 527px;
  flex-shrink: 0;
  position: absolute;
  top: -15.5%;
  left: 10%;
  @media only screen and (max-width: 900px) {
    width: 274px;
    height: 427px;
  }
  @media only screen and (max-width: 760px) {
    width: 220px;
    height: 327px;
    top: -28%;
    left: 10%;
  }
`
