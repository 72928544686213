import React from 'react'
import { PrimaryBtn, BtnText } from './styles'

export default function PrimaryButton ({
  icon,
  text,
  onClick,
  simple,
  responsive,
  noPadding
}) {
  return (
    <PrimaryBtn
      type='primary'
      noPadding={noPadding}
      onClick={onClick}
      simple={simple}
      style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}
    >
      {icon}
      <BtnText responsive={responsive}>{text}</BtnText>
    </PrimaryBtn>
  )
}
