import React from 'react'
import { Container, LeftContainer, RightContainer, Image } from './styles'
import { MainButton, SecondaryHeading, Text } from '../styles'
import img from '../../../assets/images/giftPicker/img5.png'
import { useNavigate } from 'react-router-dom'

export default function Section4 () {
  const navigate = useNavigate()
  return (
    <Container>
      <LeftContainer>
        <SecondaryHeading>About Heartsy Box GiftPicker</SecondaryHeading>
        <Text>
          Heartsy Box GiftPicker is a quick quiz that helps you find
          personalized gift
          <br /> recommendations in just 20 seconds.
        </Text>
        <Text>
          Whether you’re shopping for your work BFF or looking for the right
          gift for
          <br /> someone who has everything or hates everything, our gift
          <br />
          recommendation covers everyone on your list.
        </Text>
        <MainButton onClick={() => navigate('/quiz')}>
          Take our gift quiz
        </MainButton>
      </LeftContainer>
      <RightContainer>
        <Image src={img} />
      </RightContainer>
    </Container>
  )
}
