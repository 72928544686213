import React from "react";
import PageContent from "../../../Components/PageContent";
import PageHeading from "../../../Components/PageHeading";
import AddBlog from "../../../Components/AddNewBlog";
import { Spin } from "antd";
import { useBlog } from "../../../../hooks/Blogs/useBlog";
import { useParams } from "react-router-dom";

export default function EditBlog() {
  const { id } = useParams();
  const { blog, isLoading, refetch } = useBlog(id);

  return (
    <PageContent>
      {isLoading && <Spin fullscreen />}
      {blog && (
        <>
          <PageHeading heading={`Blog # ${blog._id}`} />
          <AddBlog data={blog} refetch={refetch} />
        </>
      )}
    </PageContent>
  );
}
