import React from 'react'
import CartComponent from '../../components/Cart'
import MetaTags from '../../components/MetaTags'

export default function Cart () {
  return (
    <>
      <MetaTags
        title={'HeartsyBox Cart - Your Gateway to Gifting Bliss'}
        description={
          ' Your HeartsyBox Cart is filled with curated treasures, ready to transform into cherished moments. Review and finalize your selections, and let the journey to gifting bliss unfold. Your perfect gift is just a heartbeat away at HeartsyBox.'
        }
      />
      <CartComponent />
    </>
  )
}
