import React, { useRef, useState } from "react";
import Slider from "react-slick";
import {
  Container,
  Title,
  SubTitle,
  ColorsContainer,
  ColorOption,
  PersonalizeHeader,
  PersonalizeContainer,
  PersonalizeBody,
  ArrowIcon,
  PersonalizeInput,
  Description,
  ColorOptContainer,
} from "./styles";
import PriceTag from "../../UiKit/PriceTag";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import AddToCartButton from "../../UiKit/Buttons/AddToCart";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useCart } from "../../../contexts/CartContext";
import CustomizationGuideModal from "../../Modal/CustomizationGuide";
import ImagesUploadBox from "../../../AdminPanel/Components/ImagesUpload";
import { convertToBase64 } from "../../../utils/LocalStorage/storingImages";
import SanitizedHTML from "../../../AdminPanel/Components/SanitizeHtml";
import { Select, message } from "antd";

export default function Info({ product, selectedColor, setSelectedColor }) {
  const [personalizedInputs, setPersonalizedInputs] = useState({});
  const [personalizedImage, setPersonalizedImage] = useState();
  const [personalizedCalPrice, setPersonalizedCalPrice] = useState(0);
  const slider = useRef(null);
  const { addToCart } = useCart();
  const { Option } = Select;

  //Modal Functions
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVarations, setSelectedVariations] = useState({});
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(product?.imagesWithColors?.length, 4),
    slidesToScroll: 4,
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };
  const handleVariationSelect = (variation, value) => {
    setSelectedVariations((prevState) => ({
      ...prevState,
      [variation]: value,
    }));
  };

  const handlePersonalizedChange = (index, value) => {
    setPersonalizedInputs((prevInputs) => {
      const updatedInput = {
        ...prevInputs,
        [index]: value,
      };
      setPersonalizedCalPrice(
        product.personalizedKeys
          .filter((pk) =>
            Object.entries(updatedInput).some(
              (i) =>
                i[0] === pk.label &&
                (i[1] instanceof File ? true : i[1].length > 0)
            )
          )
          .map((p) => parseInt(p.price))
          .reduce((acc, curr) => acc + curr, 0)
      );

      return updatedInput;
    });
  };
  console.log("personalizedLogs", personalizedInputs);
  const validateAllVariationSelected = () => {
    const productVariationsNames = product.variations.map((v) => v.variation);
    const selectedVariationNames = Object.keys(selectedVarations);
    if (
      JSON.stringify(productVariationsNames.sort()) ===
      JSON.stringify(selectedVariationNames.sort())
    ) {
      return true;
    }
    return false;
  };

  const handleAddToCart = async () => {
    //all variations selected validation
    if (!validateAllVariationSelected()) {
      message.error("Please Select All Variations Before Adding into Cart");
      return;
    }

    const prd = product;
    const pInputs = await Promise.all(
      Object.keys(personalizedInputs).map(async (pKey) => {
        let value = personalizedInputs[pKey];
        let price = product?.personalizedKeys.find(
          (v) => v.label === pKey
        ).price;

        // Check if the value is a File (image)
        if (value instanceof File) {
          // Convert the image to Base64
          value = await convertToBase64(value);
          // If needed, you can also store the Base64 image in localStorage here

          // Update the price accordingly (if necessary)
          // price = // calculate or set the price for the image
        }

        return { key: pKey, value, price };
      })
    );

    const quantity = 1;
    const color = selectedColor;
    addToCart(prd, pInputs, quantity, color, selectedVarations);
  };

  console.log(product);

  return (
    <Container>
      {product && (
        <>
          <Title>{product.title}</Title>
          <Description>
            <SanitizedHTML html={product.description} />
          </Description>
          <PriceTag price={product.price + personalizedCalPrice || 0} />

          {!product?.shouldIgnoreColors && (
            <>
              {product?.imagesWithColors?.length > 0 && (
                <SubTitle>Colors</SubTitle>
              )}
              <ColorsContainer>
                {product?.imagesWithColors?.length > 4 && (
                  <ArrowIcon onClick={() => slider.current.slickPrev()}>
                    <LeftOutlined />
                  </ArrowIcon>
                )}
                <div style={{ width: "100%" }}>
                  <Slider {...settings} ref={slider}>
                    {product?.imagesWithColors?.map((clr, index) => (
                      <ColorOptContainer key={clr?.src}>
                        <ColorOption
                          color={clr?.color}
                          onClick={() => handleColorSelect(clr?.color)}
                          style={{
                            boxShadow:
                              selectedColor === clr.color
                                ? "0 0 5px 5px rgba(0,0,0,0.5)"
                                : "none",
                          }}
                        />
                      </ColorOptContainer>
                    ))}
                  </Slider>
                </div>
                {product?.imagesWithColors.length > 4 && (
                  <ArrowIcon onClick={() => slider.current.slickNext()}>
                    <RightOutlined />
                  </ArrowIcon>
                )}
              </ColorsContainer>
            </>
          )}
          {product.variations.length > 0 && (
            <>
              <SubTitle>Variations</SubTitle>
              {product.variations.map((option) => (
                <div key={option.variation}>
                  <Description>Select {option.variation}:</Description>
                  <Select
                    placeholder="Select an option"
                    style={{ width: "100%" }}
                    onChange={(value) =>
                      handleVariationSelect(option.variation, value)
                    }
                    value={selectedVarations[option.variation]}
                  >
                    {option.subVariations.map((value) => (
                      <Option key={value} value={value}>
                        {value}
                      </Option>
                    ))}
                  </Select>
                </div>
              ))}
            </>
          )}
          {product?.personalizedKeys?.length > 0 && (
            <>
              <PersonalizeHeader>
                <SubTitle>Personalization</SubTitle>
                <div style={{ color: "#d29e84", fontSize: "2rem" }}>
                  <i>
                    <CustomizationGuideModal
                      showModal={showModal}
                      isModalOpen={isModalOpen}
                      handleCancel={handleCancel}
                      handleOk={handleOk}
                    />
                  </i>
                </div>
              </PersonalizeHeader>

              <PersonalizeContainer>
                <PersonalizeHeader>
                  <div></div>
                  <PriceTag price={personalizedCalPrice} small={true} />
                </PersonalizeHeader>
                <PersonalizeBody>
                  {product.personalizedKeys.map((v, index) =>
                    v?.label === "image" ? (
                      <ImagesUploadBox
                        value={personalizedImage}
                        onChange={(e) => {
                          setPersonalizedImage(
                            e.length ? [e[e?.length - 1]] : []
                          );
                          handlePersonalizedChange(
                            v?.label,
                            e.length ? e[e?.length - 1] : ""
                          );
                        }}
                      />
                    ) : v?.label === "message" ? (
                      <PersonalizeInput
                        paragraph={true}
                        key={index}
                        placeholder={v.label}
                        maxLength={v.count}
                        onChange={(e) =>
                          handlePersonalizedChange(v?.label, e.target.value)
                        }
                      />
                    ) : (
                      <PersonalizeInput
                        key={index}
                        placeholder={v.label}
                        maxLength={v.count}
                        onChange={(e) =>
                          handlePersonalizedChange(v?.label, e.target.value)
                        }
                      />
                    )
                  )}
                </PersonalizeBody>
              </PersonalizeContainer>
            </>
          )}
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <AddToCartButton det={true} onClick={handleAddToCart} />
          </div>
        </>
      )}
    </Container>
  );
}
