import React, { useEffect, useState } from "react";
import LoginComponent from "../../Components/Login";
import PreLoginFormContainer from "../../Components/PreLoginFormContainer";
import img from "../../Assets/login2.png";
import { Form, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../../../hooks/auth/useLogin";
import { useGlobalToast } from "../../../providers/ToastProvider";
import {
  getUserToken,
  setUserToken,
} from "../../../utils/LocalStorage/localstorage";

export default function Login() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { showError, showSuccess } = useGlobalToast();
  const { mutate, isLoading, data, error, isSuccess } = useLogin();
  const onSubmit = (values) => {
    mutate(values);
  };

  useEffect(() => {
    error && showError(error);
    isSuccess && showSuccess("Login Successful");
    if (isSuccess) {
      setUserToken(data.token);
      navigate("/admin/products");
    }
  }, [error, isSuccess, data]);

  return (
    <>
      {isLoading && <Spin fullscreen />}
      <PreLoginFormContainer
        Content={<LoginComponent onSubmit={onSubmit} form={form} />}
        title={"SIGN IN"}
        subtext={"Please Enter Your Login Credentials"}
        img={img}
        backBtn={true}
      />
    </>
  );
}
