import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";
import HomePage from "./pages/HomePage/HomePage"; // Import your new homepage component
import "./App.css";
import DesignGift from "./pages/DesignGift/DesignGift";
import DefaultRoutes from "./utils/Routes/defaultRoutes";
import ReadyMadeBoxes from "./pages/ReadyMadeBoxes/readyMadeBoxes";
import Gifts from "./pages/Gifts/gifts";
import GiftCategory from "./pages/GiftCategory/giftCategory";
import GiftDetails from "./pages/GiftDetails";
import GiftBoxDetails from "./pages/GiftBoxDetails";
import Blogs from "./pages/Blogs";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Quiz from "./pages/Quiz";
import MainGiftPicker from "./pages/GiftPickerPage";
import Login from "./AdminPanel/Pages/Login";
import AdminRoutes from "./utils/Routes/adminRoutes";
import Products from "./AdminPanel/Pages/Products/List";
import AddProduct from "./AdminPanel/Pages/Products/AddProduct";
import EditProduct from "./AdminPanel/Pages/Products/EditProduct";
import OrderManagement from "./AdminPanel/Pages/Orders";
import OrderDetails from "./AdminPanel/Pages/Orders/Details";
import VoucherManagement from "./AdminPanel/Pages/Vouchers";
import BlogsManagement from "./AdminPanel/Pages/Blogs/List";
import AddNewBlog from "./AdminPanel/Pages/Blogs/AddNew";
import BlogDetails from "./AdminPanel/Pages/Blogs/Details";
import EditBlog from "./AdminPanel/Pages/Blogs/EditBlog";
import OrderConfirmedPage from "./pages/OrderCOnfirmed";
import UserBlogDetails from "./pages/BlogDetails";
import ImagesUploadToCloudinary from "./AdminPanel/Components/ImageUploadWithColor/imageUploadWithColor";
import Categories from "./AdminPanel/Pages/Categories/List";
import NewCategory from "./AdminPanel/Pages/Categories/AddNew";
import EditCategory from "./AdminPanel/Pages/Categories/Edit";
import Subscriptions from "./AdminPanel/Pages/Subscriptions/List";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/design" exact element={<DesignGift />} />
        <Route path="/picker" exact element={<MainGiftPicker />} />
        <Route path="/quiz" element={<Quiz />} />
        <Route element={<DefaultRoutes />}>
          <Route path="/" exact element={<HomePage />} />
          <Route path="/ready-made" element={<ReadyMadeBoxes />} />
          <Route path="/gifts" element={<Gifts />} />
          <Route path="/gifts/:category" element={<GiftCategory />} />
          <Route path="/gift/:id" element={<GiftDetails />} />
          <Route path="/giftBox/:id" element={<GiftBoxDetails />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:id" element={<UserBlogDetails />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/orderConfirmed" element={<OrderConfirmedPage />} />
        </Route>
        <Route path="/admin/login" exact element={<Login />} />
        <Route element={<AdminRoutes />}>
          <Route path="/admin/products" element={<Products />} />
          <Route path="/admin/newProduct" element={<AddProduct />} />
          <Route path="/admin/products/:id" element={<EditProduct />} />
          <Route path="/admin/orders/" element={<OrderManagement />} />
          <Route path="/admin/orders/:id" element={<OrderDetails />} />
          <Route path="/admin/vouchers" element={<VoucherManagement />} />
          <Route path="/admin/blogs" element={<BlogsManagement />} />
          <Route path="/admin/newBlog" element={<AddNewBlog />} />
          <Route path="/admin/blogs/:id" element={<BlogDetails />} />
          <Route path="/admin/blogs/:id/edit" element={<EditBlog />} />
          <Route path="/admin/categories" element={<Categories />} />
          <Route path="/admin/categories/new" element={<NewCategory />} />
          <Route path="/admin/categories/:id" element={<EditCategory />} />
          <Route path="/admin/subscriptions" element={<Subscriptions />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
