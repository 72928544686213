import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Slider from "react-slick";
import "./step3slidercomponent.css";

const Step3SliderComponent = ({
  categoryName,
  products,
  handleTypeSelection,
  data,
}) => {
  const [clickedImage, setClickedImage] = useState(null);

  const handleImageClick = (image) => {
    setClickedImage(image._id);
    handleTypeSelection(image);
  };

  const settings = {
    dots: false,
    nextArrow: <img src="/nextArrow.png" alt="nextArrow" />,
    prevArrow: <img src="/prevArrow.png" alt="prevArrow" />,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1324,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 908,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    if (data?.paperType && categoryName == "Paper Type") {
      setClickedImage(data.paperType);
    }
    if (data?.envelopeType && categoryName == "Envelope Type") {
      setClickedImage(data.envelopeType);
    }
  }, []);
  console.log(data);

  return (
    <>
      <div style={{ margin: "2rem" }}>
        <h1 className="fw-bold pt-5 pb-3"> {categoryName} </h1>
        <Slider
          {...settings}
          style={{
            paddingTop: "2rem",
          }}
        >
          {products.map((slide) => (
            <div key={slide}>
              <div style={{ margin: "0 1rem" }}>
                <img
                  src={slide.imagesWithColors[0]?.src}
                  // style={{
                  //   width: '200px',
                  //   height: '200px',
                  //   objectFit: 'cover'
                  // }}
                  className={`image-in-slider card-img-top rounded-4 img-fluid ${
                    clickedImage === slide._id ? "border-11" : ""
                  }`}
                  alt="Chocolate Step 3"
                  onClick={() => handleImageClick(slide)}
                />
                <div style={{ fontSize: "16px", color: "#d29e84" }}>
                  ${slide?.price}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default Step3SliderComponent;
