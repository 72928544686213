import React from 'react'
import { AntdDropdown, MenuButton } from './styles'
import {
  ExportOutlined,
  DeleteOutlined,
  CheckCircleFilled,
  CloseCircleFilled
} from '@ant-design/icons'
import {
  GlobalOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons'
import { CiDark } from 'react-icons/ci'
import { Avatar } from 'antd'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { DownOutlined } from '@ant-design/icons'

export default function DropDown ({
  header,
  id,
  state,
  onVerify,
  onDelete,
  currentPage,
  email,
  items,
  active
}) {
  return (
    <AntdDropdown menu={{ items }} placement='bottomRight'>
      <MenuButton header={header}>
        <div
          style={{ display: 'flex', alignItems: 'center', padding: 0 }}
          className='nav-link px-3'
        >
          <b>
            <DownOutlined style={{ fontSize: '8px', fontWeight: 700 }} />
          </b>
        </div>
      </MenuButton>
    </AntdDropdown>
  )
}
