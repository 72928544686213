import styled from 'styled-components'
import { Carousel, Image } from 'antd'

export const Container = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem;

  @media only screen and (max-width: 670px) {
    width: 100%;
  }
`
export const ImageContainer = styled.div`
  width: 350px;
  height: 350px;
  object-fit: cover;
  overflow:hidden;
  @media only screen and (max-width: 670px) {
    width: 300px;
    height: 300px;
  }
`
export const ImageSliderContainer = styled.div`
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  // display: grid;
  // grid-template-columns: repeat(4, 1fr);
  // gap: 1rem;
  @media only screen and (max-width: 670px) {
    width: 250px;
    gap:1rem;
  }
`
export const SliderImageContainer = styled.div`
  width: 70px !important;
  height: 60px !important;
  flex-shrink: 0;
  border-radius: 5px;
  border: none;
  overflow: hidden;
  padding: 1rem;
`

export const SliderImage = styled(Image)`
  width: 100%;
  cursor: pointer;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  transition: border-color 0.3s ease-in-out;
`

export const ArrowIcon = styled.div`
  font-size: 7rem;
  cursor: pointer;
  // font-weight:700;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d29e84;
  @media only screen and (max-width: 460px) {
   font-size: 28px;
  }
`

  export const AntCarousel = styled(Carousel)`
    .slick-slide {
      // width: 90px !important;
    }
    .slick-list {
      height: 60px !important;
    }
  `;