import React from 'react'
import GiftsCollection from '../../components/GiftsCollection'
import { useParams } from 'react-router-dom'
import { useFetchProducts } from '../../hooks/Products/useFetchProducts'
import { Spin } from 'antd'
import { Text, TextContainer, PhoneText, Heading } from '../Gifts/gifts'
import SanitizedHTML from '../../AdminPanel/Components/SanitizeHtml'
import desktopBanner from '../../assets/images/giftBox/desktop.jpeg'
import phoneBanner from '../../assets/images/giftBox/phone.jpeg'
import MetaTags from '../../components/MetaTags'
import { useLocation } from 'react-router-dom'

export default function GiftCategory () {
  const { category } = useParams()
  const location = useLocation()
  const { isLoading, products: allProducts } = useFetchProducts()
  const products = allProducts?.filter(prd => !prd?.isAReadyMadeBox) || []

  const subText =
    'Browse our curated selection of extraordinary<br/>gifts, meticulously chosen to make every<br/>ocassion special'
  const subTextPhone =
    'Browse our curated<br/>selection of extraordinary<br/>gifts, meticulously chosen<br/>to make every ocassion<br/>special'

  return (
    <>
      <MetaTags
        title={
          location.pathname.includes('Him')
            ? ' Gifts for Him - Tailored Treasures for Every Man'
            : location.pathname.includes('Her')
            ? ' Gifts for Her - Radiant Surprises for Every Woman'
            : 'Gifts for Kids - Whimsical Wonders for Young Hearts'
        }
        description={
          location.pathname.includes('Him')
            ? 'Shop for Him at HeartsyBox for personalized treasures tailored for every man. From sleek accessories to meaningful keepsakes, find the perfect gift that speaks to his uniqueness.'
            : location.pathname.includes('Her')
            ? 'Shop for Her at HeartsyBox for curated gifts that radiate elegance. From personalized jewelry to timeless wonders, find the perfect expression of love for every woman.'
            : ' Shop for Kids at HeartsyBox for playful and enchanting gifts. From whimsical toys to delightful surprises, celebrate the joy of childhood with our curated collection for the little ones.'
        }
      />
      {isLoading && <Spin fullscreen />}
      <div className='gifts-top-image'>
        <TextContainer>
          <Heading>
            <SanitizedHTML
              fontFam={'tangerin'}
              html={'Unwrap<br />The Perfect<br />Gift At<br />Heartsy Box'}
            />
          </Heading>
          <Text>
            <SanitizedHTML
              html={subText}
              fontFam={'gilroy-Light, sans-serif'}
            />
          </Text>
          <PhoneText>
            <SanitizedHTML
              html={subTextPhone}
              fontFam={'gilroy-Light, sans-serif'}
            />
          </PhoneText>
        </TextContainer>
        <img className='desktopBanner' src={desktopBanner} />
        <img className='phoneBanner' src={phoneBanner} />
      </div>
      {allProducts && (
        <GiftsCollection
          title={category}
          btnTitle={category}
          category={true}
          products={products.filter(
            prd =>
              !prd?.isAReadyMadeBox &&
              prd?.categoryFilters?.type?.toLowerCase() ===
                category.toLowerCase()
          )}
        />
      )}
    </>
  )
}
