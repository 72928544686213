import React, { useState } from "react";
import { Container, Header } from "../Q1/styles";
import { Question, OptionText } from "../../styles";
import { Checkbox } from "antd";
import styled from "styled-components";

export default function Q5({
  setStep,
  value = [],
  setValue,
  products,
  categories,
}) {
  const [checkedList, setCheckedList] = useState(value);

  const AntCheckGroup = styled(Checkbox.Group)`
    .ant-checkbox-group-item {
      display: block;
      margin-bottom: 1rem;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      border-color: #874e31 !important;
      background-color: #874e31 !important;
    }
  `;

  const interestCategory = categories.find((cat) => cat.name === "interest");

  const onChange = (checkedValues) => {
    setCheckedList(checkedValues);
    setValue(checkedValues);
    setStep(5);
  };

  return (
    <Container>
      <Header>
        <Question>What are they into?</Question>
        <OptionText style={{ color: "lightgray" }}>
          Select as many options as you can
        </OptionText>
      </Header>
      <AntCheckGroup
        value={checkedList}
        onChange={onChange}
        style={{ flexDirection: "column" }}
      >
        {categories &&
          interestCategory &&
          interestCategory.values.map((option) => (
            <Checkbox key={option} value={option}>
              <OptionText>{option}</OptionText>
            </Checkbox>
          ))}
      </AntCheckGroup>
    </Container>
  );
}
