import styled from 'styled-components'
import { Table } from 'antd'

export const Container = styled.div`
  padding: 3rem;
  border-radius: 10px;
  background-color: white;
  height: 75vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;
  justify-content: space-between;
`
export const CardHeading = styled.div`
  color: #141414;
  font-family: IBM Plex Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const TableContainer = styled.div`
  overflow-x: scroll;
  position: relative;
  scrollbar-width: none; /* Hide Firefox scrollbar */
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1200px) {
    overflow-x: auto;
    white-space: nowrap; /* To prevent wrapping of table content */
  }
  .antTable {
    border: none;
    width: 100%;
    position: absolute;
    @media (max-width: 1200px) {
      overflow-x: scroll;
    }
  }
`

export const TableWrapper = styled.div`
  height: 100% !important;
  // border-bottom: 2px solid lightgray;
  overflow-x: scroll;
  overflow-y: scroll;
  position: relative;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 480px) {
    height: 800px;
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5rem;
  font-size: 18px;
`

export const AntTable = styled(Table)`
  .ant-table {
    border-radius: 0px !important;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  // .ant-table-container {
  //   border-radius: 0px !important;
  // }
  // .ant-table-content > table {
  //   border-radius: 0px !important;
  // }

  .ant-table-cell,
  .ant-table-cell-fix-left {
    border-radius: 0px !important;
    font-family: IBM Plex Sans;
    // color: #74829c;
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: 1200px) {
      font-size: 16px !important;
    }
  }
  thead tr th {
    font-size: 18px;

    &::before {
      display: none; // Remove the ::before pseudo-element
    }
  }
  // .ant-table-tbody > tr > td {
  //   border: none !important;
  // }
  .ant-table-thead th {
    // font-size: 18px !important;
    // background-color: #f2f2f3 !important;

    @media (max-width: 1200px) {
      font-size: 16px !important;
    }
  }
  width: 100%;
  position: absolute;
`
