import React from 'react'
import VouchersList from '../../Components/VouchersList'
import PageContent from '../../Components/PageContent'
import PageHeading from '../../Components/PageHeading'
import PrimaryButton from '../../Components/UIKit/Buttons/Primary'
import { useNavigate } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import AddVoucher from '../../Components/Modal/AddNewVoucher'


export default function VoucherManagement () {
  const navigate = useNavigate()
  return (
    <PageContent>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          position: 'absolute',
          right: '10rem',
          top: '4rem'
        }}
      >
        <AddVoucher />
      </div>
      <PageHeading heading={'Voucher Management'} />
      <VouchersList />
    </PageContent>
  )
}
