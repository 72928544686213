import React, { useState } from 'react'
import { Container, MainContainer } from './styles'
import ProdCard from './ProdCard'
import { Button } from 'antd'

export default function FilterProducts ({
  data,
  handleProductSelection,
  nextStep,
  updateData,
  showMore,
  setShowMore
}) {
  const handleShowMore = () => {
    setShowMore(prevShowMore => prevShowMore + 6)
  }
  return (
    <MainContainer>
      <Container>
        {data.slice(0, showMore).map(item => (
          <ProdCard
            key={item._id}
            data={item}
            handleProductSelection={handleProductSelection}
            nextStep={nextStep}
            updateData={updateData}
          />
        ))}
      </Container>
      {showMore < data.length && (
        <Button onClick={handleShowMore}>Show More</Button>
      )}
    </MainContainer>
  )
}
