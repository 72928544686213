import styled from 'styled-components'

export const OuterContainer = styled.div`
  // padding: 1rem;
  background-color: #f2f2f3;
  display: flex;
  height: 100vh;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
`

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  overflow: hidden;
  background-color: white;
  width: 155rem;
  box-shadow: -2px -2px 4px 0px rgba(0, 0, 0, 0.05),
    2px 2px 4px 0px rgba(0, 0, 0, 0.05);
  margin: 1rem;
  @media (max-width: 1200px) {
    width: 95%;
  }
  @media (max-width: 480px) {
    border-radius: 10px;
  }
`
export const LeftContainer = styled.div`
  padding: 10% 5%;
  posittion: relative;
  background-color: #f5f5f5;
  width: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  flex: 4;
  @media (max-width: 1200px) {
    padding: 5% 2%;
    width: 50%;
  }
  @media (max-width: 780px) {
    display: none;
  }
`
export const BackBtn = styled.div`
  position: absolute;
  top: 2rem;
  left: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
`

export const IconContainer = styled.div`
  width: 31.6px;
  height: 30px;
`
export const ImageContainer = styled.div`
  width: 100rem;
  @media (max-width: 900px) {
    width: 95%;
  }
  img {
    @media (max-width: 900px) {
      width: 100%;
    }
  }
`

export const LogoContainer = styled.div`
  // position: absolute;
  // top: 4rem;
  // left: 4rem;
  width: 15rem;

  @media (max-width: 780px) {
    top: 2rem;
    left: 2rem;
  }

  // img {
  //   width: 102px;
  //   height: 24px;
  // }
`

export const RightContainer = styled.div`
  padding: 5% 10% 10% 10%;
  width: 40%;
  flex: 6;
  width: 100%;
  margin: auto;
  @media (max-width: 1200px) {
    padding: 5%;
    width: 40%;
  }
  @media (max-width: 780px) {
    padding: 5%;
    width: 70%;
  }
`
export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
`

export const Title = styled.div`
  color: #141414;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.64px;
`
export const SubText = styled.div`
  // color: #141414;
  color: gray;
  font-size: 16px;
  font-weight: 400;
  // letter-spacing: -0.48px;
`
