import React, { useState } from "react";
import { Drawer, Space } from "antd";
import { RxHamburgerMenu } from "react-icons/rx";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import DropDown from "../Menu";
import { DownOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Hamburger from "hamburger-react";
// import { Squash as Hamburger } from 'hamburger-react'
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faPinterest,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

export const MobileMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const [activeLink, setActiveLink] = useState("/");
  const [activeSubMenu, setActiveSubMenu] = useState(false);

  const PageTitle = styled.div`
    font-size: 3rem;
    color: ${(props) => (props.active ? "#D29E84" : "gray")};
    cursor: pointer;
  `;
  const SubPageTitle = styled.div`
    display: ${(props) => (props.show ? "block" : "none")};
    font-size: 2.5rem;
    color: ${(props) => (props.active ? "#D29E84" : "gray")};
    margin-left: 5rem;
    margin-top: -2rem;
  `;
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const items2 = [
    getItem("Navigation Three", "sub4", [
      getItem("Option 9", "9"),
      getItem("Option 10", "10"),
      getItem("Option 11", "11"),
      getItem("Option 12", "12"),
    ]),
  ];
  const items = [
    {
      key: 1,
      label: (
        <a href="/gifts/For Him" style={{ textDecoration: "none" }}>
          For Him
        </a>
      ),
    },
    {
      key: 2,
      label: (
        <a href="/gifts/For Her" style={{ textDecoration: "none" }}>
          For Her
        </a>
      ),
    },
    {
      key: 3,
      label: (
        <a href="/gifts/Kids" style={{ textDecoration: "none" }}>
          For Kids
        </a>
      ),
    },
  ];

  const showDrawer = () => {
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onChange = (route) => {
    navigate(route);
    setActiveLink(route); // Update active link on click
    setOpen(false);
  };
  const GiftsPageClick = () => {
    setActiveSubMenu(!activeSubMenu);
    // navigate(route)
  };
  const subPageClick = (route) => {
    // setActiveSubMenu(false)
    setOpen(false);
    navigate(route);
  };

  const handleLinkClick = (link) => {
    // Use window.open to open the link in a new tab
    window.open(link, "_blank");
  };

  return (
    <>
      <Space>
        <div style={{ marginLeft: "-2rem" }}>
          <Hamburger
            toggled={open}
            toggle={showDrawer}
            color="#D29E84"
            size={15}
          />
          {/* <RxHamburgerMenu
            color='#D29E84'
            className='hamburger-icon'
            onClick={showDrawer}
          /> */}
        </div>
      </Space>
      <Drawer
        title="Basic Drawer"
        placement={placement}
        closable={false}
        onClose={onClose}
        open={open}
        key={placement}
        width="40rem"
        style={{ zIndex: 100 }}
        height={"100%"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "3rem",
            paddingTop: 0,
          }}
        >
          <div
            onClick={() => setOpen(false)}
            // style={{ borderBottom: '1px solid black' }}
          >
            {/* <CloseOutlined /> */}
          </div>
          <PageTitle
            active={location.pathname === "/" && activeLink === "/"}
            onClick={() => onChange("/")}
          >
            Home
          </PageTitle>
          <PageTitle
            active={
              location.pathname.includes("/gifts") || activeLink === "/gifts"
            }
            onClick={GiftsPageClick}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: "2rem",
              }}
            >
              Gifts
              <DownOutlined style={{ fontSize: "12px" }} />
            </div>
            {/* <DropDown items={items2} /> */}
          </PageTitle>
          <SubPageTitle
            show={activeSubMenu}
            active={location.pathname === "/gifts" || activeLink === "/gifts"}
            onClick={() => subPageClick("/gifts")}
          >
            For All
            {/* <DropDown items={items2} /> */}
          </SubPageTitle>
          <SubPageTitle
            show={activeSubMenu}
            active={
              location.pathname.includes("Him") ||
              activeLink === "/gifts/For Him"
            }
            onClick={() => subPageClick("/gifts/For Him")}
          >
            For Him
            {/* <DropDown items={items2} /> */}
          </SubPageTitle>
          <SubPageTitle
            show={activeSubMenu}
            active={
              location.pathname.includes("Her") ||
              activeLink === "/gifts/For Her"
            }
            onClick={() => subPageClick("/gifts/For Her")}
          >
            For Her
            {/* <DropDown items={items2} /> */}
          </SubPageTitle>
          <SubPageTitle
            show={activeSubMenu}
            active={
              location.pathname === "/gifts/For Kids" ||
              activeLink === "/gifts/For Kids"
            }
            onClick={() => subPageClick("/gifts/For Kids")}
          >
            For Kids
            {/* <DropDown items={items2} /> */}
          </SubPageTitle>

          <PageTitle
            active={location.pathname === "/picker" || activeLink === "/picker"}
            onClick={() => onChange("/picker")}
          >
            GiftPicker
          </PageTitle>
          <PageTitle
            active={
              location.pathname === "/ready-made" ||
              activeLink === "/ready-made"
            }
            onClick={() => onChange("/ready-made")}
          >
            Ready-Made
          </PageTitle>
          <PageTitle
            active={
              location.pathname.includes("blogs") || activeLink === "/blogs"
            }
            onClick={() => onChange("/blogs")}
          >
            Blog
          </PageTitle>
          {/* <PageTitle
            active={location.pathname === '#' || activeLink === '#'}
            onClick={() => onChange('#')}
          >
            Contact
          </PageTitle> */}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "2rem",
            marginTop: "150px",
            fontSize: "32px",
          }}
        >
          <FontAwesomeIcon
            icon={faFacebook}
            style={{ color: "#D29E84" }}
            onClick={() => handleLinkClick("https://facebook.com/heartsybox")}
          />
          {/* <img src='/X-icon.png' alt='twitter-icon' /> */}
          <FontAwesomeIcon
            icon={faInstagram}
            style={{ color: "#D29E84" }}
            onClick={() =>
              handleLinkClick("https://www.instagram.com/heartsybox")
            }
          />
          {/* <img src='/p-icon.png' alt='pinterest-icon' /> */}
          <FontAwesomeIcon
            icon={faPinterest}
            style={{ color: "#D29E84" }}
            onClick={() =>
              handleLinkClick("https://www.pinterest.com/heartsybox/")
            }
          />
        </div>
      </Drawer>
    </>
  );
};
