import { useQuery } from "@tanstack/react-query";
import { fetchOrders } from "../../services/Orders/orderApi";

export const useFetchOrders = () => {
  const {
    data: orders,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useQuery({ queryKey: ["orders"], queryFn: fetchOrders });
  return {
    orders,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};
