import styled from 'styled-components'

export const Container = styled.div`
  padding: 0 25rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media only screen and (max-width: 1800px) {
    padding: 0 10rem;
  }

  @media only screen and (max-width: 1000px) {
    padding: 0 5rem;
  }

  @media only screen and (max-width: 900px) {
    padding: 0;
  }
`
export const TopContainer = styled.div`
  display: flex;
  gap: 3rem;
  @media only screen and (max-width: 900px) {
    flex-wrap: wrap;
  }
`
export const BottomContainer = styled.div`
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
`
