import React, { useEffect } from 'react'
import CustomNavbar from '../../components/Navbar'
import PageContent from '../../components/PageContent'
import Footer from '../../components/Footer'

export default function Layout ({ children }) {
  return (
    <>
      <CustomNavbar />
      <PageContent>{children}</PageContent>
      <Footer />
    </>
  )
}
