import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Select, Spin } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CardHeader, Container } from "./styles";
import BackButton from "../UIKit/Buttons/BackButton";
import { useCreateBlog } from "../../../hooks/Blogs/useCreateBlog";
import { useNavigate } from "react-router-dom";
import { useFetchBlogs } from "../../../hooks/Blogs/useFetchBlogs";
import { useUpdateBlog } from "../../../hooks/Blogs/useUpdateBlog";
import QuillEditor from "../UIKit/TextEditor";
import ImagesUploadBox from "../ImagesUpload";
import VideoUploader from "../VideoUpload";

const AddBlog = ({ data, refetch }) => {
  
  const [editorHtml, setEditorHtml] = useState("");
  const [customLoader, setCustomLoader] = useState(false);
  const [form] = Form.useForm();
  const { mutate, isLoading, isSuccess, error } = useCreateBlog();
  const { refetch: refetchBlogs } = useFetchBlogs();
  const {
    mutate: updateBlog,
    isSuccess: blogUpdated,
    error: blogUpdateError,
  } = useUpdateBlog();
  const navigate = useNavigate();

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ align: [] }],
      ["clean"],
    ],
  };

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
    "align",
  ];

  const onFinish = (values) => {
    console.log(values);
    setCustomLoader(true);

    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("content", values.content);
    formData.append("status", values.status);

    const images = values?.images || [];
    const cloudinaryImages = images?.filter((img) => img?.name);
    const backupImages = images
      ?.filter((img) => !img?.name)
      .map((pic) => pic.preview);
    // Images
    cloudinaryImages.forEach((image) => {
      formData.append("images", image);
    });

    formData.append("backupImages", JSON.stringify(backupImages));
    formData.append("video", values.video);
    const backupVideo = typeof values.video === "string" ? values.video : null;
    formData.append("backupVideo",backupVideo)
    if (!data) {
      mutate(formData);
    } else {
      updateBlog({ id: data?._id, data: formData });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      message.success("Blog Created Successfully");
      setCustomLoader(false);
      refetchBlogs();
      navigate("/admin/blogs");
    }
    if (error) {
      message.error("Blog creation failed");
      setCustomLoader(false);
    }
  }, [isSuccess, error]);

  useEffect(() => {
    if (blogUpdated) {
      setCustomLoader(false);
      refetch();
      refetchBlogs();
      message.success("Blog Updated Successfully");
      navigate("/admin/blogs");
    }
    if (blogUpdateError) {
      setCustomLoader(false);
      message.error("Blog Update Error");
    }
  }, [blogUpdated, blogUpdateError]);
  console.log(data)
  return (
    <Container>
      {customLoader && <Spin fullscreen />}
      <CardHeader>
        <BackButton />
      </CardHeader>
      <Form
        name="blogForm"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={
          {
            ...data,
            images: data?.images?.map((i) => ({ preview: i })) || [],
            video:["null","undefined",""].includes(data?.video) ? null : data?.video
          } || {}
        }
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please enter the title" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: "Please enter the description" }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label="Blog Content"
          name={"content"}
          rules={[{ required: true, message: "Please enter the description" }]}
        >
          {/* <Input.TextArea /> */}
          <QuillEditor />
        </Form.Item>

        <Form.Item
          label="Status"
          name={"status"}
          rules={[{ required: true, message: "Please enter the description" }]}
        >
          <Select
            options={[
              { label: "Suspended", value: "suspended" },
              { label: "Published", value: "published" },
            ]}
            placeholder="Select Status"
          />
        </Form.Item>
        <Form.Item name="images" label="Upload Image (optional)">
          <ImagesUploadBox />
        </Form.Item>
        <Form.Item name="video" label="Upload Video (optional)">
          <VideoUploader />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

export default AddBlog;
