import { ToastContainer, toast } from 'react-toastify';

const ToastProvider = ({ children }) => {
  return (
    <>
      <ToastContainer autoClose={3000} style={{fontSize:'medium'}}/>
      {children}
    </>
  );
};

export default ToastProvider;

export const useGlobalToast = () => {
  const showToast = (message, type) => {
    const toastType = type || 'info'; // Default to 'info' if type is not provided
    toast[toastType](message);
  };

  const showSuccess = (message) => {
    showToast(message, 'success');
  };

 const showError = (error) => {
    showToast(error?.response?.data?.message ?? "Something went wrong.", 'error');
  };

  return {
    showSuccess,
    showError,
    showToast, // If you want to provide more flexibility to specify custom types
  };
};
