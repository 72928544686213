import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  // gap: 2rem;
  // background-color:#f5f5f9;
`
export const SideMenuContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 1000px) {
    // display: none;
    flex: 0;
    padding-top: 6rem;
    position: relative;
  }
  flex: 1.5;
  display: flex;
  flex-direction: column;
  // gap: 1rem;
  background-color: white;
  // min-height: 90vh;
  z-index: 100;
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  min-height: 100vh;
`

export const LogoContainer = styled.div`
  background-image: url(${props => props.logo});
  width: 162px;
  height: 38px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
  margin: 3rem;
  @media (max-width: 1000px) {
    position: absolute;
    right: -6rem;
    top: 1rem;
    margin: 0;
  }
`
