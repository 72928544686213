import React from "react";
import PriceTag from "./UiKit/PriceTag";
import AddToCartButton from "./UiKit/Buttons/AddToCart";
import "../css/productCard.css";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import { useCart } from "../contexts/CartContext";

export default function ProductCard({ price, title, img, det, id, prd }) {
  const navigate = useNavigate();
  const { addToCart } = useCart();
  const seeDetails = (id, title) => {
    navigate(`/gift/${id}_${title}`);
  };
  return (
    <div className="prod-card-container">
      <div
        className="prod-card-img-container"
        style={det && { borderRadius: "10px", overflow: "hidden" }}
        onClick={() => seeDetails(id, title)}
      >
        <img className="prod-image" alt="gift" src={img} />
      </div>
      <div className="prod-card-title" onClick={() => seeDetails(id, title)}>
        <Tooltip title={title} placement="bottom">{title}</Tooltip>
      </div>
      <PriceTag price={price} />
      <AddToCartButton
        onClick={() => {
          addToCart(prd);
        }}
      />
    </div>
  );
}
