import React, { useEffect, useState } from "react";
import { Container, CardHeader, CardHeading } from "./styles";
import AntTable from "../Table";
import Search from "../UIKit/Search";
import TableActions from "../TableActions";
import { useFetchSubscriptions } from "../../../hooks/Subscription/useFetchSubscriptions";
import { useDeleteSubscription } from "../../../hooks/Subscription/useDeleteSubscription";
import { Spin, message } from "antd";
import { formatDate } from "../../../utils/Shared/formatDate";

export default function SubscriptionList() {
  const { subscriptions, isLoading, refetch } = useFetchSubscriptions();
  const {
    mutate: deleteSubscription,
    isSuccess: deleteSuccess,
    error: deleteError,
  } = useDeleteSubscription();
  const [loading, setLoading] = useState(false);
  const [filteredSubscriptions, setFilteredSubscription] = useState([]);

  const columns = [
    {
      title: "Id",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Subscribtion Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_) => formatDate(_),
    },
    {
      title: "Actions",
      dataIndex: "_id",
      key: "actions",
      render: (_, record) => (
        <TableActions
          edit={true}
          onDelete={() => {
            setLoading(true);
            deleteSubscription(_);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    if (deleteSuccess) {
      message.success("Subscription deleted successfully");
      refetch();
      setLoading(false);
    }
    if (deleteError) {
      message.error("Subscription delete Error");
      setLoading(false);
    }
  }, [deleteSuccess, deleteError]);

  useEffect(() => {
    setFilteredSubscription(subscriptions);
  }, [subscriptions]);

  const filterByEmail = (emailValue) => {
    const matchingSubscriptions = subscriptions.filter((sub) =>
      sub.email.includes(emailValue)
    );
    setFilteredSubscription(matchingSubscriptions);
  };
  return (
    <>
      {(isLoading || loading) && <Spin fullscreen />}
      <Container>
        <CardHeader>
          <CardHeading>Subscriptions</CardHeading>
          <Search placeholder="Search by email" filter={filterByEmail} />
        </CardHeader>
        {subscriptions && (
          <AntTable
            columns={columns}
            dataSource={filteredSubscriptions}
            pagination={{ pageSize: 10 }}
            scroll={{ y: 480 }}
          />
        )}
      </Container>
    </>
  );
}
