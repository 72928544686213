import React from "react";
import PageContent from "../../../Components/PageContent";
import PageHeading from "../../../Components/PageHeading";
import AddNewCategory from "../../../Components/AddNewCategory";
import { useParams } from "react-router-dom";
import { useCategory } from "../../../../hooks/category/useCategory";
import { Spin } from "antd";

export default function NewCategory() {
  const { id } = useParams();
  const { category, isLoading } = useCategory(id);
  return (
    <PageContent>
      {isLoading && <Spin fullscreen />}
      <PageHeading heading={"Category Managent"} />
      {category && <AddNewCategory data={category} />}
    </PageContent>
  );
}
