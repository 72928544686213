import React, { useState } from "react";
import { Container, Header, OptionsRow, Option, Selectable } from "./styles";
import { Question, OptionText } from "../../styles";
import male from "../../../../assets/images/quiz/male adult.png";
import female from "../../../../assets/images/quiz/female adult.png";
import baby from "../../../../assets/images/quiz/male toddler.png";

export default function Q1({ setStep, value = null, setValue }) {
  const [isClicked, setIsClicked] = useState(value);

  const handleClick = (val) => {
    setIsClicked(val);
    setValue(val);
    setStep(1);
  };

  return (
    <Container>
      <Header>
        <Question>I need a gift ...</Question>
      </Header>
      <OptionsRow>
        <Option>
          <Selectable
            style={
              isClicked === "For him"
                ? { border: "4px solid #d29e84", borderRadius: "5px" }
                : {}
            }
            onClick={() => handleClick("For him")}
          >
            <img src={male} style={{ width: "100%" }} />
          </Selectable>
          <OptionText>For him</OptionText>
        </Option>
        <Option>
          <Selectable
            style={
              isClicked === "For her"
                ? { border: "4px solid #d29e84", borderRadius: "5px" }
                : {}
            }
            onClick={() => handleClick("For her")}
          >
            <img src={female} style={{ width: "100%" }} />
          </Selectable>
          <OptionText>For her</OptionText>
        </Option>
      </OptionsRow>
    </Container>
  );
}
