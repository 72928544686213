import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  padding: 5rem 10rem;
  background-color: #f5f5f9;
  height: 100%;
  min-height: 91vh;
  @media (max-width: 480px) {
    padding: 5rem 2rem;
  }
`
