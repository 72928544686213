import { Input, Table, Tooltip } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  padding: 3rem;
  border-radius: 10px;
  background-color: white;
  // height: 75vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export const CardHeading = styled.div`
  color: #141414;
  font-family: IBM Plex Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const InnerContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  @media (max-width: 750px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
export const OrderInfo = styled.div`
  grid-column: 1/3;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  // border: 1px solid gray;
  padding: 1rem;
  height: 80rem;
  @media (max-width: 750px) {
    // grid-column: 1/2;
    // height: 100rem;
  }
`;
export const UserInfo = styled.div`
  grid-column: 3;
  border-radius: 10px;
  padding: 1rem 3rem;
  // border: 1px solid gray;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  @media (max-width: 750px) {
    grid-column: 1/3;
  }
`;
export const GrayText = styled.div`
  color: gray;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5rem;
  font-size: 14px;
`;
export const Data = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  // gap: 1rem;
  font-size: 14px;
`;
export const ProdImage = styled.img`
  width: 50px;
  height: 50px;
`;

export const AntTable = styled(Table)`
  .ant-table {
    border-radius: 0px !important;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  // .ant-table-container {
  //   border-radius: 0px !important;
  // }
  // .ant-table-content > table {
  //   border-radius: 0px !important;
  // }

  .ant-table-cell,
  .ant-table-cell-fix-left {
    border-radius: 0px !important;
    font-family: IBM Plex Sans;
    // color: #74829c;
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: 1200px) {
      font-size: 16px !important;
    }
  }
  thead tr th {
    font-size: 18px;

    &::before {
      display: none; // Remove the ::before pseudo-element
    }
  }
  // .ant-table-tbody > tr > td {
  //   border: none !important;
  // }
  .ant-table-thead th {
    // font-size: 18px !important;
    // background-color: #f2f2f3 !important;

    @media (max-width: 1200px) {
      font-size: 16px !important;
    }
  }
  width: 100%;
  position: absolute;
`;
export const TableWrapper = styled.div`
  height: 100% !important;
  // border-bottom: 2px solid lightgray;
  overflow-x: scroll;
  overflow-y: scroll;
  position: relative;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 480px) {
    height: 800px;
  }
`;
export const TruncatedText = styled(Tooltip)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: red;
  cursor: pointer; // Add cursor pointer to indicate it's clickable
`;
