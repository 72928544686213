import React from 'react'
import { Steps } from 'antd'
import { MdOutlinePayment } from 'react-icons/md'
import { IoBagCheckOutline } from 'react-icons/io5'
import { IoCartOutline } from 'react-icons/io5'
import { FaRegSmileBeam } from 'react-icons/fa'
import { StepsContainer } from './styles'
import { AntMobileSteps } from './styles'

export default function AntSteps ({ cart, checkout }) {
  return (
    <>
      <StepsContainer>
        <Steps
          style={{ paddingBottom: '5rem' }}
          items={[
            {
              title: 'Cart',
              status: 'finish',
              icon: <IoCartOutline style={{ color: '#d29e84' }} />
            },
            {
              title: 'Payment',
              status: cart ? 'process' : checkout ? 'finish' : 'wait',
              icon: <MdOutlinePayment style={{ color: '#d29e84' }} />
            },
            {
              title: 'Checkout',
              status: 'wait',
              icon: (
                <IoBagCheckOutline style={{ color: 'rgba(0, 0, 0, 0.25) !important' }} />
              )
            }
          ]}
        />
      </StepsContainer>
      <AntMobileSteps
        style={{ paddingBottom: '5rem' }}
        items={[
          {
            status: 'finish',
            icon: <IoCartOutline style={{ color: '#d29e84' }} />
          },
          {
            status: cart ? 'process' : checkout ? 'finish' : 'wait',
            icon: <MdOutlinePayment style={{ color: '#d29e84' }} />
          },
          {
            status: 'wait',
            icon: <IoBagCheckOutline style={{ color: '#d29e84' }} />
          }
        ]}
      />
    </>
  )
}
