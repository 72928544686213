import React from 'react'
import {
  OuterContainer,
  Container,
  LeftContainer,
  RightContainer,
  ImageContainer,
  Title,
  SubText,
  LogoContainer,
  RightContent,
  BackBtn,
  IconContainer
} from './styles'
// import icon from '../../assets/icons/back.png'
import logo from '../../Assets/logo2.png'
import { useNavigate } from 'react-router-dom'

export default function PreLoginFormContainer ({
  Content,
  img,
  title,
  subtext,
  backBtn
}) {
  const navigate = useNavigate()
  const back = () => {
    navigate(-1)
  }
  return (
    <OuterContainer>
      <Container>
        <LeftContainer>
          {/* {backBtn && (
            <BackBtn onClick={back}>
              <IconContainer>
                <img src={''} />
              </IconContainer>
              <div>Back</div>
            </BackBtn>
          )} */}
          <ImageContainer>
            <img src={img} style={{ width: '100%' }} />
          </ImageContainer>
        </LeftContainer>
        <RightContainer>
          <RightContent>
            <LogoContainer>
              <img src={logo} style={{ width: '100%' }} />
            </LogoContainer>
            <Title>{title}</Title>
            <SubText>{subtext}</SubText>
            {Content}
          </RightContent>
        </RightContainer>
      </Container>
    </OuterContainer>
  )
}
