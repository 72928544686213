import React from "react";
import PageContent from "../../../Components/PageContent";
import PageHeading from "../../../Components/PageHeading";
import AddNewCategory from "../../../Components/AddNewCategory";

export default function NewCategory() {
  return (
    <PageContent>
      <PageHeading heading={"Category Management"} />
      <AddNewCategory />
    </PageContent>
  );
}
