import React from "react";
import PageContent from "../../../Components/PageContent";
import PageHeading from "../../../Components/PageHeading";
import BlogDetailsComponent from "../../../Components/BlogDetails";
import { useParams } from "react-router-dom";
import { useBlog } from "../../../../hooks/Blogs/useBlog";
import { Spin } from "antd";

export default function BlogDetails() {
  const { id } = useParams();
  const { blog, isLoading, refetch } = useBlog(id);
  return (
    <PageContent>
      {isLoading && <Spin fullscreen />}
      <PageHeading heading={"Blog"} />
      {blog && <BlogDetailsComponent data={blog} refetch={refetch} />}
    </PageContent>
  );
}
