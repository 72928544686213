import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import { Button, Input, Spin, message } from "antd";
import PrimaryButton from "../../UIKit/Buttons/Primary";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Form } from "antd";
import { DatePicker } from "antd";
import { useCreateCoupon } from "../../../../hooks/Coupon/useCreateCoupon";
import { useFetchCoupons } from "../../../../hooks/Coupon/useFetchCoupons";

export default function AddVoucher() {
  const [customLoader, setCustomLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    mutate,
    isLoading,
    isSuccess: couponCreated,
    error: couponCreateError,
  } = useCreateCoupon();
  const { refetch } = useFetchCoupons();
  const [form] = Form.useForm();

  const handleOk = () => {
    setOpen(false);
    form.resetFields();
  };
  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };
  const showModal = () => {
    setOpen(true);
  };

  const onFinish = (values) => {
    setCustomLoader(true);
    mutate(values);
  };

  useEffect(() => {
    if (couponCreated) {
      message.success("Coupon Created Successfully");
      handleOk();
      setCustomLoader(false);
      refetch();
    }
    if (couponCreateError) {
      message.error("Coupon Creation failed");
      setCustomLoader(false);
    }
  }, [couponCreated, couponCreateError]);

  return (
    <>
      {customLoader && <Spin fullscreen />}
      <PrimaryButton
        text={"Add New"}
        icon={<PlusOutlined />}
        simple={true}
        responsive={true}
        onClick={showModal}
      />
      <Modal
        style={{ borderRadius: "20px" }}
        open={open}
        closeIcon={false}
        width={"400px"}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        footer={<></>}
      >
        <Container>
          <Form
            layout="vertical"
            style={{ width: "100%" }}
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              name={"code"}
              label="Voucher Code"
              rules={[{ required: true }]}
            >
              <Input placeholder="Code" />
            </Form.Item>
            <Form.Item
              name={"discountPercentage"}
              label="Discount Percentage"
              rules={[{ required: true }]}
            >
              <Input placeholder="Discount" />
            </Form.Item>
            <Form.Item
              name={"validFrom"}
              label="Valid From"
              rules={[{ required: true }]}
            >
              <DatePicker placeholder="Valid From" style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name={"validTo"}
              label="Valid Till"
              rules={[{ required: true }]}
            >
              <DatePicker placeholder="Valid Till" style={{ width: "100%" }} />
            </Form.Item>
            <div
              style={{
                display: "flex",
                gap: "3rem",
                justifyContent: "flex-end",
              }}
            >
              <Form.Item>
                <Button onClick={handleCancel}>Cancel</Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" onClick={onFinish}>
                  {" "}
                  Add
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Container>
      </Modal>
    </>
  );
}
// export default AddVoucher;
