import styled from 'styled-components'
import { Dropdown } from 'antd'
import { Button } from 'antd'

export const AntdDropdown = styled(Dropdown)``

export const DesktopMenu = styled.div`
  @media only screen and (max-width: 600px) {
    display: none;
  }
`
export const MobileMenu = styled.div`
  display: none;
  @media only screen and (max-width: 599px) {
    display: block;
  }
`

export const MenuButton = styled(Button)`
  width: 30px;
  padding: 0;
  border: none;
  background-color: ${props =>
    !props.header ? `#f2f2f3` : `rgba(255, 0, 0, 0)`};
  box-shadow: 0 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
