import styled from 'styled-components'

export const Container = styled.div`
  padding: 10rem 22rem;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  @media only screen and (max-width: 1300px) {
    gap: 5rem;
  }
  @media only screen and (max-width: 1100px) {
    padding: 20rem 15rem 0 15rem;
  }
  @media only screen and (max-width: 800px) {
    padding: 15rem 2rem 0 2rem;
  }
`

export const Image = styled.img`
  width: 100%;
  flex-shrink: 0;
  border-radius: 1.25rem;
`
