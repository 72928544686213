import React from 'react'
import {
  Container,
  Title,
  Description
} from '../../GiftDetails/InfoContainer/styles'
import PriceTag from '../../UiKit/PriceTag'
import AddToCartButton from '../../UiKit/Buttons/AddToCart'
import { useCart } from '../../../contexts/CartContext'
import SanitizedHTML from '../../../AdminPanel/Components/SanitizeHtml'

export default function GiftBoxInfo ({ product }) {
  const { addToCart } = useCart()
  return (
    <Container>
      <Title>{product.title}</Title>
      <Description>
        <SanitizedHTML html={product.description} />
      </Description>
      <PriceTag price={product.price} />
      <AddToCartButton
        det={true}
        onClick={() => {
          addToCart(product)
        }}
      />
    </Container>
  )
}
