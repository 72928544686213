import React from 'react'
import QuizComponent from '../../components/Quiz'

export default function Quiz () {
  return (
    <>
      <QuizComponent />
    </>
  )
}
