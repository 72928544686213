import styled from 'styled-components'
import { Input, Button } from 'antd'

export const Container = styled.div`
  width: 100%;
  padding: 0 25rem;

  @media only screen and (max-width: 1800px) {
    padding: 0 10rem;
  }

  @media only screen and (max-width: 1000px) {
    padding: 0 5rem;
  }

  @media only screen and (max-width: 700px) {
    padding: 0;
  }
`
export const InnerContainer = styled.div`
  display: flex;
  gap: 2rem;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    gap: 10rem;
  }
`
export const CardContainer = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  border: 2px solid lightgray;
  padding: 1rem;
  border-radius: 10px;
`
export const DetailsCard = styled.div`
  flex: 1;
`
export const Recommended = styled.div`
  margin-top: 10rem;
`

export const Voucher = styled(Input)``
export const VoucherButton = styled(Button)`
  color: white;
  background-color: #d29e84;
  border: none;
`
