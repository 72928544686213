import styled from 'styled-components'
import { Input } from 'antd'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex: ${props => props.flex};
  height: 60rem;
  min-width: 40rem;
  @media only screen and (max-width: 460px) {
    height: 80rem;
  }
`
export const ImageContainer = styled.div`
  width: 100%;
  flex: 2;
  overflow: hidden;
  height: 40rem;
  @media only screen and (max-width: 460px) {
    height: 60rem;
  }
`
export const Image = styled.img`
  width: 100%;
  // height:100%;
  object-fit: cover;
  height: 40rem;
  ${props => !props.full && `aspect-ratio: 1/1;`}
  @media only screen and (max-width: 460px) {
    height: 60rem;
  }
`
export const InfoContainer = styled.div`
  padding: 2rem;
  background-color: #d29e84;
  color: white;
  flex: 1;
  height: 20rem;
  font-family: Nunito;
  ${props =>
    props.info &&
    `display:flex;flex-direction:column;padding:3rem; justify-content:center; align-items:center;gap:5rem;`}
`
export const Title = styled.div`
  font-size: 3rem;
  @media only screen and (max-width: 460px) {
    font-size: 22px;
  }
`
export const Description = styled.div`
  font-size: 2rem;
  align-items: justify;
  @media only screen and (max-width: 460px) {
    font-size: 16px;
  }
`
export const AntInput = styled(Input)`
  &::placeholder {
    color: #d29e84;
  }
`
