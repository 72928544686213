import React from 'react'
import Slider from 'react-slick'
import img1 from '../../assets/images/Carousel/plain1.jpg'
import img2 from '../../assets/images/Carousel/plain2.jpg'
import styled from 'styled-components'
import SanitizedHTML from '../../AdminPanel/Components/SanitizeHtml'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const MainCarousel = () => {
  const slides = [
    {
      image: img1,
      text: 'Effortlessly <br/> Extraordinary,<br/>Ready for Gifting',
      subText: 'Discover curated perfection with<br/>our Ready-made Boxes.'
    },
    {
      image: img2,
      text: 'Effortlessly <br/> Extraordinary,<br/>Ready for Gifting',
      subText: 'Discover curated perfection with<br/>our Ready-made Boxes.'
    }
  ]

  // Styled components
  const CistomSlider = styled(Slider)`
    width: 100%;
    @media only screen and (max-width: 500px) {
      object-fit: cover;
      height: 400px;
      overflow: hidden;
    }
  `
  const CistomSliderTextContainer = styled.div`
    position: absolute;
    color: white;
    top: 15%;
    padding-left: 1%;
    @media only screen and (max-width: 500px) {
      top: 10%;
    }
  `
  const CistomSliderImage = styled.img`
    width: 100%;
    @media only screen and (max-width: 500px) {
      width: auto;
    }
  `
  const CistomSliderHeading = styled.h1`
    font-size: 54px;
    @media only screen and (max-width: 950px) {
      font-size: 44px;
    }
    @media only screen and (max-width: 800px) {
      font-size: 38px;
    }
  `
  const CistomSliderText = styled.p`
    font-size: 24px;
    border-left: 5px solid #d29e84;
    padding-left: 10px;
    color: white;
    @media only screen and (max-width: 800px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 500px) {
      font-size: 14px;
    }
  `
  // Carousel settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  }

  return (
    <CistomSlider {...settings}>
      {slides.map((slide, index) => (
        <div
          key={index}
          className='carousel-slide'
          style={{ position: 'relative' }}
        >
          <CistomSliderTextContainer>
            <CistomSliderHeading>
              <SanitizedHTML html={slide.text} fontFam={'Tangerin'} />
            </CistomSliderHeading>
            <CistomSliderText>
              <SanitizedHTML
                html={slide.subText}
                fontFam={'gilroy-Light, sans-serif'}
              />
            </CistomSliderText>
          </CistomSliderTextContainer>

          <CistomSliderImage src={slide.image} alt={`slide-${index + 1}`} />
        </div>
      ))}
    </CistomSlider>
  )
}

export default MainCarousel
