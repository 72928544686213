import React from "react";
import BlogDetailsComponent from "../../components/BlogDetails";
import { useBlog } from "../../hooks/Blogs/useBlog";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import { formatDate } from "../../utils/Shared/formatDate";

export default function UserBlogDetails() {
  const blogDetails = {
    title: "Sample Blog",
    description: "A short description of the blog.",
    imageUrl: "https://placekitten.com/800/400", // Replace with your actual image URL
    body: "The main content of your blog goes here. This can be a longer text describing your blog post.",
    author: "John Doe",
    publishedDate: new Date(),
  };
  const { id } = useParams();
  const { blog, isLoading } = useBlog(id);
  console.log("blog", blog);
  return (
    <>
      {isLoading && <Spin fullscreen />}
      <BlogDetailsComponent
        title={blog?.title}
        description={blog?.description}
        imageUrl={blog?.images[0]}
        body={blog?.content}
        author={blog?.createdAt}
      />
    </>
  );
}
