import React, { useState } from 'react';
import '../css/carousel.css';
import Slider from "react-slick";

const carouselItems = [
  {
    step : 1,
    title: 'Pick a Sweet',
    description: 'Indulge your taste buds and create an exquisite flavour pairing. Choose from our artisanal chocolates or delicate macarons. Each bite is a glimpse into a world of taste and delight.'
  },
  {step : 2,
    title: 'Pick a Flower',
    description: 'Enhance your box by selecting the bloom that resonates with your heart. Feel the velvety petals, embrace the delicate fragrance, and let it whisper your message of love.'
  },
  {step : 3,
    title: 'Pick a Gift',
    description: 'The heart of your creation—pick a gift that embodies your sentiments. Let it be a symbol of joy, wonder, or inspiration, just waiting to be unwrapped by your loved one.'
  },
  // Random content for the 4th and 5th items
  {step : 4,
    title: 'Write a Note',
    description: 'Add a personal touch with a handwritten note. Convey your deepest thoughts and make the gift uniquely personal.'
  },
  {step : 5,
    title: 'Choose a Card',
    description: 'Select a card that complements your gift. From romantic to whimsical, choose the design that frames your message perfectly.'
  },
];

const CustomCarousel = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const settings = {
    dots: true,
    nextArrow: <img src="/nextArrow.png" alt="nextArrow" />,
    prevArrow: <img src="/prevArrow.png" alt="prevArrow" />,
    infinite: true,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
  
      {
        breakpoint:1208, // Adjust the breakpoint as needed
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: 1000, // Adjust the breakpoint as needed
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          variableWidth:true
        },
      },
      {
        breakpoint: 470, // Adjust the breakpoint as needed
        settings: {
          slidesToShow: 1.25 ,
          slidesToScroll: 1,
          variableWidth:true
        },
      },

      // Add more breakpoints if necessary
    ],
  };

  return (
    <div className="custom-carousel-container">
              <Slider {...settings} 
              >
              {carouselItems.map((item, idx) => (
                <div key={idx} className="each-slide" >
                <div key={idx} className="carousel-item-content">
                  <div className="d-flex flex-column gap-5">
                  <h2>Step {item.step}</h2>
                  <h1 style={{fontSize:'3rem'}}>{item.title}</h1>
                  <p>{item.description}</p>
                  </div>
                </div>
                </div>
              ))}
              </Slider>        
    </div>
  );
};

export default CustomCarousel;
