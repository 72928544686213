import './../css/productCategory.css'
import { useNavigate } from 'react-router-dom'

const ProductCategory = () => {
  const navigate = useNavigate()
  return (
    <>
      <section className='main-product-category'>
        <h1 style={{ fontFamily: 'var(--heading-font)', fontSize: '3rem' }}>
          Product <span className='header-span'>Categories</span>
        </h1>

        <div className='categories-parent'>
          <div
            className='categories-child'
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/gifts')}
          >
            <img src='/diamond.png' alt='diamond-icon' />
            <p>Jewellery</p>
          </div>
          <div
            className='categories-child'
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/gifts')}
          >
            <img src='/device.png' alt='device-icon' />
            <p>Gadgets</p>
          </div>
          <div
            className='categories-child'
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/gifts')}
          >
            <img src='/shoulder-bag.png' alt='shoulder-bag-icon' />
            <p>Accessories</p>
          </div>
          <div
            className='categories-child'
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/gifts')}
          >
            <img src='/child.png' alt='child-icon' />
            <p>Toys</p>
          </div>
          {/* <div className='categories-child'>
            <img src='/tie.png' alt='tie-icon' style={{ width: '10rem' }} />
            <p>Suits</p>
          </div> */}
        </div>
      </section>
    </>
  )
}

export default ProductCategory
