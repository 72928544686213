import axiosInstance from "../../utils/Axios/axiosConfig";

export const createBlog = async (blogData) => {
  const result = await axiosInstance.post("/blogs", blogData);
  return result.data;
};

export const deleteBlog = async (blogId) => {
  const result = await axiosInstance.delete(`/blogs/${blogId}`);
  return result.data;
};

export const updateBlog = async (blog) => {
  const result = await axiosInstance.put(`/blogs/${blog.id}`, blog.data);
  return result.data;
};

export const getBlog = async (blogId) => {
  const result = await axiosInstance.get(`/blogs/${blogId}`);
  return result.data;
};

export const fetchBlogs = async () => {
  const result = await axiosInstance.get(`/blogs`);
  return result.data;
};
