import React, { useState } from 'react'
import { Container, Header } from '../Q1/styles'
import { OptionText, Question } from '../../styles'
import { Slider } from 'antd'
import styled from 'styled-components'
import RangeSlider from '../../../UiKit/Slider'

export default function Q6 ({ setStep, value = null, setValue, step5Values }) {
  const [sliderValues, setSliderValues] = useState(
    step5Values.reduce((acc, option) => {
      acc[option.toLowerCase().replace(/\s/g, '_')] = value
      return acc
    }, {})
  )

  const AntSlider = styled(Slider)`
    .ant-slider-track {
      background-color: #d29e84;
    }
    &:hover .ant-slider-track {
      background-color: #d29e84 !important;
    }
    .ant-slider-handle .ant-tooltip-open ::after {
      background-color: #d29e84 !important;
    }
  `
  const marks = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10'
  }

  const onChange = (option, v) => {
    const newValues = { ...sliderValues, [option]: v }
    setSliderValues(newValues)
    setStep(6)

    const maxOption = Object.keys(newValues).reduce(
      (max, key) => (newValues[key] > newValues[max] ? key : max),
      Object.keys(newValues)[0]
    )

    const optionTexts = step5Values.reduce((acc, curr) => {
      return { ...acc, [curr.toLowerCase().replaceAll(' ', '_')]: curr }
    }, {})

    setValue(optionTexts[maxOption])
  }

  return (
    <Container>
      <Header>
        <Question>
          On a scale of 1 to 10, how would you say your giftee is interested in…
        </Question>
      </Header>
      <div>
        {step5Values.map(option => (
          <div key={option}>
            <OptionText>{option}</OptionText>
            <AntSlider
              defaultValue={0}
              min={1}
              max={10}
              marks={marks}
              onChange={v =>
                onChange(option.toLowerCase().replace(/\s/g, '_'), v)
              }
              value={sliderValues[option.toLowerCase().replace(/\s/g, '_')]}
            />
          </div>
        ))}
      </div>
    </Container>
  )
}
