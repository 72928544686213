import React from 'react'
import {
  Container,
  LeftContainer,
  RightContainer,
  Image1,
  Image2,
  Image3,
  SVGContainer
} from './styles'
import { MainHeading, PinkText, Text, MainButton } from '../styles'
import img1 from '../../../assets/images/giftPicker/Base.png'
import img2 from '../../../assets/images/giftPicker/Rectangle 4733.png'
import img3 from '../../../assets/images/giftPicker/Abstract.png'
import giftPickerDots from '../../../assets/SVG/giftPickerDots'
import { useNavigate } from 'react-router-dom'
import img from '../../../assets/images/find-a-way.png'

export default function Section1 () {
  const navigate = useNavigate()
  return (
    <Container>
      <LeftContainer>
        <MainHeading>
          Find the Perfect Gift
          <br /> for Anyone.
        </MainHeading>
        <PinkText>
          Introducing Heartsy Box GiftPicker, the better
          <br /> way to find the perfect gift for anyone.
        </PinkText>
        <Text>
          Spend less time finding the perfect gift and more time
          <br /> celebrating with those you love.
        </Text>
        <MainButton onClick={() => navigate('/quiz')}>
          Take our gift quiz
        </MainButton>
      </LeftContainer>
      <RightContainer>
        <Image1 src={img} />

        {/* <Image2 src={img2} />
        <Image3 src={img3} /> */}
        {/* <SVGContainer>
          <giftPickerDots />
        </SVGContainer> */}
      </RightContainer>
    </Container>
  )
}
