import React, { useEffect, useState } from "react";
import {
  Container,
  InnerContainer,
  CardContainer,
  DetailsCard,
  Recommended,
  Title,
  Voucher,
  VoucherButton,
} from "./styles";
import ItemCard from "./ItemCard";
import OrderDetails from "./OrderDetailsCard";
import GiftsCollection from "../GiftsCollection";
import AntSteps from "../AntSteps";

import { Empty, Spin } from "antd";
import { useCart } from "../../contexts/CartContext";
import { useFetchProducts } from "../../hooks/Products/useFetchProducts";

export default function CartComponent() {
  const { cart } = useCart();
  const { products, isLoading } = useFetchProducts();

  const filteredProducts =
    products &&
    products.filter((product) =>
      cart.some(
        (item) =>
          item.product.categoryFilters?.type ===
            product.categoryFilters?.type ||
          item.product.isAReadyMadeBox === product.isAReadyMadeBox
      )
    );

  return (
    <Container>
      {isLoading && <Spin fullscreen />}
      <AntSteps cart={true} />
      <InnerContainer>
        <CardContainer>
          {cart &&
            cart.map((v) => (
              <ItemCard
                productId={v?.product._id}
                name={v?.product?.title}
                color={v?.color}
                price={v?.product?.price}
                quantity={v?.quantity}
                image={v?.product?.imagesWithColors[0]?.src}
                personalizedInputs={v?.personalizedInputs}
                cartRawItem={v}
              />
            ))}
          {cart.length < 1 && (
            <Empty
              description="No products available"
              style={{ marginTop: "1rem" }}
            />
          )}
        </CardContainer>
        <DetailsCard>
          <OrderDetails cart={cart} />
        </DetailsCard>
      </InnerContainer>
      <Recommended>
        <GiftsCollection
          title={"Recommended"}
          suggestion={true}
          det={true}
          products={filteredProducts?.slice(-3)}
        />
        <GiftsCollection
          title={"You might also like"}
          suggestion={true}
          det={true}
          products={filteredProducts?.slice(-3)}
        />
      </Recommended>
    </Container>
  );
}
