import { useQuery } from "@tanstack/react-query";
import { fetchCoupons } from "../../services/Coupon/couponApi";

export const useFetchCoupons = () => {
  const {
    data: coupons,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useQuery({ queryKey: ["coupons"], queryFn: fetchCoupons });
  return {
    coupons,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};
