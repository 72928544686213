import React, { useEffect, useState } from "react";
import { Container, InfoContainer, SuggestionsContainer } from "./styles";
import ImageSlider from "./ImageSlider";
import Info from "./InfoContainer";
import GiftsCollection from "../GiftsCollection";
import { useFetchProducts } from "../../hooks/Products/useFetchProducts";
import BackButton from "../../AdminPanel/Components/UIKit/Buttons/BackButton";

export default function GiftDetailsComponent({ product }) {
  const [selectedColor, setSelectedColor] = useState("");
  const { isLoading, products } = useFetchProducts();
  const [randomProducts, setRandomProducts] = useState([]);
  const targetScopes = ["buildABox1", "buildABox2"];

  const getRandomElements = (array, count) => {
    const shuffledArray = array.sort(() => Math.random() - 0.5);
    return shuffledArray.slice(0, count);
  };

  useEffect(() => {
    if (products) {
      const filteredProducts = products.filter((product) =>
        product.scopes.some((scope) => targetScopes.includes(scope))
      );
      const randomElements = getRandomElements(filteredProducts, 3);
      setRandomProducts(randomElements);
    }
  }, [products]);
  return (
    <Container>
      <BackButton />
      {product && (
        <>
          <InfoContainer>
            <ImageSlider product={product} selectedColor={selectedColor} />
            <Info
              product={product}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
            />
          </InfoContainer>
          <SuggestionsContainer>
            {products && (
              <GiftsCollection
                title={"Pairs well with"}
                suggestion={true}
                det={true}
                products={randomProducts}
              />
            )}
            {products && (
              <GiftsCollection
                title={"You might also like"}
                suggestion={true}
                det={true}
                products={products
                  ?.filter(
                    (prd) =>
                      prd?.categoryFilters?.type ===
                        product?.categoryFilters?.type &&
                      prd._id !== product._id
                  )
                  ?.slice(-3)}
              />
            )}
          </SuggestionsContainer>
        </>
      )}
    </Container>
  );
}
