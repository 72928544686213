import { useQuery } from "@tanstack/react-query";
import { fetchProduct } from "../../services/Products/productsApi";
export const useProduct = (id) => {
  const {
    data: product,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ["product", id],
    queryFn: () => (id ? fetchProduct(id) : null),
  });
  return {
    product,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};
