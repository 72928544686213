import styled from 'styled-components'
import { Steps } from 'antd'

export const AntMobileSteps = styled(Steps)`
  @media screen and (min-width: 599px) {
    display: none;
  }
  @media screen and (max-width: 600px) {
    flex-direction: row !important;
    flex-wrap: nowrap !important;
  }

  && .ant-steps-item-tail::after {
    @media screen and (max-width: 600px) {
      content: none;
    }
  }

  // && .ant-steps-item-title::after {
  //   background-color: #d29e84 !important;
  // }
  && .ant-steps-item-icon {
    @media screen and (max-width: 600px) {
      margin: 0 !important;
    }
  }

  .ant-steps-item-content {
    .ant-steps-item-tail {
      background-color: red !important; // Change this to the desired color
    }
  }

  && .ant-steps-item {
    @media screen and (max-width: 600px) {
      &:nth-child(-n + 2) .ant-steps-item-title::after {
        display: block !important;
      }
      &:nth-child(n + 3) .ant-steps-item-title::after {
        display: none !important;
      }
    }
  }
`

export const StepsContainer = styled.div`
  @media screen and (max-width: 600px) {
    display: none;
  }
`
