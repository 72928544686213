import React from "react";
import {
  Container,
  CardHeader,
  CardHeading,
  InnerContainer,
  OrderInfo,
  UserInfo,
  AntTable,
  TableWrapper,
  Row,
  ProdImage,
  Data,
  GrayText,
  TruncatedText,
} from "./styles";
import img from "../../Assets/product_1.jpg";
import BackButton from "../UIKit/Buttons/BackButton";
import { useParams } from "react-router-dom";
import { useOrder } from "../../../hooks/Orders/useOrder";
import ImagesUploadBox from "../ImagesUpload";
import { Divide } from "hamburger-react";
import { Divider } from "antd";

export default function OrderDetailsComponent() {
  const { id } = useParams();
  const { order, isLoading } = useOrder(id);
  console.log(order);
  const columns = [
    {
      title: "Sr",
      dataIndex: "product",
      key: "key",
      render: (prd) => prd._id.substr(-3),
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "image",
      width: 1,
      render: (prd) => <ProdImage src={prd.imagesWithColors[0]?.src} />,
    },
    {
      title: "Title",
      dataIndex: "product",
      key: "title",
      render: (prd) => prd.title,
    },
    { title: "Quantity", dataIndex: "quantity", key: "quantity" },
    {
      title: "Price",
      dataIndex: "product",
      key: "price",
      render: (prd) => <>${prd.price}</>,
    },
    AntTable.EXPAND_COLUMN,
  ];

  //console.log(order);
  return (
    <Container>
      <CardHeader>
        <BackButton />
      </CardHeader>
      <CardHeading>Order ID # {order && order._id}</CardHeading>
      <InnerContainer>
        <OrderInfo>
          <TableWrapper>
            {order && (
              <AntTable
                columns={columns}
                dataSource={order && order.products}
                pagination={{ pageSize: 10 }}
                expandable={{
                  expandedRowRender: (record) => (
                    <>
                      {record.personalizedInputs.length > 0 && (
                        <div style={{ fontSize: "14px", color: "gray" }}>
                          <div style={{ marginBottom: "2rem" }}>
                            Personalization Fields:
                          </div>
                          {record.personalizedInputs.map((pI) => (
                            <Row
                              style={{
                                justifyContent: "flex-start",
                                gap: "5rem",
                              }}
                            >
                              <div>{pI.key}</div>
                              {pI.key === "image" ? (
                                <ImagesUploadBox
                                  value={[{ preview: pI.value }]}
                                  readonly
                                />
                              ) : (
                                <div>{pI.value}</div>
                              )}
                            </Row>
                          ))}
                        </div>
                      )}
                      <Divider />
                      {record.variations.length > 0 && (
                        <div style={{ fontSize: "14px", color: "gray" }}>
                          <div style={{ marginBottom: "2rem" }}>
                            Variation Fields:
                          </div>
                          {record.variations.map((item) => (
                            <Row
                              style={{
                                justifyContent: "flex-start",
                                gap: "5rem",
                              }}
                            >
                              <div>{item.key}</div>

                              <div>{item.value}</div>
                            </Row>
                          ))}
                        </div>
                      )}
                      {/* {} */}
                    </>
                  ),
                }}
              />
            )}
          </TableWrapper>
        </OrderInfo>
        <UserInfo>
          <CardHeading style={{ marginBottom: "3rem" }}>
            Sender Info:
          </CardHeading>
          <Data>
            <GrayText>Name:</GrayText>
            <div>
              {order?.senderInfo.firstName + " " + order?.senderInfo.lastName}
            </div>
          </Data>
          <Data>
            <GrayText>Address:</GrayText>
            <div>{order?.senderInfo.address}</div>
          </Data>
          <Data>
            <GrayText>Contact:</GrayText>
            <div>{order?.senderInfo.emailAddress}</div>
          </Data>
          <Data>
            <GrayText>State:</GrayText>
            <div>{order?.senderInfo.state}</div>
          </Data>
          <Data>
            <GrayText>City:</GrayText>
            <div>{order?.senderInfo.city}</div>
          </Data>
          <Data>
            <GrayText>Zip code:</GrayText>
            <div>{order?.senderInfo.zipCode}</div>
          </Data>
          {order?.personalMessage && order?.personalMessage !== "undefined" && (
            <>
              <CardHeading style={{ margin: "3rem 0 1rem 0" }}>
                Personal Message:
              </CardHeading>
              <Data>
                <GrayText>{order?.personalMessage}</GrayText>
              </Data>
            </>
          )}
          {order?.recipientInfo.emailAddress.length > 1 && (
            <>
              <CardHeading style={{ margin: "3rem 0" }}>
                Recipient Info:
              </CardHeading>
              <Data>
                <GrayText>Name:</GrayText>
                <div>
                  {order?.recipientInfo.firstName +
                    " " +
                    order?.recipientInfo.lastName}
                </div>
              </Data>
              <Data>
                <GrayText>Address:</GrayText>
                <div>{order?.recipientInfo.address}</div>
              </Data>
              <Data>
                <GrayText>Contact:</GrayText>
                <div>{order?.recipientInfo.emailAddress}</div>
              </Data>
              <Data>
                <GrayText>State:</GrayText>
                <div>{order?.recipientInfo.state}</div>
              </Data>
              <Data>
                <GrayText>City:</GrayText>
                <div>{order?.recipientInfo.city}</div>
              </Data>
              <Data>
                <GrayText>Zip code:</GrayText>
                <div>{order?.recipientInfo.zipCode}</div>
              </Data>
            </>
          )}
          <CardHeading
            style={{
              margin: "1.5rem 0",
              paddingTop: "1.5rem",
              borderTop: "1px solid lightgray",
            }}
          >
            Order Info:
          </CardHeading>
          <Data>
            <GrayText>Stripe Payment Id</GrayText>
            <TruncatedText title={order?.stripePaymentId}>
              {order?.stripePaymentId}
            </TruncatedText>
          </Data>

          <Data>
            <div>Total</div>
            <div>${order?.totalPrice}</div>
          </Data>
        </UserInfo>
      </InnerContainer>
    </Container>
  );
}
