import React from 'react'
import Section1 from './Section1'
import { MainContainer } from './styles'
import Section2 from './Section2'
import Section22 from './Section2.2'
import Section3 from './Section3'
import Section4 from './Section4'
import CustomNavbar from '../Navbar'
import Footer from '../Footer'
export default function GiftPickerPageComponent () {
  return (
    <>
      <CustomNavbar />

      <Section1 />

      <MainContainer>
        <Section2 />
        <Section22 />
      </MainContainer>
      <Section3 />
      <MainContainer>
        <Section4 />
      </MainContainer>
      <Footer />
    </>
  )
}
