// OrderDetails.js
import React from 'react'
import { CardContainer, ProductItem, GrandTotal, ProceedButton } from './styles'
import { useNavigate } from 'react-router-dom'

const OrderDetails = ({ cart }) => {
  const navigate = useNavigate()

  const calculateTotalPrice = product => {
    const productPrice = product?.product.price || 0
    const personalizedInputsPrice = product?.personalizedInputs
      ? product.personalizedInputs.reduce(
          (sum, input) => sum + (input.price || 0),
          0
        )
      : 0
    return productPrice + personalizedInputsPrice
  }

  const calculateGrandTotal = () => {
    return cart?.reduce((total, product) => {
      return total + calculateTotalPrice(product) * product.quantity
    }, 0)
  }

  return (
    <CardContainer>
      {cart?.map((product, index) => (
        <ProductItem key={index}>
          <span>{product.product.title}</span>
          <span style={{ color: 'black' }}>
            ${calculateTotalPrice(product)}
          </span>
        </ProductItem>
      ))}
      <GrandTotal>
        <span>Grand Total:</span>
        <span style={{ color: 'black' }}>${calculateGrandTotal()}</span>
      </GrandTotal>
      <ProceedButton
        onClick={() => navigate('/checkout')}
        disabled={cart.length <= 0}
      >
        Proceed to Payment
      </ProceedButton>
    </CardContainer>
  )
}

export default OrderDetails
