import axiosInstance from "../../utils/Axios/axiosConfig";

export const createSubscription = async (subscriptionData) => {
  const result = await axiosInstance.post("/subscriptions", subscriptionData);
  return result.data;
};

export const deleteSubscription = async (subscriptionId) => {
  const result = await axiosInstance.delete(`/subscriptions/${subscriptionId}`);
  return result.data;
};

export const fetchSubscriptions = async () => {
  const result = await axiosInstance.get(`/subscriptions`);
  return result.data;
};
