import React, { useState } from 'react'
import {
  FilterContainer,
  FilterHeader,
  FilterTitle,
  Filter,
  FilterType
} from './styles'
import { Button, Radio } from 'antd'

export default function SideFilter ({
  margin,
  setFilteredProducts,
  products = [],
  category,
  setCategory,
  priceRange,
  setPriceRange,
}) {
  const handleApplyFilter = () => {
    // Filter products based on category and price range
    let filteredProducts = products

    if (category !== "all") {
      filteredProducts = filteredProducts.filter(
        product => product?.categoryFilters?.type === category
      )
    }

    if (priceRange) {
      filteredProducts = filteredProducts.filter(product => {
        const productPrice = product?.price

        switch (priceRange) {
          case 1:
            return productPrice <= 50
          case 2:
            return productPrice > 50 && productPrice <= 100
          case 3:
            return productPrice > 100 && productPrice <= 200
          case 4:
            return productPrice > 200 && productPrice <= 500
          case 5:
            return productPrice > 500 && productPrice <= 1000
          case 6:
            return productPrice > 1000;
          case 7:
            return true;
          default:
            return false
        }
      })
    }

    // Set filtered products
    setFilteredProducts(filteredProducts)
  }

  return (
    <FilterContainer style={margin ? { marginTop: '8rem' } : {}}>
      <FilterHeader>
        <FilterTitle>Filters</FilterTitle>
        <Button type="primary" onClick={handleApplyFilter} style={{backgroundColor:'#d29e84'}}>
          Apply
        </Button>
      </FilterHeader>

      <FilterType>Categories</FilterType>
      <Radio.Group
        value={category}
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0 2rem'
        }}
        onChange={e => setCategory(e.target.value)}
      >
        <Filter value="all">All Products</Filter>
        <Filter value="For him">For Him</Filter>
        <Filter value="For her">For Her</Filter>
        <Filter value="For kids">For Kids</Filter>
      </Radio.Group>

      <FilterType>Price</FilterType>
      <Radio.Group
        value={priceRange}
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0 2rem'
        }}
        onChange={e => setPriceRange(e.target.value)}
      >
        <Filter value={7}>All Prices</Filter>
        <Filter value={1}>$0 - $50</Filter>
        <Filter value={2}>$51 - $100</Filter>
        <Filter value={3}>$101 - $200</Filter>
        <Filter value={4}>$201 - $500</Filter>
        <Filter value={5}>$501 - $1000</Filter>
        <Filter value={6}>Above $1000</Filter>
      </Radio.Group>
    </FilterContainer>
  )
}
