import React, { useEffect, useState } from "react";
import "./gifts.css";
import GiftsCollection from "../../components/GiftsCollection";
import SideFilter from "../../components/Filter";
import styled from "styled-components";
import MobileFilter from "../../components/Filter/MobileFilter";
import { useFetchProducts } from "../../hooks/Products/useFetchProducts";
import { Spin } from "antd";
import SanitizedHTML from "../../AdminPanel/Components/SanitizeHtml";
import desktopBanner from "../../assets/images/giftBox/desktop.jpeg";
import phoneBanner from "../../assets/images/giftBox/phone.jpeg";
import MetaTags from "../../components/MetaTags";

export const TextContainer = styled.div`
  position: absolute;
  top: 15%;
  left: 5%;
  @media only screen and (max-width: 1230px) {
    top: 18%;
  }
  @media only screen and (max-width: 1100px) {
    top: 22%;
  }
  @media only screen and (max-width: 500px) {
    top: 25%;
  }
`;
export const Heading = styled.h1`
  font-size: 54px;
  @media only screen and (max-width: 1230px) {
    font-size: 44px;
  }
  @media only screen and (max-width: 1100px) {
    font-size: 38px;
  }
  @media only screen and (max-width: 400px) {
    font-size: 32px;
  }
`;
export const Text = styled.div`
  font-size: 24px;
  border-left: 5px solid #d29e84;
  padding-left: 10px;
  @media only screen and (max-width: 1100px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 500px) {
    display: none;
  }
`;
export const PhoneText = styled.p`
  font-size: 14px;
  border-left: 5px solid #d29e84;
  padding-left: 10px;
  font-weight: 300;
  display: none;
  @media only screen and (max-width: 500px) {
    display: block;
  }
`;

export default function Gifts() {
  const { products: allProducts, isLoading } = useFetchProducts();
  const products = allProducts?.filter((prd) => !prd?.isAReadyMadeBox) || [];
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [category, setCategory] = useState("all");
  const [priceRange, setPriceRange] = useState(7);
  const subText =
    "Browse our curated selection of extraordinary<br/>gifts, meticulously chosen to make every<br/>ocassion special";
  const subTextPhone =
    "Browse our curated<br/>selection of extraordinary<br/>gifts, meticulously chosen<br/>to make every ocassion<br/>special";

  const Container = styled.div`
    display: flex;
    padding: 1rem;
    margin-top: 5rem;
    @media only screen and (min-width: 1440px) {
      margin-top: 100px;
    }
    @media only screen and (max-width: 780px) {
      flex-direction: column;
    }
  `;
  const GiftContainer = styled.div`
    flex: 8;
  `;

  useEffect(() => {
    setFilteredProducts(products);
    setCategory("all");
    setPriceRange(7);
  }, [allProducts]);
  console.log("filteredProducts", filteredProducts);
  return (
    <>
      <MetaTags
        title={"Gifts - Unveiling Treasures for every Moment"}
        description={
          " Browse our curated selection of extraordinary gifts at HeartsyBox. From timeless classics to unique wonders, find the perfect expression of your sentiments."
        }
      />
      <div className="gifts-top-image">
        <TextContainer>
          <Heading>
            <SanitizedHTML
              fontFam={"tangerin"}
              html={"Unwrap<br />The Perfect<br />Gift At<br />Heartsy Box"}
            />
          </Heading>
          <Text>
            <SanitizedHTML
              html={subText}
              fontFam={"gilroy-Light, sans-serif"}
            />
          </Text>
          <PhoneText>
            <SanitizedHTML
              html={subTextPhone}
              fontFam={"gilroy-Light, sans-serif"}
            />
          </PhoneText>
        </TextContainer>
        <img className="desktopBanner" src={desktopBanner} />
        <img className="phoneBanner" src={phoneBanner} />
      </div>
      <Container>
        <MobileFilter
          setFilteredProducts={setFilteredProducts}
          products={products}
          category={category}
          setCategory={setCategory}
          priceRange={priceRange}
          setPriceRange={setPriceRange}
        />
        <SideFilter
          margin={"5rem"}
          setFilteredProducts={setFilteredProducts}
          products={products}
          category={category}
          setCategory={setCategory}
          priceRange={priceRange}
          setPriceRange={setPriceRange}
        />
        <GiftContainer>
          {isLoading && <Spin fullscreen />}
          {!isLoading && (
            <GiftsCollection
              btnTitle={"Her"}
              category={true}
              products={filteredProducts.sort((a, b) => {
                const dateA = a.createdAt ? new Date(a.createdAt) : new Date(0);
                const dateB = b.createdAt ? new Date(b.createdAt) : new Date(0);
                return dateB.getTime() - dateA.getTime();
              })}
            />
          )}
        </GiftContainer>
      </Container>
    </>
  );
}
