import axiosInstance from "../../utils/Axios/axiosConfig";

export const fetchOrders = async () => {
  const result = await axiosInstance.get("/orders");
  return result.data;
};

export const fetchOrder = async (id) => {
  const result = await axiosInstance.get("/orders/" + id);
  return result.data;
};

export const createOrder = async (orderData) => {
  const result = await axiosInstance.post("/orders", orderData);
  return result.data;
};
