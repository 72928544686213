import styled from 'styled-components'

export const Container = styled.div`
  color: #141414;
  font-family: IBM Plex Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2rem;
`
