import axiosInstance from "../../utils/Axios/axiosConfig";

export const createCheckoutSession = async (data) => {
  const result = await axiosInstance.post("/stripe/create-session", data);
  return result.data;
};

export const checkSessionStatus = async (session_id) => {
  const result = await axiosInstance.get(
    "stripe/check-session-status/" + session_id
  );
  return result.data;
};
