import { useQuery } from "@tanstack/react-query";
import { fetchOrder } from "../../services/Orders/orderApi";

export const useOrder = (id) => {
  const {
    data: order,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ["order", id],
    queryFn: () => (id ? fetchOrder(id) : null),
  });
  return {
    order,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};
