import { useQuery } from "@tanstack/react-query";
import { fetchProducts } from "../../services/Products/productsApi";
export const useFetchProducts = () => {
  const {
    data: products,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useQuery({ queryKey: ["products"], queryFn: fetchProducts });
  return {
    products,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};
