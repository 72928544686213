import { useQuery } from "@tanstack/react-query";
import { fetchSubscriptions } from "../../services/Subscription/subscriptionApi";

export const useFetchSubscriptions = () => {
  const {
    data: subscriptions,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useQuery({ queryKey: ["subscriptions"], queryFn: fetchSubscriptions });
  return {
    subscriptions,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};
