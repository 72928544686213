import styled from 'styled-components'

export const Container = styled.div`
  display: flex;

  gap: 2rem;
  width: 100%;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
`
export const CardHeader = styled.div``
export const OuterContainer = styled.div`
  padding: 1rem 3rem;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
