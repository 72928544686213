// axiosConfig.js
import axios from "axios";
import { getUserToken } from "../LocalStorage/localstorage";

const axiosInstance = axios.create({
  baseURL: "https://heartsybox.com/api",
  //baseURL: "http://localhost:5000/api",

  headers: {
    common: {
      Authorization: `${getUserToken()}`,
    },
  },
});

// will gonna use following if there will be exception where we will need to manually set header type
// // Set default headers
// axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

// // Add an interceptor to handle FormData requests
// axiosInstance.interceptors.request.use((config) => {
//   if (config.headers['Content-Type'] === 'multipart/form-data') {
//     // Adjust headers for FormData
//     delete config.headers['Content-Type']; // Remove the default Content-Type
//   }
//   return config;
// });

export default axiosInstance;
