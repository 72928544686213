import styled from 'styled-components'
import { Button } from 'antd'
import { MenuOutlined } from '@ant-design/icons'

export const MenuIcon = styled(MenuOutlined)`
  display: none;
  @media (max-width: 768px) {
    display: block;
    color: #6c5ffc;
  }
`

export const IconContainer = styled.div`
  position: relative;
`

export const ResponsiveMenu = styled.div`
  position: absolute !important;
  left: -2rem;
  min-height: 100vh;
  top: 2rem;
  z-index: 100;
  display: ${props => (props.showMenu ? 'block' : 'none')};
  background-color: white;
`

export const SecButton = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0 2rem;
  cursor: pointer;
  font-size: 16px;
  ${props => props.active && `border-bottom: 3px solid #0548dd;`}
  color: black;
  @media (max-width: 480px) {
    margin: 0;
  }
`

export const Container = styled.div`
  @media (max-width: 480px) {
    display: none;
  }
  position: relative;
  display: flex;
  padding: 1.5rem 2rem;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  // color: white;
  // background-color: #1677ff;
`

export const MobileContainer = styled.div`
  display: none;
  @media (max-width: 480px) {
    display: block;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    font-size: 14px;
    background-color: white;
  }
`
export const LogoContainer = styled.div`
  background-image: url(${props => props.logo});
  width: 132px;
  height: 38px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
  // @media (min-width: 768px) {
  //   display: none;
  // }
  @media (max-width: 480px) {
    width: 100px;
  }
`
