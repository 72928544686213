import React from 'react'
import SubscriptionList from '../../../Components/SubscriptionList'
import PageContent from '../../../Components/PageContent'
import PageHeading from '../../../Components/PageHeading'
import { useNavigate } from 'react-router-dom'

export default function Subscriptions () {
  const navigate = useNavigate()
  return (
    <PageContent>
      <PageHeading heading={'Subscription Management'} />
      <SubscriptionList />
    </PageContent>
  )
}
