import React from 'react'
import { AntdDropdown, MenuButton, DesktopMenu, MobileMenu } from './styles'
import {
  ExportOutlined,
  DeleteOutlined,
  CheckCircleFilled,
  CloseCircleFilled
} from '@ant-design/icons'
import {
  SettingOutlined,
  UserOutlined,
  LogoutOutlined
} from '@ant-design/icons'
import { Avatar } from 'antd'
import TextWithIcon from '../TextWithIcon'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { clearUserToken } from '../../../../utils/LocalStorage/localstorage'

export default function DropDown ({
  header,
  id,
  state,
  onVerify,
  onDelete,
  currentPage,
  email,
  placement
}) {
  const navigate = useNavigate()
  const logout = () => {
    clearUserToken()
    // navigate("/admin/products");
  }
  const items = !header
    ? [
        {
          key: 1,
          label: (
            <Link to={`/user/${id}?page=${currentPage}`}>
              <TextWithIcon
                text={'View'}
                icon={<ExportOutlined />}
                color={'#74829C'}
              />
            </Link>
          )
        },
        {
          key: 2,
          label: (
            <TextWithIcon
              text={'Delete'}
              icon={<DeleteOutlined />}
              color={'#E94E51'}
              onClick={() => onDelete(email)}
            />
          )
        },
        {
          key: 3,
          label: state ? (
            <TextWithIcon
              text={'Not Verified'}
              icon={<CloseCircleFilled />}
              color={'#E94E51'}
              onClick={() => onVerify(email, false)}
            />
          ) : (
            <TextWithIcon
              text={'Verify'}
              icon={<CheckCircleFilled />}
              color={'#01AB3B'}
              onClick={() => onVerify(email, true)}
            />
          )
        }
      ]
    : [
        {
          key: 1,
          label: (
            <a onClick={logout} href='/admin/login'>
              <TextWithIcon
                text={'Logout'}
                icon={<LogoutOutlined />}
                color={'#74829C'}
              />
            </a>
          )
        }
      ]

  return (
    <AntdDropdown menu={{ items }}>
      <MenuButton>
        <Avatar size='middle' icon={<UserOutlined />} />
      </MenuButton>
    </AntdDropdown>
  )
}
