import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  padding: 5rem 0;
`
export const Header = styled.div`
  wish: 100%;
`
export const OptionsRow = styled.div`
  display: flex;
  width: 100%;
  padding: 2rrem;
  justify-content: space-around;
`
export const Option = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
`
export const Selectable = styled.div`
  width: 200px;
  height: 200px;
  cursor: pointer;
  @media only screen and (max-width: 460px) {
    max-width: 100px;
    max-height: 100px;
  }
`
