import React from "react";
import { Container, Header, Option, Selectable } from "../Q1/styles";
import { Question } from "../../styles";
import GiftsCollection from "../../../GiftsCollection";

export default function Recommended({ products }) {
  return (
    <Container>
      <Header>
        <Question>
          Based on your answers, your giftee would love these gifts
        </Question>
      </Header>
      <div>
        <GiftsCollection suggestion={true} products={products} />
      </div>
    </Container>
  );
}
