// Step 1: Convert Images to Base64
export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

// // Step 2: Store Base64 Strings in localStorage
// export const storeImagesInLocalStorage = async (images) => {
//   const base64Array = await Promise.all(
//     images.map((image) => convertToBase64(image))
//   );
//   localStorage.setItem("imageData", JSON.stringify(base64Array));
// };

// // Step 3: Retrieve Base64 Strings from localStorage
// export const getImagesFromLocalStorage = () => {
//   const storedData = localStorage.getItem("imageData");
//   return storedData ? JSON.parse(storedData) : [];
// };

// Step 4: Convert Base64 Strings back to Files
export const convertBase64ToFiles = (base64Array) => {
  return base64Array.map((base64String, index) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/png" }); // Adjust the type based on your image format

    return new File([blob], `image_${index}.png`, { type: "image/png" });
  });
};
