import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Select,
  Spin,
  message,
} from "antd";
import { Row, Divider } from "./styles";
import ImagesUploadWithColor from "../../ImageUploadWithColor/imageUploadWithColor";
import VariationField from "../../UIKit/VariationFields";

import { useCreateProduct } from "../../../../hooks/Products/useCreateProduct";
import { useNavigate } from "react-router-dom";
import { useFetchProducts } from "../../../../hooks/Products/useFetchProducts";
import { useUpdateProduct } from "../../../../hooks/Products/useUpdateProduct";
import { useProduct } from "../../../../hooks/Products/useProduct";
import { MdDelete } from "react-icons/md";
import { generate, green, presetPalettes, red } from "@ant-design/colors";
import { ColorPicker, theme } from "antd";
import chroma from "chroma-js";
import QuillEditor from "../../UIKit/TextEditor";
import { useFetchCategories } from "../../../../hooks/category/useFetchCategories";

export default function AddGift({ edit, product }) {
  // ColorPicker Secifications
  const genPresets = (presets = presetPalettes) =>
    Object.entries(presets).map(([label, colors]) => ({
      label,
      colors,
    }));

  const { token } = theme.useToken();
  const presets = genPresets({
    primary: generate(token.colorPrimary),
    red,
    green,
  });
  //Color Picker Specifications
  const {
    isLoading: isCreatingProduct,
    isSuccess,
    data,
    mutate,
    error,
  } = useCreateProduct();
  const { refetch: refetchCurrentProductOnUpdate } = useProduct(
    product ? product?._id : null
  );
  const {
    mutate: updateProduct,
    data: updatedProduct,
    error: updateProductError,
    isLoading: isUpdatingProduct,
  } = useUpdateProduct();
  const { refetch } = useFetchProducts();
  const [form] = Form.useForm();
  const [rows, setRows] = useState(
    [...Array(product?.personalizedKeys?.length || 0).keys()].map((id) => ({
      id,
    }))
  );

  // const [variationRows, setVariationRows] = useState(0)
  const [colorQty, setColorQty] = useState(0);
  const navigate = useNavigate();
  const [customIsLoading, setCustomIsLoading] = useState(false);
  const { categories } = useFetchCategories();
  const [variationRows, setVariationRows] = useState(0);
  const [subfieldCounts, setSubfieldCounts] = useState([]);

  const handleAddVariation = () => {
    setVariationRows(variationRows + 1);
    setSubfieldCounts((prevCounts) => [...prevCounts, 0]);
  };

  const [checkboxState, setCheckboxState] = useState({
    buildABox1: false,
    buildABox2: false,
    buildABox3: false,
    buildABox4: false,
    buildABox4EnvelopeType: false,
    buildABox4PaperType: false,
    homepage: false,
    forHim: false,
    forHer: false,
    forKids: false,
    shouldIgnoreColors: false,
  });

  const onCheckboxChange = (e) => {
    const { name, checked } = e.target;

    //this is handling to only select one from envelop type or paper type
    if (["buildABox4EnvelopeType", "buildABox4PaperType"].includes(name)) {
      setCheckboxState({
        ...checkboxState,
        buildABox4EnvelopeType: false,
        buildABox4PaperType: false,
        [name]: checked,
      });
    } else {
      setCheckboxState({
        ...checkboxState,
        [name]: checked,
      });
    }
  };
  const handleAddField = () => {
    const newId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
    setRows([...rows, { id: newId }]);
  };

  const onFinish = async (values) => {
    console.log("values", values);
    const formData = new FormData();
    const images = JSON.stringify(form.getFieldValue("images") || []);
    formData.append("imagesWithColors", images);

    // Basic fields
    formData.append("title", values.name);
    formData.append("description", values.description);
    formData.append("price", values.price);

    // Scopes - updated with checkboxState
    let scopes = [];
    Object.keys(checkboxState).forEach((key) => {
      if (checkboxState[key] && key !== "shouldIgnoreColors") {
        scopes.push(key);
      }
      //no colors logic
      if (key === "shouldIgnoreColors" && checkboxState[key]) {
        formData.append("shouldIgnoreColors", true);
      } else if (key === "shouldIgnoreColors" && !checkboxState[key]) {
        formData.append("shouldIgnoreColors", false);
      }
    });
    formData.append("scopes", JSON.stringify(scopes));

    // Personalized Keys - unchanged
    let personalizedKeys = [];
    let imageFieldCount = 0;

    for (let i = 0; i < rows.length; i++) {
      personalizedKeys.push({
        label: values[`personalizedFieldName${rows[i]?.id}`],
        count: values[`personalizedFieldLetters${rows[i]?.id + 1}`],
        price: values[`personalizedFieldCost${rows[i]?.id + 1}`],
      });
      if (personalizedKeys[i].label === "image") {
        imageFieldCount++;
      }
    }
    console.log("personzlizedKeys", personalizedKeys);

    if (imageFieldCount > 1) {
      message.error(
        "Image Field in personalized items is more than one. Only 1 is allowed"
      );
      return;
    }

    formData.append("personalizedKeys", JSON.stringify(personalizedKeys));

    //phase2
    const categoryKeys = Object.keys(values).filter((val) =>
      val.startsWith("cat")
    );
    const categoryData = categoryKeys.map((cK) => ({
      category: cK.split("-")[1],
      values: values[cK],
    }));

    formData.append("categories", JSON.stringify(categoryData));

    //variations
    const variations = Object.keys(values)
      .filter((v) => v.startsWith("variationFieldName"))
      .map((variationKey) => ({
        variation: values[variationKey],
        subVariations: Object.keys(values)
          .filter(
            (subV) =>
              subV.endsWith(variationKey) && !subV.startsWith(variationKey)
          )
          .map((subVKey) => values[subVKey])
          //filterting empty sub values
          .filter((subVal) => !!subVal),
      }));
    const filteredEmptyVariations = variations.filter(
      (v) => v?.variation && v?.subVariations?.length > 0
    );

    formData.append("variations", JSON.stringify(filteredEmptyVariations));

    if (edit) {
      setCustomIsLoading(true);
      updateProduct({ id: product._id, data: formData });
    } else {
      setCustomIsLoading(true);
      mutate(formData);
    }
  };
  useEffect(() => {
    if (isSuccess) {
      setCustomIsLoading(false);
      message.success("Product Uploaded Successfully");
      refetch();
      navigate("/admin/products");
    }
    if (error) {
      setCustomIsLoading(false);
      message.error("An Error Occurred");
    }
  }, [error, isSuccess, navigate, refetch, setCustomIsLoading]);

  useEffect(() => {
    if (updatedProduct) {
      message.success(`${updatedProduct.title} is successfully updated`);
      setCustomIsLoading(false);
      refetch();
      refetchCurrentProductOnUpdate();
      navigate("/admin/products");
    }

    if (updateProductError) {
      setCustomIsLoading(false);
      message.error(
        updateProductError?.response?.data?.error || "Product Update Failed"
      );
    }
  }, [
    updatedProduct,
    updateProductError,
    refetchCurrentProductOnUpdate,
    refetch,
    navigate,
    setCustomIsLoading,
  ]);

  const transformProductToFormValues = (product) => {
    const formValues = {
      name: product.title,
      description: product.description,
      price: product.price,
      images: product.imagesWithColors,
    };

    // Add dynamic keys and values
    product.personalizedKeys.forEach((item, index) => {
      formValues[`personalizedFieldName${index}`] = item.label;
      formValues[`personalizedFieldLetters${index + 1}`] = item.count;
      formValues[`personalizedFieldCost${index + 1}`] = item.price;
    });
    //phase 2 part below
    let tempSubVariationCount = [];
    product?.variations.forEach((item, index) => {
      formValues[`variationFieldName${index}`] = item.variation;

      tempSubVariationCount = [
        ...tempSubVariationCount,
        item.subVariations.length,
      ];
      item.subVariations.forEach((subV, subVIndex) => {
        formValues[`SubFieldName${subVIndex}-variationFieldName${index}`] =
          subV;
      });
    });
    //variation rows = product.variations.length
    setVariationRows(product.variations.length);
    setSubfieldCounts(tempSubVariationCount);

    //selected sub categories fill
    product?.categories && product?.categories.forEach((item) => {
      if(item?.category?._id){
      formValues[`cat-${item.category._id}`] = item.values;
      }
    });

    return formValues;
  };
  useEffect(() => {
    if (edit && product) {
      const initalValues = product ? transformProductToFormValues(product) : {};
      setCheckboxState((prevCheckboxState) => ({
        ...prevCheckboxState,
        ...product.scopes.reduce(
          (acc, value) =>
            checkboxState[value] ? acc : { ...acc, [value]: true },
          {}
        ),
        shouldIgnoreColors: product.shouldIgnoreColors ? true : false,
      }));

      form.setFieldsValue(initalValues);
    }
  }, [edit, product, form]);

  const handlePersonalizedValueDelete = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  return (
    <>
      {customIsLoading && <Spin fullscreen />}
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        style={{ flex: 2 }}
      >
        <Form.Item
          name="name"
          label="Product Name"
          rules={[{ required: true }]}
        >
          <Input placeholder="Product Name" />
        </Form.Item>

        <Form.Item
          label="Product Description"
          name="description"
          rules={[{ required: true }]}
        >
          {/* <TextArea rows={6} placeholder='Description' /> */}
          <QuillEditor />
        </Form.Item>
        <Form.Item style={{ width: "100%" }}>
          <Checkbox
            name="shouldIgnoreColors"
            checked={checkboxState.shouldIgnoreColors}
            onChange={onCheckboxChange}
          >
            Ignore Colors
          </Checkbox>
        </Form.Item>
        <Form.Item name="images" label="Upload Images">
          <ImagesUploadWithColor
            shouldIgnoreColors={checkboxState?.shouldIgnoreColors}
          />
        </Form.Item>
        <Row>
          <Form.Item
            name="price"
            label="Price"
            style={{ width: "100%" }}
            rules={[{ required: true }]}
          >
            <Input placeholder="Product Price" />
          </Form.Item>
        </Row>

        <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
          {Array.from({ length: colorQty }).map((_, index) => (
            <div style={{ display: "flex" }}>
              <Form.Item
                key={index}
                label={`Color- ${index + 1}`}
                name={`color-${index}`}
                rules={[{ required: true }]}
              >
                <ColorPicker
                  presets={presets}
                  defaultValue={product?.color[index] || "#1677ff"}
                />
              </Form.Item>
              {/* Delete button added */}

              <Button
                style={{
                  border: "none",
                  boxShadow: "0 0 0 0",
                  padding: 0,
                  marginTop: "-5px",
                  color: "red",
                }}
              >
                <MdDelete />
              </Button>
            </div>
          ))}
        </div>
        <Divider>Category Options</Divider>

        {categories &&
          categories.map((cat) => (
            <Form.Item label={cat.name} name={`cat-${cat._id}`}>
              <Select
                options={cat.values.map((c) => ({ value: c, label: c }))}
                mode="multiple"
              />
            </Form.Item>
          ))}

        <Divider>Personalization Options</Divider>
        <Form.Item name={"Personalization"}>
          {rows.map((_, index) => (
            <Row key={_.id}>
              <Form.Item
                style={{ width: "100%" }}
                name={`personalizedFieldName${_.id}`}
              >
                <Input placeholder={`Field Name`} />
              </Form.Item>
              <Form.Item
                style={{ width: "50%" }}
                name={`personalizedFieldCost${_.id + 1}`}
              >
                <Input placeholder={`Field Cost`} />
              </Form.Item>
              <Form.Item
                style={{ width: "50%" }}
                name={`personalizedFieldLetters${_.id + 1}`}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder={`Max Letters`}
                />
              </Form.Item>
              {/* Delete button added */}
              <Form.Item>
                <Button
                  style={{ color: "red" }}
                  onClick={() => handlePersonalizedValueDelete(_.id)}
                >
                  <MdDelete />
                </Button>
              </Form.Item>
            </Row>
          ))}
          <Form.Item>
            <Button type="dashed" onClick={handleAddField}>
              Add Field
            </Button>
          </Form.Item>
        </Form.Item>
        {/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
        <Divider>Variation Options</Divider>
        <Form.Item>
          <Button type="dashed" onClick={handleAddVariation}>
            Add Field
          </Button>
        </Form.Item>
        <Form.Item>
          {[...Array(variationRows)].map((_, index) => (
            <VariationField
              key={index}
              index={index}
              subfieldCount={subfieldCounts[index]}
              setSubfieldCount={(count) =>
                setSubfieldCounts((prevCounts) =>
                  prevCounts.map((prevCount, i) =>
                    i === index ? count : prevCount
                  )
                )
              }
            />
          ))}
        </Form.Item>
        {/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
        <Divider>Build-a-Box Options</Divider>

        <Row>
          <Form.Item name="buildBoxStep1_render" style={{ width: "100%" }}>
            <Checkbox
              name="buildABox1"
              checked={checkboxState.buildABox1}
              onChange={onCheckboxChange}
            >
              Show on Step 1
            </Checkbox>
          </Form.Item>
          <Form.Item name="buildBoxStep2_render" style={{ width: "100%" }}>
            <Checkbox
              name="buildABox2"
              checked={checkboxState.buildABox2}
              onChange={onCheckboxChange}
            >
              Show on Step 2
            </Checkbox>
          </Form.Item>
          <Form.Item name="buildBoxStep3_render" style={{ width: "100%" }}>
            <Checkbox
              name="buildABox3"
              checked={checkboxState.buildABox3}
              onChange={onCheckboxChange}
            >
              Show on Step 3
            </Checkbox>
          </Form.Item>
          <Form.Item name="buildBoxStep4_render" style={{ width: "100%" }}>
            <Checkbox
              name="buildABox4"
              checked={checkboxState.buildABox4}
              onChange={onCheckboxChange}
            >
              Show on Step 4
            </Checkbox>
          </Form.Item>
        </Row>

        <Row>
          <Form.Item name="paperType" label="Type" style={{ width: "100%" }}>
            <Checkbox
              name="buildABox4PaperType"
              checked={checkboxState.buildABox4PaperType}
              onChange={onCheckboxChange}
              disabled={!checkboxState.buildABox4}
            >
              Paper Type
            </Checkbox>
          </Form.Item>
          <Form.Item name="envelopeType" label=" " style={{ width: "100%" }}>
            <Checkbox
              name="buildABox4EnvelopeType"
              checked={checkboxState.buildABox4EnvelopeType}
              onChange={onCheckboxChange}
              disabled={!checkboxState.buildABox4}
            >
              Envelope Type
            </Checkbox>
          </Form.Item>
        </Row>

        <Divider>Publish Options</Divider>

        <Row>
          <Form.Item name="homePage_render" style={{ width: "100%" }}>
            <Checkbox
              name="homepage"
              checked={checkboxState.homepage}
              onChange={onCheckboxChange}
            >
              Show on HomePage
            </Checkbox>
          </Form.Item>
          <Form.Item name="quiz_render" style={{ width: "100%" }}>
            <Checkbox
              name="quiz"
              checked={checkboxState.quiz}
              onChange={onCheckboxChange}
            >
              Show in Quiz
            </Checkbox>
          </Form.Item>
        </Row>

        {edit ? (
          <Row style={{ justifyContent: "flex-start" }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Changes
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="primary">Cancel</Button>
            </Form.Item>
          </Row>
        ) : (
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Product
            </Button>
          </Form.Item>
        )}
      </Form>
    </>
  );
}
