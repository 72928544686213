import React, { useState } from 'react'
import { AntInput, AntPasswordInput } from './styles'
import { Form } from 'antd'
import PrimaryButton from '../UIKit/Buttons/Primary'
import { useNavigate } from 'react-router-dom'
import { Alert } from 'antd'

export default function LoginComponent ({ onSubmit, form }) {
  const navigate = useNavigate()
  const [error, setError] = useState()

  const onFormSubit = async () => {
    const values = await form.validateFields()
    onSubmit(values, setError)
  }

  return (
    <>
      <Form style={{ width: '100%' }} layout='vertical' form={form}>
        <Form.Item label='Enter Your Email' name='email'>
          <AntInput placeholder='Email' autoComplete='off' />
        </Form.Item>
        <Form.Item label='Enter Your Password' name='password'>
          <AntPasswordInput
            placeholder='Enter Your Password'
            autoComplete='off'
          />
        </Form.Item>
        {error && (
          <Alert message={error + '. Please try again'} type='error' showIcon />
        )}
      </Form>

      <PrimaryButton text={'Sign In'} onClick={onFormSubit} />
    </>
  )
}
