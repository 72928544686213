import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 0 25rem;

  @media only screen and (max-width: 1440px) {
    padding:0 10rem;
  }

  @media only screen and (max-width: 1000px) {
    padding:0 5rem;
  }

  @media only screen and (max-width: 700px) {
    padding:0;
  }
`
export const InfoContainer = styled.div`
  display: flex;

  @media only screen and (max-width: 670px) {
    flex-direction: column;
  }
`
export const SuggestionsContainer = styled.div`
  width: 100%;
`
