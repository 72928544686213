import React from 'react'
import BlogsList from '../../../Components/BlogsList'
import PageContent from '../../../Components/PageContent'
import PageHeading from '../../../Components/PageHeading'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import PrimaryButton from '../../../Components/UIKit/Buttons/Primary'
import CategoriesList from '../../../Components/CategoriesList'

export default function Categories () {
  const navigate = useNavigate()

  return (
    <PageContent>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          position: 'absolute',
          right: '10rem',
          top: '4rem'
        }}
      >
        <PrimaryButton
          text={'Add New'}
          icon={<PlusOutlined />}
          simple={true}
          responsive={true}
          onClick={() => navigate('/admin/categories/new')}
        />
      </div>
      <PageHeading heading={'Categories'} />
      <CategoriesList />
    </PageContent>
  )
}
