import React from "react";
import PageContent from "../../../Components/PageContent";
import PageHeading from "../../../Components/PageHeading";
import AddProductComponent from "../../../Components/AddProduct";
import { useParams } from "react-router-dom";
import { useProduct } from "../../../../hooks/Products/useProduct";

export default function EditProduct() {
  const { id } = useParams();
  const { product, isError, isSuccess } = useProduct(id);
  return (
    <PageContent>
      <PageHeading heading={"Edit Product"}></PageHeading>
      {product && <AddProductComponent edit={true} product={product} />}
    </PageContent>
  );
}
