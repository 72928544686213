import React, { useState } from 'react'
import { Container, Header, Option, Selectable } from '../Q1/styles'
import { OptionBody } from '../Q2/styles'
import { Question, OptionText } from '../../styles'
import male50 from '../../../../assets/images/quiz/male 50.png'
import male100 from '../../../../assets/images/quiz/male 100.png'
import male200 from '../../../../assets/images/quiz/male 200.png'
import male500 from '../../../../assets/images/quiz/male 500.png'
import male1000 from '../../../../assets/images/quiz/male 1000.png'
import maleanyprice from '../../../../assets/images/quiz/male any price.png'
//female
import female50 from '../../../../assets/images/quiz/female 50.png'
import female100 from '../../../../assets/images/quiz/female 100.png'
import female200 from '../../../../assets/images/quiz/female 200.png'
import female500 from '../../../../assets/images/quiz/female 500.png'
import female1000 from '../../../../assets/images/quiz/female 1000.png'
import femaleanyprice from '../../../../assets/images/quiz/female any price.png'

export default function Q7 ({ setStep, value = null, setValue, she }) {
  const [isClicked, setIsClicked] = useState(value)

  const handleClick = val => {
    setIsClicked(val)
    setValue(val)
    setStep(7)
  }
  return (
    <Container>
      <Header>
        <Question>What’s your budget for this gift?</Question>
      </Header>
      <OptionBody>
        <Option>
          <Selectable
            style={
              isClicked === 50
                ? { border: '4px solid #d29e84', borderRadius: '5px' }
                : { backgroundColor: '#d29e84' }
            }
            onClick={() => handleClick(50)}
          >
            <img src={she ? female50 : male50} style={{ width: '100%' }} />
          </Selectable>
          <OptionText>Up to $50</OptionText>
        </Option>
        <Option>
          <Selectable
            style={
              isClicked === 100
                ? { border: '4px solid #d29e84', borderRadius: '5px' }
                : { backgroundColor: '#d29e84' }
            }
            onClick={() => handleClick(100)}
          >
            <img src={she ? female100 : male100} style={{ width: '100%' }} />
          </Selectable>
          <OptionText>Up to $100</OptionText>
        </Option>
        <Option>
          <Selectable
            style={
              isClicked === 200
                ? { border: '4px solid #d29e84', borderRadius: '5px' }
                : { backgroundColor: '#d29e84' }
            }
            onClick={() => handleClick(200)}
          >
            <img src={she ? female200 : male200} style={{ width: '100%' }} />
          </Selectable>
          <OptionText>Up to $200</OptionText>
        </Option>
        <Option>
          <Selectable
            style={
              isClicked === 500
                ? { border: '4px solid #d29e84', borderRadius: '5px' }
                : { backgroundColor: '#d29e84' }
            }
            onClick={() => handleClick(500)}
          >
            <img src={she ? female500 : male500} style={{ width: '100%' }} />
          </Selectable>
          <OptionText>Up to $500</OptionText>
        </Option>
        <Option>
          <Selectable
            style={
              isClicked === 1000
                ? { border: '4px solid #d29e84', borderRadius: '5px' }
                : { backgroundColor: '#d29e84' }
            }
            onClick={() => handleClick(1000)}
          >
            <img src={she ? female1000 : male1000} style={{ width: '100%' }} />
          </Selectable>
          <OptionText>Up to $1000</OptionText>
        </Option>
        <Option>
          <Selectable
            style={
              isClicked === 'Any Price'
                ? { border: '4px solid #d29e84', borderRadius: '5px' }
                : { backgroundColor: '#d29e84' }
            }
            onClick={() => handleClick('Any Price')}
          >
            <img
              src={she ? femaleanyprice : maleanyprice}
              style={{ width: '100%' }}
            />
          </Selectable>
          <OptionText>Any Price</OptionText>
        </Option>
      </OptionBody>
    </Container>
  )
}
