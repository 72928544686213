import React from 'react'
import { Container, LeftContainer, RightContainer, Image } from './styles'
import img1 from '../../../assets/images/giftPicker/img2.jpg'
import { SecondaryHeading, Text } from '../styles'

export default function Section22 () {
  return (
    <Container>
      <RightContainer>
        <SecondaryHeading>
          Who’s Heartsy Box
          <br /> GiftPicker for?
        </SecondaryHeading>
        <Text>
          Spend less time finding the perfect gift and more
          <br /> time celebrating with those you love.
        </Text>
      </RightContainer>
      <LeftContainer>
        <Image src={img1} />
      </LeftContainer>
    </Container>
  )
}
