import React, { useState } from "react";
import { Container, Image, Title, Price, InfoContainer } from "./styles";
import ProductCustomizationModal from "../../Modal/ProductCustomizationModal";
import { Tooltip } from "antd";

export default function ProdCard({
  data,
  handleProductSelection,
  nextStep,
  updateData,
}) {
  const [visible, setVisible] = useState(false);
  return (
    <Container onClick={() => setVisible(true)}>
      <ProductCustomizationModal
        show={visible}
        // setShow={setVisible}
        item={data}
        handleProductSelection={handleProductSelection}
        nextStep={nextStep}
        updateData={updateData}
      />
      <Image src={data.imagesWithColors[0]?.src} />
      <InfoContainer>
        <Tooltip title={data?.title}>{data?.title}</Tooltip>
      </InfoContainer>
      <InfoContainer>
        <Price>${data?.price}</Price>
      </InfoContainer>
    </Container>
  );
}
