import { useQuery } from "@tanstack/react-query";
import { fetchBlogs } from "../../services/Blogs/blogsApi";

export const useFetchBlogs = () => {
  const {
    data: blogs,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useQuery({ queryKey: ["blogs"], queryFn: fetchBlogs });

  return {
    blogs,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};
