import React from 'react'
import PageContent from '../../../Components/PageContent'
import PageHeading from '../../../Components/PageHeading'
import AddProductComponent from '../../../Components/AddProduct'

export default function AddProduct () {
  return (
    <PageContent>
      <PageHeading heading={'Add Product'}></PageHeading>
      <AddProductComponent/>
    </PageContent>
  )
}
