import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { ColorPicker, Spin, message, theme } from "antd";
import { Alert } from "antd";
import { useUploadImage } from "../../../hooks/cloudinary/useUploadImage";
import { generate, green, presetPalettes, red } from "@ant-design/colors";
import chroma from "chroma-js";

const ImagesUploadWithColor = ({
  value = [],
  onChange,
  readonly = false,
  shouldIgnoreColors,
}) => {
  const { mutate, isLoading, error, data, isSuccess } = useUploadImage();
  const [messageApi, contextHolder] = message.useMessage();
  const [customLoader, setCustomLoader] = useState(false);
  const [color, setColor] = useState("");

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if ((!color || color?.length < 1) && !shouldIgnoreColors) {
        message.error("Please Select Color Variation First");
        return;
      }
      if (acceptedFiles.length > 1) {
        messageApi.open({
          type: "error",
          content:
            "Please upload only iamge file and it should be less than 2 MB",
        });
        return;
      }

      const newImages = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      //as we have allow only 1 image so it will be on index 0
      const formData = new FormData();
      formData.append("image", newImages[0]);
      mutate(formData);
      setCustomLoader(true);
    },
    [color, value]
  );

  const removeImage = (index, event) => {
    event.preventDefault();

    const updatedImages = [...value];
    updatedImages.splice(index, 1);
    onChange(updatedImages);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
    maxFiles: 1,
    maxSize: 2 * 1024 * 1024,
  });

  useEffect(() => {
    if (isSuccess) {
      onChange([...value, { color, src: data.imageUrl || "" }]);
      setCustomLoader(false);
      setColor("");
    }
    if (error) {
      message.error("Error Uploading Image To Cloudinary");
      setCustomLoader(false);
    }
  }, [isSuccess, error, data]);

  // ColorPicker Secifications
  const genPresets = (presets = presetPalettes) =>
    Object.entries(presets).map(([label, colors]) => ({
      label,
      colors,
    }));

  const { token } = theme.useToken();
  const presets = genPresets({
    primary: generate(token.colorPrimary),
    red,
    green,
  });
  //Color Picker Specifications

  const onColorPick = (e) => {
    const colorHSV = e.metaColor.originalInput;
    if (typeof colorHSV === "object") {
      const hexColor = chroma.hsv(colorHSV.h, colorHSV.s, colorHSV.v).hex();
      setColor(hexColor);
    } else if (typeof colorHSV === "string") {
      setColor(colorHSV);
    }
  };
  console.log("shouldIgnoreColors", shouldIgnoreColors);

  return (
    <Container>
      {customLoader && <Spin fullscreen />}
      {!readonly && (
        <DropzoneContainer {...getRootProps()}>
          <input {...getInputProps()} />
          <DropzoneText>
            <>
              <p>Drag & drop here, or click to select</p>
              <p>
                {" "}
                <span style={{ color: "red" }}>*</span>Only a single file is
                allowed.
              </p>
            </>
          </DropzoneText>
        </DropzoneContainer>
      )}
      <ImageContainer>
        {value.map((data, index) => (
          <ImagePreview key={index}>
            <Image src={data.src} alt={`Preview ${index}`} />
            {!readonly && (
              <RemoveButton onClick={(e) => removeImage(index, e)}>
                x
              </RemoveButton>
            )}
          </ImagePreview>
        ))}
      </ImageContainer>
      <ColorPicker
        presets={presets}
        defaultValue={"#1677ff"}
        onChange={onColorPick}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DropzoneContainer = styled.div`
  border: 2px dashed gray;
  // background-color: lightgray;
  color: gray;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
`;

const DropzoneText = styled.p`
  margin: 0;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ImagePreview = styled.div`
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const Image = styled.img`
  width: 75px;
  height: 75px;
  object-fit: cover;
  margin-right: 5px;
  margin-bottom: 5px;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: -5px;
  padding: 0 !important;
  right: 7px;
  color: black;
  border: none;
  cursor: pointer;
  background-color: transparent;
`;
// };

export default ImagesUploadWithColor;
