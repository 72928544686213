import React, { useState } from 'react'
import Step3SliderComponent from './Step3SIiderComponent'
import { Form } from 'react-bootstrap'
import './step4.css'
import { useFetchProducts } from '../../../hooks/Products/useFetchProducts'

const Step4 = ({ data, updateData, setStep }) => {
  const { products: allProducts } = useFetchProducts()
  const products = allProducts?.filter(prd => !prd?.isAReadyMadeBox) || []
  const [showTextarea, setShowTextarea] = useState(null)
  const handlePaperTypeSelection = selectedPaperType => {
    setStep(4)
    updateData('paperType', selectedPaperType._id)
    updateData('step4PaperTypePrice', selectedPaperType.price)
  }
  const handleEnvelopeTypeSelection = selectedEnvelopeType => {
    setStep(4)
    updateData('envelopeType', selectedEnvelopeType._id)
    updateData('step4EnvelopeTypePrice', +selectedEnvelopeType.price)
  }
  const handlePersonalMessage = personalMessage => {
  setStep(4)
    updateData('personalMessage', personalMessage)
  }

  const handleSwitchToggle = () => {
    setShowTextarea(!showTextarea)
  }

  return (
    <>
      <div style={{ paddingBottom: '7rem' }}>
        {allProducts && (
          <>
            <Step3SliderComponent
              categoryName='Paper Type'
              products={products.filter(prd =>
                prd.scopes.includes('buildABox4PaperType')
              )}
              handleTypeSelection={handlePaperTypeSelection}
              data={data}
            />
            <Step3SliderComponent
              categoryName='Envelope Type'
              products={products.filter(prd =>
                prd.scopes.includes('buildABox4EnvelopeType')
              )}
              handleTypeSelection={handleEnvelopeTypeSelection}
              data={data}
            />
          </>
        )}
        <Form style={{}}>
          <div
            className='d-flex justify-content-between align-items-center rounded-4 p-4'
            style={{
              backgroundColor: 'rgba(210, 158, 132, 0.17)',
              margin: 'auto',
              maxWidth: '90%'
            }}
          >
            <Form.Label htmlFor='custom-switch' className='mb-0 fs-2'>
              Would you like to leave a message or note on the envelope?
            </Form.Label>
            <Form.Check
              type='switch'
              id='custom-switch'
              className='fs-1 custom-switch-step4'
              onChange={handleSwitchToggle}
              checked={showTextarea || data?.personalMessage}
            />
          </div>
          {(showTextarea || data?.personalMessage) && (
            <Form.Group className='mt-5' controlId='personalMessage'>
              <Form.Label className='fs-1 fw-bold'>Personal Message</Form.Label>
              <Form.Control
                as='textarea'
                placeholder='Enter your personal message here'
                rows={3}
                style={{
                  border: '1.224px solid #D29E84',
                  margin: 'auto',
                  marginTop: '3rem',
                  width: '90%',
                  borderRadius: '0.8rem',
                  fontSize: 'large'
                }}
                onChange={e => {
                  handlePersonalMessage(e.target.value)
                }}
                value={data?.personalMessage}
              />
            </Form.Group>
          )}
        </Form>
      </div>
    </>
  )
}

export default Step4
