import React from "react";
import GiftBox from "../../components/GIftBox";
import "./readyMadeBoxex.css";
import { useFetchProducts } from "../../hooks/Products/useFetchProducts";
import { Spin } from "antd";
import MainCarousel from "../../components/MainAutoCarousel";
import MetaTags from "../../components/MetaTags";

export default function ReadyMadeBoxes() {
  const { isLoading, products } = useFetchProducts();

  return (
    <>
      <MetaTags
        title={" Ready-made Boxes - Effortless Elegance for Every Occasion"}
        description={
          "Explore our exquisite ready-made boxes, each a masterpiece of curated elegance. Effortlessly gift sophistication for birthdays, celebrations, or just  because. Unwrap joy with HeartsyBox."
        }
      />
      <div className=".readyMade-top-image ">
        <MainCarousel />
      </div>
      <div className="readyMade-container">
        {isLoading && <Spin fullscreen />}

        {products &&
          products
            .filter((prd) => prd.isAReadyMadeBox)
            .map((prd, index) => (
              <GiftBox
                img={prd.imagesWithColors[0]?.src}
                key={prd._id}
                id={prd._id}
                title={prd.title}
                price={prd.price}
                description={prd.description}
                idx={index}
                reverse={true}
                prd={prd}
              />
            ))}
      </div>
    </>
  );
}
