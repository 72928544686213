import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media only screen and (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }
`
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  flex: 8;
  @media only screen and (max-width: 1100px) {
    flex: 7;
  }
`
