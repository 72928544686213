import React, { useState } from 'react'
import { Container, AntModal, DeleteText } from './styles'
import img from '../../../Assets/icons/delete.png'
import { RiDeleteBinLine } from 'react-icons/ri'
import { Button } from 'antd'
import DeleteButton from '../../UIKit/Buttons/Delete'

export default function DeleteModal ({ onSubmit, delText }) {
  const [open, setOpen] = useState(false)

  const handleOk = () => {
    onSubmit()
    setOpen(false)
  }
  const handleCancel = () => {
    setOpen(false)
  }
  const showModal = () => {
    setOpen(true)
  }

  return (
    <>
      {delText ? (
        <div style={{ color: 'red' }} onClick={showModal}>
          Delete
        </div>
      ) : (
        <RiDeleteBinLine onClick={showModal} />
      )}
      <AntModal
        style={{ borderRadius: '20px' }}
        open={open}
        closeIcon={false}
        width={'400px'}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        footer={() => <></>}
      >
        <Container>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1rem'
            }}
          >
            <img src={img} style={{ width: '50px' }} />

            <DeleteText>
              Are you sure you want to delete this record?
            </DeleteText>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '1rem',
                width: '100%'
              }}
            >
              <Button onClick={handleCancel}>No</Button>
              {/* <div style={{ flex: 1 }}> */}
              <DeleteButton
                text={'Yes'}
                style={{ flex: 1 }}
                onClick={handleOk}
                simple={true}
              />
              {/* </div> */}
            </div>
          </div>
        </Container>
      </AntModal>
    </>
  )
}
