import React, { useState } from 'react'
import { Container, CardHeader, OuterContainer } from './styles'
import AddGift from './AddGift'
import AddGiftBox from './AddGiftBox'
import { Radio } from 'antd'
import BackButton from '../UIKit/Buttons/BackButton'

export default function AddProductComponent ({ edit, product }) {
  const [type, setType] = useState(edit && product.isAReadyMadeBox ? '' : 'a')
  return (
    <OuterContainer>
      <CardHeader>
        <BackButton />
      </CardHeader>
      <Container>
        {!edit && (
          <div style={{ flex: 1 }}>
            <Radio.Group
              style={{ border: 'none' }}
              defaultValue='a'
              buttonStyle='solid'
              onChange={e => setType(e.target.value)}
            >
              <Radio.Button
                style={{
                  // border: 'none',
                  // marginRight: '8px',
                  borderRadius: 0,
                  fontSize: '12px',
                  backgroundColor: 'white',
                  color: 'black'
                }}
                value='a'
              >
                Gift
              </Radio.Button>
              <Radio.Button
                value='b'
                style={{
                  // border: 'none',
                  // marginRight: '8px',
                  borderRadius: 0,
                  fontSize: '12px',
                  backgroundColor: 'white',
                  color: 'black'
                }}
              >
                Gift Box
              </Radio.Button>
            </Radio.Group>
          </div>
        )}
        {type === 'a' ? (
          <AddGift edit={edit} product={product} />
        ) : (
          <AddGiftBox edit={edit} product={product} />
        )}
      </Container>
    </OuterContainer>
  )
}
