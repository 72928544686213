import React from "react";
import { Navigate, Outlet } from "react-router-dom";
// import Layout from '../../Containers/Layout'
import Layout from "../../AdminPanel/Components/Layout";
import { getUserToken } from "../LocalStorage/localstorage";

export default function AdminRoutes() {
  const token = getUserToken();
  return token ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/admin/login" />
  );
}
