import styled from 'styled-components'

export const Container = styled.div`
  padding: 3rem;
  border-radius: 10px;
  background-color: white;
  height: 75vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;
  justify-content: space-between;
`
export const CardHeading = styled.div`
  color: #141414;
  font-family: IBM Plex Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const TableContainer = styled.div`
  overflow-x: scroll;
  position: relative;
  scrollbar-width: none; /* Hide Firefox scrollbar */
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1200px) {
    overflow-x: auto;
    white-space: nowrap; /* To prevent wrapping of table content */
  }
  .antTable {
    border: none;
    width: 100%;
    position: absolute;
    @media (max-width: 1200px) {
      overflow-x: scroll;
    }
  }
`
